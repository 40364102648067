import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const NEW_NEWS = gql`
  mutation createNewsDraft {
    createNewsDraft {
      id
    }
  }
`;

const GET_ADMIN_NEWS = gql`
  {
    news: getAdminNews {
      id
      createdAt

      category
      title
      subTitle
      imgUrl

      published
    }
  }
`;

const getOneNews = gql`
  query getOneNews($id: ID!) {
    getOneNews(id: $id) {
      id
      createdAt

      title
      subTitle
      imgUrl
      category
      content

      published
    }
  }
`;

const DELETE_NEWS = gql`
  mutation deleteNews($id: ID!) {
    deleteNews(id: $id) {
      id
    }
  }
`;

const UPDATE_NEWS = gql`
  mutation updateNews(
    $id: ID!
    $title: String!
    $subTitle: String!
    $published: Boolean!
    $category: String!
    $imgUrl: String
    $content: String
  ) {
    updateNews(
      id: $id
      title: $title
      subTitle: $subTitle
      published: $published
      category: $category
      imgUrl: $imgUrl
      content: $content
    ) {
      id
      title
      subTitle
      published
      category
      imgUrl
      content
    }
  }
`;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("");
  const [accessKeyId, setAccessKeyId] = React.useState("");
  const [secretAccessKey, setSecretAccessKey] = React.useState("");
  const [postTitle, setPostTitle] = React.useState("");
  const [postSubTitle, setPostSubTitle] = React.useState("");
  const [postPublished, setPostPublished] = React.useState(false);
  const [postCategory, setPostCategory] = React.useState("국외뉴스");
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postContent, setPostContent] = React.useState("");

  const { data, loading } = useQuery(GET_ADMIN_NEWS);

  const me = useQuery(ME);

  const newsPostQuery = useQuery(getOneNews, {
    variables: {
      id: postid,
    },
  });

  const [createNewsDraftMutation] = useMutation(NEW_NEWS, {
    refetchQueries: [{ query: GET_ADMIN_NEWS }],
  });

  const [deletePostMutation] = useMutation(DELETE_NEWS, {
    variables: {
      id: postid,
    },
    refetchQueries: [{ query: GET_ADMIN_NEWS }],
  });

  const [updatePostMutation] = useMutation(UPDATE_NEWS, {
    variables: {
      id: postid,
      title: postTitle,
      subTitle: postSubTitle,
      published: postPublished,
      category: postCategory,
      imgUrl: postImgUrl,
      content: postContent,
    },
    refetchQueries: [{ query: GET_ADMIN_NEWS }],
  });

  const newsPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createNewsDraftMutation();
  };

  const uploadImg = async (e) => {
    // REACT-S3
    // const config = {
    //   bucketName: "cdn.pokertoday.co.kr",
    //   dirName: "images" /* optional */,
    //   region: "ap-northeast-2",
    //   accessKeyId: "",
    //   secretAccessKey: ""
    // };

    // S3FileUpload.uploadFile(e.target.files[0], config)
    //   .then(data => console.log(data))
    //   .catch(err => console.error(err));

    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    // console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const deletePost = () => {
    setPage("overview");
    resetState();
    deletePostMutation();
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const handleTitle = (e) => {
    setPostTitle(e.target.value);
  };

  const handleSubTitle = (e) => {
    setPostSubTitle(e.target.value);
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  const saveContent = ({ contents }) => {
    setPostContent(contents);
  };

  const handleCategory = (e) => {
    setPostCategory(e.target.value);
  };

  const resetState = () => {
    setPostid("");
    setPostTitle("");
    setPostImgUrl("");
    setPostPublished(false);
    setPostCategory("국외뉴스");
    setPostImgUrl("");
    setPostContent("");
  };

  React.useEffect(() => {
    if (newsPostQuery.loading === false) {
      if (newsPostQuery.data.getOneNews) {
        if (postTitle !== newsPostQuery.data.getOneNews.title) {
          setPostTitle(newsPostQuery.data.getOneNews.title);
          setPostSubTitle(newsPostQuery.data.getOneNews.subTitle);
          setPostImgUrl(newsPostQuery.data.getOneNews.imgUrl);
          setPostPublished(newsPostQuery.data.getOneNews.published);
          setPostCategory(newsPostQuery.data.getOneNews.category);
          setPostContent(newsPostQuery.data.getOneNews.content);
        }
      }
    }
  }, [newsPostQuery.loading, newsPostQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>KSOP소식</Title>
          <div>
            {!me.loading &&
              me.data &&
              me.data.me &&
              me.data.me.class &&
              me.data.me.name && (
                <div>
                  사용자: {me.data.me.class} {me.data.me.name}
                </div>
              )}
          </div>
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="1">생성일</Label>
              <Label flex="2">제목</Label>
              <Label flex="1">분류</Label>
              <Label flex="1">공개</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.news &&
              data.news.map((news) => {
                return (
                  <Item
                    key={news.id}
                    pointer={true}
                    onClick={() => newsPost(news.id)}
                    published={news.published}
                  >
                    <Label flex="1">
                      <Time createdAt={news.createdAt} />
                    </Label>
                    <Label flex="2">{news.title}</Label>
                    <Label flex="1">{news.category}</Label>
                    <Label flex="1">{news.published ? "O" : "X"}</Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>KSOP소식 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
            <Button onClick={() => deletePost()}>{"X 삭제하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!newsPostQuery.loading &&
              newsPostQuery.data &&
              newsPostQuery.data.getOneNews && (
                <>
                  <LabelPost>ID: {newsPostQuery.data.getOneNews.id}</LabelPost>
                  <LabelPost>제목</LabelPost>
                  <InputPost
                    value={postTitle}
                    onChange={handleTitle}
                    long={true}
                  />
                  <LabelPost>부제목</LabelPost>
                  <InputPost
                    value={postSubTitle}
                    onChange={handleSubTitle}
                    long={true}
                  />
                  <LabelPost>생성일</LabelPost>
                  <GetDate
                    createdAt={newsPostQuery.data.getOneNews.createdAt}
                  />
                  <Wrap>
                    <LabelPost>공개여부</LabelPost>
                    <Select
                      name="published"
                      value={postPublished}
                      onChange={handlePublished}
                    >
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>종류</LabelPost>
                    <Select
                      name="category"
                      value={postCategory}
                      onChange={handleCategory}
                    >
                      <option value="국외뉴스">국외뉴스</option>
                      <option value="국내뉴스">국내뉴스</option>
                      <option value="매장뉴스">매장뉴스</option>
                      <option value="포커정보">포커정보</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>썸네일 이미지</LabelPost>
                    <ImgPreview imgurl={postImgUrl} />
                    <ImgInput type="file" onChange={uploadImg} />
                  </Wrap>
                  <LabelPost></LabelPost>
                  <TextEditor
                    save={saveContent}
                    content={postContent}
                    postid={postid}
                  />
                </>
              )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 30px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 200px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url("${(p) => p.imgurl}");
  background-size: cover;
  background-position: center;
`;
