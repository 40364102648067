import React from "react";
import styled from "styled-components";
// import Input from "../../Components/Input";
import About from "./About";
import News from "./News";
import Introductions from "./Introductions";
import Competitions from "./Competitions";
import Players from "./Players";
import Tournaments from "./Tournaments";
import Banner from "./Banner";
import TicketMeta from "./TicketMeta";
import Location from "./Location";
import CM from "./CM";
import Live from "./Live";
import AppPage from "./AppPage";
import Monthly from "./Monthly";
import Satellite from "./Satellite";
import Online from "./Online";
import User from "./User";
import Notification from "./Notification";
import OnlineWPL from "./OnlineWPL";

export default (props) => {
  const [page, setPage] = React.useState("news");

  const setMenu = (menu) => {
    setPage(menu);
  };

  return (
    <Container>
      <MenuContainer>
        <Wrapper>
          <Menu onClick={() => setMenu("news")}>KSOP소식</Menu>
          <Menu onClick={() => setMenu("introductions")}>매장소개</Menu>
          <Separator />
          <Menu onClick={() => setMenu("banner")}>배너관리</Menu>
          <Menu onClick={() => setMenu("location")}>대회장소관리</Menu>
          <Menu onClick={() => setMenu("live")}>KSOP LIVE</Menu>
          <Menu onClick={() => setMenu("online")}>ONLINE</Menu>
          <Menu onClick={() => setMenu("onlinewpl")}>ONLINE_WPL</Menu>
          <Menu onClick={() => setMenu("app")}>앱</Menu>
          <Separator />
          <Menu onClick={() => setMenu("user")}>회원관리</Menu>
          <Menu onClick={() => setMenu("players")}>선수</Menu>
          <Menu onClick={() => setMenu("monthly")}>월별메인이벤트</Menu>
          <Menu onClick={() => setMenu("tournaments")}>개별메인이벤트</Menu>
          <Menu onClick={() => setMenu("satellites")}>Satellites</Menu>
          {/* <Separator /> */}
          {/* <Menu onClick={() => setMenu("")}>티켓종류</Menu> */}
          <Separator />
          <Menu onClick={() => setMenu("ticketmeta")}>티켓종류</Menu>
          <Separator />
          <Menu onClick={() => setMenu("about")}>소개&안내</Menu>
          <Menu onClick={() => setMenu("cm")}>파트너사</Menu>
          <Separator />
          {
            // <Menu onClick={() => setMenu("notification")}>푸시 알림</Menu>
          }
          <Menu onClick={() => props.logout()}>로그아웃</Menu>
        </Wrapper>
      </MenuContainer>
      <MainContainer>
        {page === "about" && (
          <Wrapper>
            <About />
          </Wrapper>
        )}
        {page === "news" && (
          <Wrapper>
            <News />
          </Wrapper>
        )}
        {page === "introductions" && (
          <Wrapper>
            <Introductions />
          </Wrapper>
        )}
        {page === "competitions" && (
          <Wrapper>
            <Competitions />
          </Wrapper>
        )}
        {page === "banner" && (
          <Wrapper>
            <Banner />
          </Wrapper>
        )}
        {page === "app" && (
          <Wrapper>
            <AppPage />
          </Wrapper>
        )}
        {page === "location" && (
          <Wrapper>
            <Location />
          </Wrapper>
        )}
        {page === "live" && (
          <Wrapper>
            <Live />
          </Wrapper>
        )}
        {page === "user" && (
          <Wrapper>
            <User />
          </Wrapper>
        )}
        {page === "players" && (
          <Wrapper>
            <Players />
          </Wrapper>
        )}
        {page === "monthly" && (
          <Wrapper>
            <Monthly />
          </Wrapper>
        )}
        {page === "tournaments" && (
          <Wrapper>
            <Tournaments />
          </Wrapper>
        )}
        {page === "satellites" && (
          <Wrapper>
            <Satellite />
          </Wrapper>
        )}
        {page === "cm" && (
          <Wrapper>
            <CM />
          </Wrapper>
        )}
        {page === "ticketmeta" && (
          <Wrapper>
            <TicketMeta />
          </Wrapper>
        )}
        {page === "online" && (
          <Wrapper>
            <Online />
          </Wrapper>
        )}
        {page === "onlinewpl" && (
          <Wrapper>
            <OnlineWPL />
          </Wrapper>
        )}
      </MainContainer>
    </Container>
  );
};

const Separator = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.14);
  margin-bottom: 30px;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  ${(p) => p.theme.sansFont};
`;

const MenuContainer = styled.div`
  width: 20%;
  height: 100%;
  position: fixed;
  top: 0;
  margin-top: 0px;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(p) => p.theme.lightgreyColor};
  ${(p) => p.theme.sansFont};
  z-index: 5;
`;

const MainContainer = styled.div`
  width: 100%;
  padding-left: 20%;
  position: relative;
  min-height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${(p) => p.theme.sansFont};
`;

const Wrapper = styled.div`
  padding: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 1920px;
  min-height: 100vwh;
`;

const Menu = styled.div`
  flex: 1;
  font-size: 18px;
  line-height: 16px;
  height: 16px;
  margin-bottom: 20px;
  cursor: pointer;
`;
