import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
  ${reset};
  * {
    box-sizing:border-box;
  }
  body {
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: rgba(0,0,0,0.8);
    position: relative;
    min-height: 100vh;
  }
  a {
    color:inherit;
    text-decoration: none;
  }
  .headerlogoimg{
    height:60px;
  }
  .footerlogoimg{
    height:25px;
  }
  .mainslider .swiper-container{
    height:25vw;
    width: 100%;
  }
  .mainslider .swiper-container .swiper-slide img {
    width: 100%;
  }
  .eventongoingslider .swiper-container{
    height:auto;
    width: 100%;
  }
  .eventongoingslider .swiper-container .swiper-slide img {
    width: 100%;
  }
  input {

  }
  .swiper-button-prev {
    left:15px !important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 27 44'%3E%3Cpath d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z' fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
  }
  .swiper-button-next {
    right:15px !important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 27 44'%3E%3Cpath d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z' fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
  }
  .swiper-button-prev, .swiper-button-next{
    width: 18px;
    height: 29px;
    background-size: 18px 29px;
    margin-top:-55px;
  }
  .swiper-pagination-bullet-active{
    background: rgb(249, 25, 52);
  }

  article {
    min-height: 90vh;
  }

  @media only screen and (max-width: 1080px){
    .swiper-button-prev, .swiper-button-next{
      margin-top:-18px;
    }
  }
`;

// line-clamp:1, text-overflow:ellipsis, word-wrap:break-word;
