import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation newSatellite($year: Int!, $month: Int!, $day: Int!) {
    newSatellite(year: $year, month: $month, day: $day) {
      id
    }
  }
`;

const GET_ALL = gql`
  {
    items: adminSatellite {
      id
      title
      published
      date
      shop {
        id
        title
      }
    }
  }
`;

const GET_ONE = gql`
  query oneSatellite($id: ID!) {
    post: oneSatellite(id: $id) {
      id
      title
      date
      endTime
      published
      price
      info
      shop {
        id
        title
      }
    }
  }
`;

const DELETE_ONE = gql`
  mutation deleteSatellite($id: ID!) {
    deleteSatellite(id: $id) {
      id
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateSatellite(
    $id: ID!
    $title: String!
    $published: Boolean!
    $date: Int!
    $startTime: Int!
    $endTime: Int!
    $price: String!
    $info: String!
    $shop: String
  ) {
    updateSatellite(
      id: $id
      title: $title
      published: $published
      date: $date
      startTime: $startTime
      endTime: $endTime
      price: $price
      info: $info
      shop: $shop
    ) {
      id
      title
      published
      date
      endTime
      price
      info
      shop {
        id
        title
      }
    }
  }
`;

const ADMIN_SHOPS = gql`
  query adminShops($region: String!, $keyword: String!) {
    shops: adminShops(region: $region, keyword: $keyword) {
      id
      title
      region
    }
  }
`;

const regionList1 = [
  "서울",
  "인천",
  "경기",
  "강원",
  "충청",
  "호남",
  "영남",
  "제주",
];

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("");
  const [postTitle, setPostTitle] = React.useState("");
  const [postPublished, setPostPublished] = React.useState(false);
  const [postLinkUrl, setPostLinkUrl] = React.useState("");
  const [postTime, setPostTime] = React.useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
    startTime: 0,
    endTime: 2200,
  });
  const [postPrice, setPostPrice] = React.useState({
    r1: "",
    r2: "",
    r3: "",
  });
  const [postInfo, setPostInfo] = React.useState({
    r1: "",
    r2: "",
    r3: "",
    r4: "",
    r5: "",
  });
  const [postShop, setPostShop] = React.useState({
    id: null,
    title: "",
  });

  const [postSearch, setPostSearch] = React.useState({
    region: "서울",
    keyword: "",
  });

  const [postSearch2, setPostSearch2] = React.useState({
    region: "서울",
    keyword: "",
  });

  const dt = new Date();

  const [mutationDate, setMutationDate] = React.useState({
    year: dt.getFullYear(),
    month: dt.getMonth() + 1,
    day: dt.getDate(),
  });

  const { data, loading } = useQuery(GET_ALL);

  const me = useQuery(ME);

  const postQuery = useQuery(GET_ONE, {
    variables: {
      id: postid,
    },
  });

  const [createDraftMutation] = useMutation(CREATE_DRAFT, {
    variables: {
      year: parseInt(mutationDate.year),
      month: parseInt(mutationDate.month),
      day: parseInt(mutationDate.day),
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const [updatePostMutation] = useMutation(UPDATE_ONE);

  const [deletePostMutation] = useMutation(DELETE_ONE, {
    variables: {
      id: postid,
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const shopQuery = useQuery(ADMIN_SHOPS, {
    variables: {
      region: postSearch2.region,
      keyword: postSearch2.keyword,
    },
  });

  const toPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createDraftMutation();
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const deletePost = () => {
    setPage("overview");
    resetState();
    deletePostMutation();
  };

  const updatePost = () => {
    setPage("overview");

    if (postShop.id === null) {
      updatePostMutation({
        variables: {
          id: postid,
          title: postTitle,
          published: postPublished,
          date:
            parseInt(postTime.year) * 10000 +
            parseInt(postTime.month) * 100 +
            parseInt(postTime.day),
          startTime: parseInt(postTime.startTime),
          endTime: parseInt(postTime.endTime),
          price: JSON.stringify(postPrice),
          info: JSON.stringify(postInfo),
        },
        refetchQueries: [{ query: GET_ALL }],
      });
    } else {
      updatePostMutation({
        variables: {
          id: postid,
          title: postTitle,
          published: postPublished,
          date:
            parseInt(postTime.year) * 10000 +
            parseInt(postTime.month) * 100 +
            parseInt(postTime.day),
          startTime: parseInt(postTime.startTime),
          endTime: parseInt(postTime.endTime),
          price: JSON.stringify(postPrice),
          info: JSON.stringify(postInfo),
          shop: postShop.id,
        },
        refetchQueries: [{ query: GET_ALL }],
      });
    }

    resetState();
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  // const handleImgUrl = e => {
  //   setPostImgUrl(e.target.value);
  // };

  const resetState = () => {
    setPostid("");
    setPostTitle("");
    setPostPublished(false);
    setPostTime({
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
      startTime: 0,
      endTime: 2200,
    });
    setPostPrice({
      r1: "",
      r2: "",
      r3: "",
    });
    setPostInfo({
      r1: "",
      r2: "",
      r3: "",
      r4: "",
      r5: "",
    });
    setPostShop({
      id: null,
      title: "",
    });
    setPostSearch({
      region: "서울",
      keyword: "",
    });
    setPostSearch2({
      region: "서울",
      keyword: "",
    });
  };

  React.useEffect(() => {
    if (postQuery.loading === false) {
      if (postQuery.data.post && postQuery.data.post !== null) {
        if (postTitle !== postQuery.data.post.title) {
          setPostTitle(postQuery.data.post.title);
          setPostLinkUrl(postQuery.data.post.linkUrl);
          setPostPublished(postQuery.data.post.published);
          setPostTime({
            year: parseInt(postQuery.data.post.date.substr(0, 4)),
            month: parseInt(postQuery.data.post.date.substr(5, 2)),
            day: parseInt(postQuery.data.post.date.substr(8, 2)),
            startTime:
              parseInt(postQuery.data.post.date.substr(11, 2)) * 100 +
              parseInt(postQuery.data.post.date.substr(14, 2)),
            endTime: postQuery.data.post.endTime,
          });
          setPostPrice(JSON.parse(postQuery.data.post.price));
          setPostInfo(JSON.parse(postQuery.data.post.info));
          if (postQuery.data.post.shop !== null) {
            setPostShop({
              id: postQuery.data.post.shop.id,
              title: postQuery.data.post.shop.title,
            });
          } else {
            setPostShop({
              id: null,
              title: "",
            });
          }
        }
      }
    }
  }, [postQuery.loading, postQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>Satellites</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Wrap style={{ marginTop: "32px" }}>
            <InputPost
              value={mutationDate.year}
              type="number"
              style={{ width: "80px" }}
              onChange={(e) =>
                setMutationDate({ ...mutationDate, year: e.target.value })
              }
            />
            {"년 "}
            <InputPost
              value={mutationDate.month}
              style={{ marginLeft: "8px", width: "50px" }}
              type="number"
              onChange={(e) =>
                setMutationDate({ ...mutationDate, month: e.target.value })
              }
            />
            {"월 "}
            <InputPost
              value={mutationDate.day}
              style={{ marginLeft: "8px", width: "50px" }}
              type="number"
              onChange={(e) =>
                setMutationDate({ ...mutationDate, day: e.target.value })
              }
            />
            {"일 "}
          </Wrap>
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="1">날짜</Label>
              <Label flex="1">이름</Label>
              <Label flex="1">매장</Label>
              <Label flex="1">공개</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.items &&
              data.items.map((item) => {
                return (
                  <Item
                    key={item.id}
                    pointer={true}
                    onClick={() => toPost(item.id)}
                    published={item.published}
                  >
                    <Label flex="1">{item.date.substr(0, 16)}</Label>
                    <Label flex="1">{item.title}</Label>
                    <Label flex="1">
                      {item.shop ? item.shop.title : "없음"}
                    </Label>
                    <Label flex="1">{item.published ? "O" : "X"}</Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>Satellites - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
            <Button onClick={() => deletePost()}>{"X 삭제하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>ID: {postQuery.data.post.id}</LabelPost>
                <LabelPost>이름</LabelPost>
                <InputPost
                  value={postTitle}
                  onChange={(e) => setPostTitle(e.target.value)}
                  long={false}
                />
                <Wrap>
                  <LabelPost>공개여부</LabelPost>
                  <Select
                    name="published"
                    value={postPublished}
                    onChange={handlePublished}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <Wrap>
                  <LabelPost>일시</LabelPost>
                  <InputPost
                    style={{ width: "80px" }}
                    value={postTime.year}
                    type="number"
                    onChange={(e) =>
                      setPostTime({ ...postTime, year: e.target.value })
                    }
                  />
                  {"년"}
                  <InputPost
                    style={{ width: "50px" }}
                    value={postTime.month}
                    type="number"
                    onChange={(e) =>
                      setPostTime({ ...postTime, month: e.target.value })
                    }
                  />
                  {"월"}
                  <InputPost
                    style={{ width: "50px" }}
                    value={postTime.day}
                    type="number"
                    onChange={(e) =>
                      setPostTime({ ...postTime, day: e.target.value })
                    }
                  />
                  {"일"}
                  <InputPost
                    style={{ width: "80px" }}
                    value={postTime.startTime}
                    type="number"
                    onChange={(e) =>
                      setPostTime({ ...postTime, startTime: e.target.value })
                    }
                  />
                  {"시/분"}
                  <span style={{ fontSize: "12px", marginLeft: "16px" }}>
                    ex) '1500' 입력시 -> '15시 00분' 이라는 의미
                  </span>
                </Wrap>
                <Wrap>
                  <LabelPost>종료시간</LabelPost>
                  <InputPost
                    value={postTime.endTime}
                    type="number"
                    onChange={(e) =>
                      setPostTime({ ...postTime, endTime: e.target.value })
                    }
                  />
                  <span style={{ fontSize: "12px", marginLeft: "16px" }}>
                    *종료시간 특정하지 않을 시 '0' 입력
                  </span>
                </Wrap>
                <hr />
                <LabelPost>*빈칸일시 표시되지 않음</LabelPost>
                <LabelPost style={{ marginTop: "32px" }}>비용</LabelPost>
                <Wrap>
                  <LabelPost>입장료</LabelPost>
                  <InputPost
                    value={postPrice.r1}
                    type="text"
                    onChange={(e) =>
                      setPostPrice({ ...postPrice, r1: e.target.value })
                    }
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>재참가</LabelPost>
                  <InputPost
                    value={postPrice.r2}
                    type="text"
                    onChange={(e) =>
                      setPostPrice({ ...postPrice, r2: e.target.value })
                    }
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>리바이or추가</LabelPost>
                  <InputPost
                    value={postPrice.r3}
                    type="text"
                    onChange={(e) =>
                      setPostPrice({ ...postPrice, r3: e.target.value })
                    }
                  />
                </Wrap>
                <hr />
                <LabelPost>*빈칸일시 표시되지 않음</LabelPost>
                <LabelPost style={{ marginTop: "32px" }}>세부정보</LabelPost>
                <Wrap>
                  <LabelPost>게임종목</LabelPost>
                  <InputPost
                    value={postInfo.r1}
                    type="text"
                    onChange={(e) =>
                      setPostInfo({ ...postInfo, r1: e.target.value })
                    }
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>시작 칩</LabelPost>
                  <InputPost
                    value={postInfo.r2}
                    type="text"
                    onChange={(e) =>
                      setPostInfo({ ...postInfo, r2: e.target.value })
                    }
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>예선 통과 인원</LabelPost>
                  <InputPost
                    value={postInfo.r3}
                    type="text"
                    onChange={(e) =>
                      setPostInfo({ ...postInfo, r3: e.target.value })
                    }
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>사용 기한</LabelPost>
                  <InputPost
                    value={postPrice.r4}
                    type="text"
                    onChange={(e) =>
                      setPostInfo({ ...postInfo, r4: e.target.value })
                    }
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>추가 조건</LabelPost>
                  <InputPost
                    value={postInfo.r5}
                    type="text"
                    onChange={(e) =>
                      setPostInfo({ ...postInfo, r5: e.target.value })
                    }
                  />
                </Wrap>
                <hr />
                <LabelPost>*매장 연결후 상단 적용하기 클릭</LabelPost>
                <LabelPost style={{ marginTop: "32px" }}>매장 연결</LabelPost>
                <Wrap>
                  <LabelPost>
                    현재 연결매장:{" "}
                    {postShop.title === "" ? "없음" : postShop.title}
                    {postShop.id === null ? "" : " (ID:" + postShop.id + ")"}
                  </LabelPost>
                </Wrap>
                <LabelPost style={{ marginTop: "32px" }}>매장 검색</LabelPost>
                <Wrap style={{ marginBottom: "32px" }}>
                  <Select
                    style={{ marginRight: "16px", marginBottom: "0px" }}
                    name="region"
                    value={postSearch.region}
                    onChange={(e) =>
                      setPostSearch({ ...postSearch, region: e.target.value })
                    }
                  >
                    {regionList1.map((region) => (
                      <option key={region} value={region}>
                        {region}
                      </option>
                    ))}
                  </Select>
                  <InputPost
                    style={{ marginBottom: "0px" }}
                    value={postSearch.keyword}
                    onChange={(e) =>
                      setPostSearch({ ...postSearch, keyword: e.target.value })
                    }
                  />
                  <button
                    onClick={() => {
                      setPostSearch2({
                        ...postSearch,
                      });
                    }}
                  >
                    검색
                  </button>
                </Wrap>
                {!shopQuery.loading &&
                  shopQuery.data &&
                  shopQuery.data.shops &&
                  shopQuery.data.shops.map((shop) => (
                    <Card key={shop.id}>
                      <LabelPost>매장이름: {shop.title}</LabelPost>
                      <LabelPost>ID: {shop.id}</LabelPost>
                      <button
                        onClick={() =>
                          setPostShop({ id: shop.id, title: shop.title })
                        }
                      >
                        이 매장으로 연결
                      </button>
                    </Card>
                  ))}
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Card = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.14);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
  padding-bottom: 128px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
