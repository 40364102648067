import React from "react";
import { useMutation, useQuery } from "react-apollo-hooks";

import {
  LOG_IN,
  LOCAL_LOG_IN,
  IS_LOGGED_IN,
  LOCAL_LOG_OUT
} from "./AdminQueries";
import LoginPresenter from "./LoginPresenter";
import AdminPresenter from "./AdminPresenter";

import useInput from "../../Hooks/useInput";

export default () => {
  const email = useInput("");
  const password = useInput("");

  const { data } = useQuery(IS_LOGGED_IN);

  const [logInMutation] = useMutation(LOG_IN);

  const [localLogOutMutation] = useMutation(LOCAL_LOG_OUT);

  const [localLogInMutation] = useMutation(LOCAL_LOG_IN);

  const local_login = ({ token, name }) => {
    localLogInMutation({
      variables: {
        token
      }
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (email.value !== "" && password.value !== "") {
      try {
        const { data } = await logInMutation({
          variables: { email: email.value, password: password.value }
        });
        // console.log(data);
        if (!data.login.token) {
          console.log("failed");
        } else {
          console.log("success");
          // console.log(data.login);
          // console.log(data);
          local_login({
            token: data.login.token
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log("All fields are required.");
    }
  };

  const logout = () => {
    localLogOutMutation();
  };

  return (
    <>
      {!data.isLoggedIn && (
        <LoginPresenter email={email} password={password} onSubmit={onSubmit} />
      )}
      {data.isLoggedIn && <AdminPresenter logout={logout} />}
    </>
  );
};
