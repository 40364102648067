import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation createCompetitionDraft {
    createCompetitionDraft {
      id
    }
  }
`;

const GET_ALL = gql`
  {
    getAdminCompetitions {
      id
      createdAt

      category
      title
      imgUrl

      published
      issued

      startDate
      endDate
    }
  }
`;

const GET_ONE = gql`
  query getOneCompetition($id: ID!) {
    getOneCompetition(id: $id) {
      id
      createdAt

      title
      imgUrl
      smallImgUrl
      contentImgUrl
      category

      startDate
      endDate

      published
      issued
    }
  }
`;

const DELETE_ONE = gql`
  mutation deleteCompetition($id: ID!) {
    deleteCompetition(id: $id) {
      id
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateCompetition(
    $id: ID!
    $title: String!
    $published: Boolean!
    $issued: Boolean!
    $category: String!
    $imgUrl: String!
    $smallImgUrl: String!
    $contentImgUrl: String!
    $startDate: String!
    $endDate: String!
  ) {
    updateCompetition(
      id: $id
      title: $title
      published: $published
      issued: $issued
      category: $category
      imgUrl: $imgUrl
      smallImgUrl: $smallImgUrl
      contentImgUrl: $contentImgUrl
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      title
      published
      issued
      category
      imgUrl
      smallImgUrl
      contentImgUrl
      startDate
      endDate
    }
  }
`;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("");
  const [postTitle, setPostTitle] = React.useState("");
  const [postPublished, setPostPublished] = React.useState(false);
  const [postIssued, setPostIssued] = React.useState(false);
  const [postCategory, setPostCategory] = React.useState("국내");
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postSmallImgUrl, setPostSmallImgUrl] = React.useState("");
  const [postContentImgUrl, setPostContentImgUrl] = React.useState("");
  const [postStartDate, setPostStartDate] = React.useState("");
  const [postEndDate, setPostEndDate] = React.useState("");

  const { data, loading } = useQuery(GET_ALL);

  const me = useQuery(ME);

  const competitionPostQuery = useQuery(GET_ONE, {
    variables: {
      id: postid,
    },
  });

  const [createCompetitionDraftMutation] = useMutation(CREATE_DRAFT, {
    refetchQueries: [{ query: GET_ALL }],
  });

  const [deletePostMutation] = useMutation(DELETE_ONE, {
    variables: {
      id: postid,
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const [updatePostMutation] = useMutation(UPDATE_ONE, {
    variables: {
      id: postid,
      title: postTitle,
      published: postPublished,
      issued: postIssued,
      category: postCategory,
      imgUrl: postImgUrl,
      smallImgUrl: postSmallImgUrl,
      contentImgUrl: postContentImgUrl,
      startDate: postStartDate + "T00:00:00.000Z",
      endDate: postEndDate + "T20:00:00.000Z",
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const newsPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createCompetitionDraftMutation();
  };

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg", // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadSmallImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostSmallImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadContentImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostContentImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const deletePost = () => {
    setPage("overview");
    resetState();
    deletePostMutation();
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const handleTitle = (e) => {
    setPostTitle(e.target.value);
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  const handleIssued = (e) => {
    if (e.target.value === "false") {
      setPostIssued(false);
    } else {
      setPostIssued(true);
    }
  };

  // const handleImgUrl = e => {
  //   setPostImgUrl(e.target.value);
  // };

  const handleCategory = (e) => {
    setPostCategory(e.target.value);
  };

  const handleStartDate = (e) => {
    setPostStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setPostEndDate(e.target.value);
  };

  const resetState = () => {
    setPostid("");
    setPostTitle("");
    setPostImgUrl("");
    setPostSmallImgUrl("");
    setPostContentImgUrl("");
    setPostPublished(false);
    setPostIssued(false);
    setPostCategory("포커뉴스");
    setPostStartDate("");
    setPostEndDate("");
  };

  React.useEffect(() => {
    if (competitionPostQuery.loading === false) {
      if (
        competitionPostQuery.data.getOneCompetition &&
        competitionPostQuery.data.getOneCompetition !== null
      ) {
        if (postTitle !== competitionPostQuery.data.getOneCompetition.title) {
          setPostTitle(competitionPostQuery.data.getOneCompetition.title);
          setPostImgUrl(competitionPostQuery.data.getOneCompetition.imgUrl);
          setPostSmallImgUrl(
            competitionPostQuery.data.getOneCompetition.smallImgUrl
          );
          setPostContentImgUrl(
            competitionPostQuery.data.getOneCompetition.contentImgUrl
          );
          setPostPublished(
            competitionPostQuery.data.getOneCompetition.published
          );
          setPostIssued(competitionPostQuery.data.getOneCompetition.issued);
          setPostCategory(competitionPostQuery.data.getOneCompetition.category);

          setPostStartDate(
            competitionPostQuery.data.getOneCompetition.startDate.substr(0, 10)
          );
          setPostEndDate(
            competitionPostQuery.data.getOneCompetition.endDate.substr(0, 10)
          );
        }
      }
    }
  }, [competitionPostQuery.loading, competitionPostQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>대회일정</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="1">시작일</Label>
              <Label flex="1">종료일</Label>
              <Label flex="2">제목</Label>
              <Label flex="1">분류</Label>
              <Label flex="1">공개</Label>
              <Label flex="1">홍보</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.getAdminCompetitions &&
              data.getAdminCompetitions.map((competition) => {
                return (
                  <Item
                    key={competition.id}
                    pointer={true}
                    onClick={() => newsPost(competition.id)}
                    published={competition.published}
                  >
                    <Label flex="1">
                      {competition.startDate.substr(0, 10)}
                    </Label>
                    <Label flex="1">{competition.endDate.substr(0, 10)}</Label>
                    <Label flex="2">{competition.title}</Label>
                    <Label flex="1">{competition.category}</Label>
                    <Label flex="1">{competition.published ? "O" : "X"}</Label>
                    <Label flex="1">{competition.issued ? "O" : "X"}</Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>대회일정 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
            <Button onClick={() => deletePost()}>{"X 삭제하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!competitionPostQuery.loading &&
              competitionPostQuery.data &&
              competitionPostQuery.data.getOneCompetition && (
                <>
                  <LabelPost>
                    ID: {competitionPostQuery.data.getOneCompetition.id}
                  </LabelPost>
                  <LabelPost>제목</LabelPost>
                  <InputPost
                    value={postTitle}
                    onChange={handleTitle}
                    long={true}
                  />
                  <LabelPost>시작일</LabelPost>
                  <InputPost value={postStartDate} onChange={handleStartDate} />
                  <LabelPost>종료일</LabelPost>
                  <InputPost value={postEndDate} onChange={handleEndDate} />
                  <Wrap>
                    <LabelPost>공개여부</LabelPost>
                    <Select
                      name="published"
                      value={postPublished}
                      onChange={handlePublished}
                    >
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>홍보여부</LabelPost>
                    <Select
                      name="issued"
                      value={postIssued}
                      onChange={handleIssued}
                    >
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>종류</LabelPost>
                    <Select
                      name="category"
                      value={postCategory}
                      onChange={handleCategory}
                    >
                      <option value="국내">국내</option>
                      <option value="해외">해외</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>큰 배너</LabelPost>
                    <ImgPreview imgurl={postImgUrl} />
                    <ImgInput type="file" onChange={uploadImg} />
                  </Wrap>
                  <Wrap>
                    <LabelPost>작은 배너</LabelPost>
                    <ImgPreview imgurl={postSmallImgUrl} />
                    <ImgInput type="file" onChange={uploadSmallImg} />
                  </Wrap>
                  <Wrap>
                    <LabelPost>내용 이미지</LabelPost>
                    <ImgPreview imgurl={postContentImgUrl} />
                    <ImgInput type="file" onChange={uploadContentImg} />
                  </Wrap>
                </>
              )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 200px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
