import React from "react";
import styled from "styled-components";
import Input from "../../Components/Input";

export default ({ email, password, onSubmit }) => {
  return (
    <LoginContainer>
      <LoginForm onSubmit={onSubmit}>
        <Input {...email} placeholder="Email" type="email" />
        <Input {...password} placeholder="Password" type="password" />
        <LoginButton>LOGIN</LoginButton>
      </LoginForm>
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => p.theme.sansFont};
`;

const LoginForm = styled.form`
  label: loginForm;
  display: inline;
  width: 300px;
  height: auto;
  border-radius: 4px;
  border: 1px solid ${p => p.theme.lightgreyColor};
  padding: 15px;

  & input {
    margin-top: 7px;
  }
`;

const Button = styled.button`
  padding: 5px;
  color: white;
  width: 100%;
  border-radius: 4px;
  background-color: ${p => p.theme.pokerredColor};
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;

  &:active {
    background-color: ${p => p.theme.greyColor};
  }
`;

const LoginButton = styled(Button)`
  margin-top: 30px;
`;
