import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const GET_ONE = gql`
  query oneLive {
    post: oneLive {
      id
      published
      videoPublished
      isLive
      description
      videoUrl
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updatePlay(
    $published: Boolean!
    $videoPublished: Boolean!
    $isLive: Boolean!
    $description: String!
    $videoUrl: String!
  ) {
    updatePlay(
      published: $published
      videoPublished: $videoPublished
      isLive: $isLive
      description: $description
      videoUrl: $videoUrl
    ) {
      published
      videoPublished
      description
      isLive
      videoUrl
    }
  }
`;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("0");
  const [postPublished, setPostPublished] = React.useState(false);
  const [postIsLive, setPostIsLive] = React.useState(false);
  const [postVideoPublished, setPostVideoPublished] = React.useState(false);
  const [postDescription, setPostDescription] = React.useState("");
  const [postVideoUrl, setPostVideoUrl] = React.useState("");

  // const { data, loading } = useQuery(GET_ALL);

  const me = useQuery(ME);

  const { data, loading } = useQuery(GET_ONE, {
    fetchPolicy: "network-only"
  });

  const [updatePostMutation] = useMutation(UPDATE_ONE, {
    variables: {
      published: postPublished,
      videoPublished: postVideoPublished,
      description: postDescription,
      videoUrl: postVideoUrl,
      isLive: postIsLive
    },
    refetchQueries: [{ query: GET_ONE }]
  });

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  React.useEffect(() => {
    if (loading === false) {
      if (data.post && data.post !== null) {
        setPostPublished(data.post.published);
        setPostVideoPublished(data.post.videoPublished);
        setPostDescription(data.post.description);
        setPostIsLive(data.post.isLive);
        setPostVideoUrl(data.post.videoUrl);
      }
    }
  }, [loading, data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>KSOP LIVE</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button
            onClick={() => {
              updatePostMutation();
            }}
          >
            {"V 적용하기"}
          </Button>
          <WrapperPost>
            {!loading && data && data.post && (
              <>
                <Wrap>
                  <LabelPost>공개여부</LabelPost>
                  <Select
                    name="published"
                    value={postPublished}
                    onChange={e => {
                      if (e.target.value === "false") {
                        setPostPublished(false);
                      } else {
                        setPostPublished(true);
                      }
                    }}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <Wrap>
                  <LabelPost>영상공개여부</LabelPost>
                  <Select
                    name="videoPublished"
                    value={postVideoPublished}
                    onChange={e => {
                      if (e.target.value === "false") {
                        setPostVideoPublished(false);
                      } else {
                        setPostVideoPublished(true);
                      }
                    }}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <Wrap>
                  <LabelPost>LIVE 여부</LabelPost>
                  <Select
                    name="isLive"
                    value={postIsLive}
                    onChange={e => {
                      if (e.target.value === "false") {
                        setPostIsLive(false);
                      } else {
                        setPostIsLive(true);
                      }
                    }}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <LabelPost>유튜브 ID</LabelPost>
                <InputPost
                  value={postVideoUrl}
                  onChange={e => setPostVideoUrl(e.target.value)}
                  long={false}
                />
                <LabelPost>방송일정&상세</LabelPost>
                <textarea
                  value={postDescription}
                  onChange={e => setPostDescription(e.target.value)}
                  style={{ width: "500px", height: "300px" }}
                />
              </>
            )}
          </WrapperPost>{" "}
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${p => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${p => (p.pointer ? "cursor:pointer;" : "")};
  ${p =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${p => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${p => (p.short ? "width:100px;" : "")};
  ${p => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${p => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${p => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${p => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
