import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const ABOUT = gql`
  query about($id: Int!) {
    post: about(where: { id: $id }) {
      id
      r1
      r2
      r3
      r4
      r5
      r6
      r7
      r8
      r9
      r10
    }
  }
`;

const META = gql`
  query meta($slug: String!) {
    post: meta(slug: $slug) {
      id
      slug
      content
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateOneAbout(
    $id: Int!
    $r1: String
    $r2: String
    $r3: String
    $r4: String
    $r5: String
    $r6: String
    $r7: String
    $r8: String
    $r9: String
    $r10: String
  ) {
    updateOneAbout(
      where: { id: $id }
      data: {
        r1: $r1
        r2: $r2
        r3: $r3
        r4: $r4
        r5: $r5
        r6: $r6
        r7: $r7
        r8: $r8
        r9: $r9
        r10: $r10
      }
    ) {
      id
      r1
      r2
      r3
      r4
      r5
      r6
      r7
      r8
      r9
      r10
    }
  }
`;

const NEW_META = {
  HEAD_TITLE: "HEAD_TITLE",
  HEAD_SUBTITLE: "HEAD_SUBTITLE",
  HEAD_CONTENT_TYPE: "HEAD_CONTENT_TYPE",
  HEAD_CONTENT_URL: "HEAD_CONTENT_URL",
  HEAD_VIDEO_URL: "HEAD_VIDEO_URL",
  HEAD_CONTENT_LINK: "HEAD_CONTENT_LINK",
  HEAD_DESC_TITLE_01: "HEAD_DESC_TITLE_01",
  HEAD_DESC_TITLE_02: "HEAD_DESC_TITLE_02",
  HEAD_DESC_TITLE_03: "HEAD_DESC_TITLE_03",
};

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("0");
  const [uploading, setUploading] = React.useState(false);
  const [postState, setPostState] = React.useState({
    r1: ["", "", ""],
    r2: [
      { head: "", body: "" },
      { head: "", body: "" },
    ],
    r3: "",
    r4: "",
    r5: "",
    r6: "",
    r7: "",
    r8: "",
    r9: "",
    r10: {
      [NEW_META.HEAD_TITLE]: "",
      [NEW_META.HEAD_SUBTITLE]: "",
      [NEW_META.HEAD_CONTENT_TYPE]: "",
      [NEW_META.HEAD_CONTENT_URL]: "",
      [NEW_META.HEAD_VIDEO_URL]: "",
      [NEW_META.HEAD_CONTENT_LINK]: "",
      [NEW_META.HEAD_DESC_TITLE_01]: "",
      [NEW_META.HEAD_DESC_TITLE_02]: "",
      [NEW_META.HEAD_DESC_TITLE_03]: "",
    },
  });

  console.log(postState);

  const me = useQuery(ME);

  const { data, loading } = useQuery(ABOUT, {
    variables: { id: 7 },
    fetchPolicy: "network-only",
  });

  const [updatePostMutation] = useMutation(UPDATE_ONE);

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const uploadFile = (e) => {
    const today = new Date();
    const file = e.target.files[0];
    const contentType = file.type;
    const generatePutUrl = "https://backend.ksop.co.kr/s3put";
    const options = {
      params: {
        Key:
          "files/" +
          today.getFullYear().toString() +
          "/" +
          today.getMonth() +
          "/" +
          file.name,
        ContentType: contentType,
      },
      headers: {
        "Content-Type": contentType,
      },
    };

    axios.get(generatePutUrl, options).then((res) => {
      const {
        data: { putURL },
      } = res;
      console.log(putURL);
      axios
        .put(putURL, file, {})
        .then((res) => {
          setPostState({
            ...postState,
            r2: [
              {
                body:
                  "https://cdnksop.s3.ap-northeast-2.amazonaws.com/files/" +
                  today.getFullYear().toString() +
                  "/" +
                  today.getMonth() +
                  "/" +
                  file.name,
                head: postState.r2[0].head,
              },
              {
                head: postState.r2[1].head,
                body: postState.r2[1].body,
              },
            ],
          });

          setUploading(false);
        })
        .catch((err) => {
          // this.setState({ message: "Sorry, something went wrong" });
          console.error("err", err);
        });
    });
  };

  const uploadFile2 = (e) => {
    const today = new Date();
    const file = e.target.files[0];
    const contentType = file.type;
    const generatePutUrl = "https://backend.ksop.co.kr/s3put";
    const options = {
      params: {
        Key:
          "files/" +
          today.getFullYear().toString() +
          "/" +
          today.getMonth() +
          "/" +
          file.name,
        ContentType: contentType,
      },
      headers: {
        "Content-Type": contentType,
      },
    };

    axios.get(generatePutUrl, options).then((res) => {
      const {
        data: { putURL },
      } = res;
      console.log(putURL);
      axios
        .put(putURL, file, {})
        .then((res) => {
          setPostState({
            ...postState,
            r2: [
              {
                head: postState.r2[0].head,
                body: postState.r2[0].body,
              },
              {
                body:
                  "https://cdnksop.s3.ap-northeast-2.amazonaws.com/files/" +
                  today.getFullYear().toString() +
                  "/" +
                  today.getMonth() +
                  "/" +
                  file.name,
                head: postState.r2[1].head,
              },
            ],
          });

          setUploading(false);
        })
        .catch((err) => {
          // this.setState({ message: "Sorry, something went wrong" });
          console.error("err", err);
        });
    });
  };

  const uploadFile3 = (e) => {
    const today = new Date();
    const file = e.target.files[0];
    const contentType = file.type;
    const generatePutUrl = "https://backend.ksop.co.kr/s3put";
    const options = {
      params: {
        Key:
          "files/" +
          today.getFullYear().toString() +
          "/" +
          today.getMonth() +
          "/" +
          file.name,
        ContentType: contentType,
      },
      headers: {
        "Content-Type": contentType,
      },
    };

    axios.get(generatePutUrl, options).then((res) => {
      const {
        data: { putURL },
      } = res;
      console.log(putURL);
      axios
        .put(putURL, file, {})
        .then((res) => {
          setPostState({
            ...postState,
            r7:
              "https://cdnksop.s3.ap-northeast-2.amazonaws.com/files/" +
              today.getFullYear().toString() +
              "/" +
              today.getMonth() +
              "/" +
              file.name,
          });

          setUploading(false);
        })
        .catch((err) => {
          // this.setState({ message: "Sorry, something went wrong" });
          console.error("err", err);
        });
    });
  };

  const uploadFile4 = (e) => {
    const today = new Date();
    const file = e.target.files[0];
    const contentType = file.type;
    const generatePutUrl = "https://backend.ksop.co.kr/s3put";
    const options = {
      params: {
        Key:
          "files/" +
          today.getFullYear().toString() +
          "/" +
          today.getMonth() +
          "/" +
          file.name,
        ContentType: contentType,
      },
      headers: {
        "Content-Type": contentType,
      },
    };

    axios.get(generatePutUrl, options).then((res) => {
      const {
        data: { putURL },
      } = res;
      console.log(putURL);
      axios
        .put(putURL, file, {})
        .then((res) => {
          setPostState({
            ...postState,
            r9:
              "https://cdnksop.s3.ap-northeast-2.amazonaws.com/files/" +
              today.getFullYear().toString() +
              "/" +
              today.getMonth() +
              "/" +
              file.name,
          });

          setUploading(false);
        })
        .catch((err) => {
          // this.setState({ message: "Sorry, something went wrong" });
          console.error("err", err);
        });
    });
  };

  const uploadImg = async (e) => {
    // REACT-S3
    // const config = {
    //   bucketName: "cdn.pokertoday.co.kr",
    //   dirName: "images" /* optional */,
    //   region: "ap-northeast-2",
    //   accessKeyId: "",
    //   secretAccessKey: ""
    // };

    // S3FileUpload.uploadFile(e.target.files[0], config)
    //   .then(data => console.log(data))
    //   .catch(err => console.error(err));

    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    // console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // setPostImgUrl(response.data.location);
          setPostState({
            ...postState,
            r7: response.data.location,
          });
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadImg2 = async (e) => {
    // REACT-S3
    // const config = {
    //   bucketName: "cdn.pokertoday.co.kr",
    //   dirName: "images" /* optional */,
    //   region: "ap-northeast-2",
    //   accessKeyId: "",
    //   secretAccessKey: ""
    // };

    // S3FileUpload.uploadFile(e.target.files[0], config)
    //   .then(data => console.log(data))
    //   .catch(err => console.error(err));

    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    // console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // setPostImgUrl(response.data.location);
          setPostState({
            ...postState,
            r9: response.data.location,
          });
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadImgContentUrl = async (e) => {
    // REACT-S3
    // const config = {
    //   bucketName: "cdn.pokertoday.co.kr",
    //   dirName: "images" /* optional */,
    //   region: "ap-northeast-2",
    //   accessKeyId: "",
    //   secretAccessKey: ""
    // };

    // S3FileUpload.uploadFile(e.target.files[0], config)
    //   .then(data => console.log(data))
    //   .catch(err => console.error(err));

    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    // console.log(name);

    formData.append("file", e.target.files[0], "headerContentUrl." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response);
          // setPostImgUrl(response.data.location);
          setPostState({
            ...postState,
            r10: {
              ...postState.r10,
              [NEW_META.HEAD_CONTENT_URL]: response.data.location,
            },
          });
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (loading === false) {
      if (data.post && data.post !== null) {
        setPostState({
          r1: JSON.parse(data.post.r1),
          r2: JSON.parse(data.post.r2),
          r3: data.post.r3,
          r4: data.post.r4,
          r5: data.post.r5,
          r6: data.post.r6,
          r7: data.post.r7,
          r8: data.post.r8,
          r9: data.post.r9,
          r10: JSON.parse(data.post.r10),
        });
      }
    }
  }, [loading, data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>KSOP ONLINE</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button
            onClick={() => {
              updatePostMutation({
                variables: {
                  id: 7,
                  r1: JSON.stringify(postState.r1),
                  r2: JSON.stringify(postState.r2),
                  r3: postState.r3,
                  r4: postState.r4,
                  r5: postState.r5,
                  r6: postState.r6,
                  r7: postState.r7,
                  r8: postState.r8,
                  r9: postState.r9,
                  r10: JSON.stringify(postState.r10),
                },
                refetchQueries: [{ query: ABOUT, variables: { id: 7 } }],
              });
            }}
          >
            {"V 적용하기"}
          </Button>
          <WrapperPost>
            {!loading && data && data.post && (
              <>
                <Wrap>
                  <LabelPost>헤더 타이틀</LabelPost>
                  <InputPost
                    value={postState.r10[NEW_META.HEAD_TITLE]}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r10: {
                          ...postState.r10,
                          [NEW_META.HEAD_TITLE]: e.target.value,
                        },
                      });
                    }}
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>헤더 서브타이틀</LabelPost>
                  <InputPost
                    value={postState.r10[NEW_META.HEAD_SUBTITLE]}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r10: {
                          ...postState.r10,
                          [NEW_META.HEAD_SUBTITLE]: e.target.value,
                        },
                      });
                    }}
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>헤더 종류</LabelPost>
                  <select
                    value={postState.r10[NEW_META.HEAD_CONTENT_TYPE]}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r10: {
                          ...postState.r10,
                          [NEW_META.HEAD_CONTENT_TYPE]: e.target.value,
                        },
                      });
                    }}
                  >
                    <option value="IMAGE">이미지</option>
                    <option value="VIDEO">유튜브</option>
                  </select>
                </Wrap>
                <Pad />
                {postState.r10[NEW_META.HEAD_CONTENT_TYPE] === "IMAGE" && (
                  <Wrap>
                    <LabelPost>헤더 이미지</LabelPost>
                    <ImgPreview
                      imgurl={postState.r10[NEW_META.HEAD_CONTENT_URL]}
                    />
                    <ImgInput type="file" onChange={uploadImgContentUrl} />
                  </Wrap>
                )}
                {postState.r10[NEW_META.HEAD_CONTENT_TYPE] === "VIDEO" && (
                  <Wrap>
                    <LabelPost>헤더 유튜브 URL</LabelPost>
                    <InputPost
                      value={postState.r10[NEW_META.HEAD_VIDEO_URL]}
                      long={true}
                      onChange={(e) => {
                        setPostState({
                          ...postState,
                          r10: {
                            ...postState.r10,
                            [NEW_META.HEAD_VIDEO_URL]: e.target.value,
                          },
                        });
                      }}
                    />
                  </Wrap>
                )}
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <Wrap>
                  <LabelPost>헤더 설명 타이틀 01</LabelPost>
                  <InputPost
                    value={postState.r10[NEW_META.HEAD_DESC_TITLE_01]}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r10: {
                          ...postState.r10,
                          [NEW_META.HEAD_DESC_TITLE_01]: e.target.value,
                        },
                      });
                    }}
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>헤더 설명 타이틀 02</LabelPost>
                  <InputPost
                    value={postState.r10[NEW_META.HEAD_DESC_TITLE_02]}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r10: {
                          ...postState.r10,
                          [NEW_META.HEAD_DESC_TITLE_02]: e.target.value,
                        },
                      });
                    }}
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>헤더 설명 타이틀 03</LabelPost>
                  <InputPost
                    value={postState.r10[NEW_META.HEAD_DESC_TITLE_03]}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r10: {
                          ...postState.r10,
                          [NEW_META.HEAD_DESC_TITLE_03]: e.target.value,
                        },
                      });
                    }}
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>헤더 설명 내용 01</LabelPost>
                  <InputPost
                    value={postState.r1[0]}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r1: [e.target.value, postState.r1[1], postState.r1[2]],
                      });
                    }}
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>헤더 설명 내용 02</LabelPost>
                  <InputPost
                    value={postState.r1[1]}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r1: [postState.r1[0], e.target.value, postState.r1[2]],
                      });
                    }}
                  />
                </Wrap>
                <LabelPost>헤더 설명 내용 03</LabelPost>
                <textarea
                  value={postState.r1[2]}
                  style={{ width: "100%", height: "256px" }}
                  onChange={(e) => {
                    setPostState({
                      ...postState,
                      r1: [postState.r1[0], postState.r1[1], e.target.value],
                    });
                  }}
                />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <Wrap>
                  <LabelPost>협약사 이름</LabelPost>
                  <InputPost
                    value={postState.r4}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r4: e.target.value,
                      });
                    }}
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>다운로드 카피</LabelPost>
                  <InputPost
                    value={postState.r6}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r6: e.target.value,
                      });
                    }}
                  />
                </Wrap>
                <LabelPost>협약사 내용</LabelPost>
                <textarea
                  value={postState.r5}
                  style={{ width: "100%", height: "256px" }}
                  onChange={(e) => {
                    setPostState({
                      ...postState,
                      r5: e.target.value,
                    });
                  }}
                />
                <div style={{ height: "16px", width: "16px" }} />
                <LabelPost>협약사 로고</LabelPost>
                <ImgPreview imgurl={postState.r7} />
                <ImgInput type="file" onChange={uploadImg} />
                <div style={{ height: "16px", width: "16px" }} />
                <Wrap>
                  <LabelPost>협약사 링크</LabelPost>
                  <InputPost
                    value={postState.r8}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r8: e.target.value,
                      });
                    }}
                  />
                </Wrap>
                <LabelPost>게임 이미지</LabelPost>
                <ImgPreview imgurl={postState.r9} />
                <ImgInput type="file" onChange={uploadImg2} />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>게임가이드</LabelPost>
                <Wrap>
                  <InputPost value={postState.r2[0].body} readOnly />
                </Wrap>
                <Wrap>
                  <ImgInput
                    disabled={uploading}
                    type="file"
                    onChange={(e) => {
                      setUploading(true);
                      uploadFile(e);
                    }}
                  />
                  {uploading && <div>!! 업로드중...</div>}
                </Wrap>
                <LabelPost>쿠폰 사용법</LabelPost>
                <Wrap>
                  <InputPost value={postState.r2[1].body} readOnly />
                </Wrap>
                <Wrap>
                  <ImgInput
                    disabled={uploading}
                    type="file"
                    onChange={(e) => {
                      setUploading(true);
                      uploadFile2(e);
                    }}
                  />
                  {uploading && <div>!! 업로드중...</div>}
                </Wrap>
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <Wrap>
                  <LabelPost>유튜브URL</LabelPost>
                  <InputPost
                    value={postState.r3}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r3: e.target.value,
                      });
                    }}
                  />
                </Wrap>
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Pad = styled.div`
  height: 16px;
`;

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url("${(p) => p.imgurl}");
  background-size: cover;
  background-position: center;
`;
