import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const PLAYER = gql`
  query adminPlayerbyName($name: String!) {
    players: adminPlayerByName(name: $name) {
      id
      rank
      name
      tel
      score
      prize
    }
  }
`;

const ONE_PLAYER = gql`
  query getOnePlayer($id: ID!) {
    player: getOnePlayer(id: $id) {
      id
      name
      rank
    }
  }
`;

const GET_ADMIN_USERS = gql`
  query getAdminUsers($first: Int!, $skip: Int!, $name: String!) {
    users: getAdminUsers(first: $first, skip: $skip, name: $name) {
      id
      createdAt
      email
      name
      tel
    }
  }
`;

const GET_ONE_USER = gql`
  query getOneUser($id: ID!) {
    post: getOneUser(id: $id) {
      id
      createdAt
      email
      name
      tel
      playerId
      player {
        id
        name
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String!
    $email: String!
    $playerId: ID!
    $tel: String!
  ) {
    updateUser(
      id: $id
      name: $name
      email: $email
      playerId: $playerId
      tel: $tel
    ) {
      id
      name
      email
      tel
      playerId
    }
  }
`;

export default () => {
  const ct = 50;
  const [subPage, setSubPage] = React.useState(0);

  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("");

  const [postName, setPostName] = React.useState("");
  const [postEmail, setPostEmail] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [playerName, setPlayerName] = React.useState("");

  const [playerId, setPlayerId] = React.useState("");

  const [postTeam, setPostTeam] = React.useState("");
  const [postMemo, setPostMemo] = React.useState("");
  const [postTel, setPostTel] = React.useState("");

  const [WTitle, setWTitle] = React.useState("");
  const [WEventName, setWEventName] = React.useState("");
  const [WRecord, setWRecord] = React.useState("");
  const [WLinked, setWLinked] = React.useState(false);
  const [WIdx, setWIdx] = React.useState(0);

  const [postScore, setPostScore] = React.useState(0.0);
  const [postRecordScore, setPostRecordScore] = React.useState(0.001);
  const [postIdx, setPostIdx] = React.useState(1);

  const [postPublished, setPostPublished] = React.useState(false);
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postRank, setPostRank] = React.useState(0);

  const [postDeletion, setPostDeletion] = React.useState("");
  const [WDeletion, setWDeletion] = React.useState("");
  const [WUpdate, setWUpdate] = React.useState(0);
  const [WState, setWState] = React.useState({
    title: "",
    eventName: "",
    record: "",
    linked: false,
    idx: 0,
  });

  const [state, setState] = React.useState({ name: "", ct: ct });
  const [searchName, setSearchName] = React.useState("");

  const { data, loading } = useQuery(GET_ADMIN_USERS, {
    variables: { skip: subPage, first: state.ct, name: state.name },
    fetchPolicy: "network-only",
  });

  const me = useQuery(ME);

  const playerQuery = useQuery(PLAYER, {
    variables: {
      name: playerName,
    },
  });

  const onePlayerQuery = useQuery(ONE_PLAYER, {
    variables: {
      id: playerId,
    },
  });

  const postQuery = useQuery(GET_ONE_USER, {
    variables: {
      id: postid,
    },
  });

  const [deletePostMutation] = useMutation(DELETE_USER, {
    variables: {
      id: postid,
    },
    refetchQueries: [
      {
        query: GET_ADMIN_USERS,
        variables: { skip: subPage, first: ct, name: "" },
      },
    ],
  });

  const [updatePostMutation] = useMutation(UPDATE_USER, {
    variables: {
      id: postid,
      name: postName,
      email: postEmail,
      playerId: playerId,
      tel: postTel,
    },
    refetchQueries: [
      {
        query: GET_ADMIN_USERS,
        variables: { skip: subPage, first: ct, name: "" },
      },
    ],
  });

  const playerPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const splited = name.split(".");

    formData.append(
      "file",
      e.target.files[0],
      "img." + splited[splited.length - 1]
    );

    try {
      axios
        .post(
          // "https://pokertoday-backend.xero0001.now.sh/upload",
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const deletePost = () => {
    setPage("overview");
    resetState();
    deletePostMutation();
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const deleteRecord = () => {
    // deleteRecordMutation();
  };

  const handleName = (e) => {
    setPostName(e.target.value);
  };

  const setDeletion = (props) => {
    setPostDeletion(props);
  };

  const handleTeam = (e) => {
    setPostTeam(e.target.value);
  };

  const handleMemo = (e) => {
    setPostMemo(e.target.value);
  };

  const handleScore = (e) => {
    setPostScore(e.target.value);
  };

  const handleRecordScore = (e) => {
    if (
      e.target.value !== NaN &&
      e.target.value !== "" &&
      e.target.value !== "NaN" &&
      e.target.value !== undefined
    ) {
      setPostRecordScore(parseFloat(e.target.value));
    } else {
      setPostRecordScore(0.001);
    }
  };

  const handleIdx = (e) => {
    if (
      e.target.value !== NaN &&
      e.target.value !== "" &&
      e.target.value !== "NaN" &&
      e.target.value !== undefined
    ) {
      setPostIdx(parseInt(e.target.value));
    } else {
      setPostIdx(1);
    }
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  const numberFormat = (inputNumber) => {
    var parts = inputNumber.toString().split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "")
    );
  };

  const resetState = () => {
    setPostid("");
    setPostName("");
    setPostEmail("");
    setPlayerId("");
    setPostTel("");
    // setPostPublished(false);
    // setPostTeam("");
    // setPostImgUrl("");
    // setPostRank(0);
    // setPostScore(0.0);
    // setPostRecordScore(0.001);
    // setPostIdx(1);

    // setPostMemo("");
    // setPostTel("");

    // setWTitle("");
    // setWEventName("");
    // setWRecord("");
    // setWLinked(false);
    // setWIdx(0);
  };

  React.useEffect(() => {
    if (postQuery.loading === false) {
      if (postQuery.data.post) {
        if (postName !== postQuery.data.post.name) {
          setPostName(postQuery.data.post.name);
          setPostEmail(postQuery.data.post.email);
          setPostTel(postQuery.data.post.tel);
          if (postQuery.data.post.playerId) {
            setPlayerId(postQuery.data.post.playerId);
          } else {
            setPlayerId("");
          }
        }
      }
    }
  }, [postQuery.loading, postQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>회원관리</Title>
          <div>
            {!me.loading &&
              me.data &&
              me.data.me &&
              me.data.me.class &&
              me.data.me.name && (
                <div>
                  사용자: {me.data.me.class} {me.data.me.name}
                </div>
              )}
          </div>

          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 32,
              marginBottom: 16,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span style={{ lineHeight: "32px" }}>이름검색: </span>
            <InputPost
              style={{ height: "32px" }}
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  if (searchName === "") {
                    setState({ name: "", ct: ct });
                  } else {
                    setState({ name: searchName, ct: 0 });
                    setSearchName("");
                  }
                }
              }}
            />
            <button
              style={{ width: 64, height: "32px" }}
              onClick={() => {
                if (searchName === "") {
                  setState({ name: "", ct: ct });
                } else {
                  setState({ name: searchName, ct: 0 });
                  setSearchName("");
                }
              }}
            >
              검색
            </button>
          </div>
          {state.name === "" && (
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 32 }}
            >
              {subPage >= ct && (
                <button
                  style={{ width: 64 }}
                  onClick={() => setSubPage(subPage - 50)}
                >
                  앞으로
                </button>
              )}
              <Label style={{ marginLeft: 16, marginRight: 16 }}>
                {subPage + 1 + " ~ " + (subPage + 50)}
              </Label>
              <button
                style={{ width: 64 }}
                onClick={() => setSubPage(subPage + 50)}
              >
                뒤로
              </button>
            </div>
          )}
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="2">생성일</Label>
              <Label flex="2">이메일</Label>
              <Label flex="2">휴대폰번호</Label>
              <Label flex="1">이름</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.users &&
              data.users.map((user) => {
                return (
                  <Item
                    key={user.id}
                    pointer={true}
                    onClick={() => playerPost(user.id)}
                    published="true"
                  >
                    <Label flex="2">{user.createdAt}</Label>
                    <Label flex="2">{user.email}</Label>
                    <Label flex="2">{user.tel}</Label>
                    <Label flex="1">{user.name}</Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>회원관리</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
            <Button onClick={() => deletePost()}>{"X 회원탈퇴"}</Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>ID: {postQuery.data.post.id}</LabelPost>
                <LabelPost>이름</LabelPost>
                <InputPost value={postName} onChange={handleName} />
                <LabelPost>이메일</LabelPost>
                <InputPost
                  value={postEmail}
                  onChange={(e) => {
                    setPostEmail(e.target.value);
                  }}
                />
                <LabelPost>휴대폰번호</LabelPost>
                <InputPost
                  value={postTel}
                  onChange={(e) => {
                    setPostTel(e.target.value);
                  }}
                />
                <LabelPost>
                  생성일: {postQuery.data.post.createdAt.substr(0, 10)}
                </LabelPost>
                <LabelPost style={{ fontWeight: "600" }}>연결된 선수</LabelPost>
                {(playerId === "" || playerId === null) && (
                  <LabelPost>*연결되지 않음</LabelPost>
                )}
                {playerId !== "" &&
                  playerId !== null &&
                  !onePlayerQuery.loading &&
                  onePlayerQuery.data &&
                  onePlayerQuery.data.player && (
                    <>
                      <LabelPost>ID: {playerId}</LabelPost>
                      <LabelPost>
                        이름: {onePlayerQuery.data.player.name}
                      </LabelPost>
                      <LabelPost>
                        순위: {onePlayerQuery.data.player.rank}
                      </LabelPost>
                      <Button onClick={() => setPlayerId("")}>
                        {"- 선수연결해제"}
                      </Button>
                    </>
                  )}

                {
                  // <Wrap>
                  //   <LabelPost>프로필 이미지</LabelPost>
                  //   <ImgPreview imgurl={postImgUrl} />
                  //   <ImgInput type="file" onChange={uploadImg} />
                  // </Wrap>
                }
                <hr />
                <ContainerWrap>
                  <LabelPost>선수 검색</LabelPost>
                  <Wrap>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      style={{ padding: "8px", fontSize: "16px" }}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          setPlayerName(search);
                        }
                      }}
                    />
                    <button
                      style={{ fontSize: "16px" }}
                      onClick={() => setPlayerName(search)}
                    >
                      검색
                    </button>
                  </Wrap>
                  <Rowh style={{ marginTop: "32px" }}>
                    <Colh flex={1}>순위</Colh>
                    <Colh flex={1}>이름</Colh>
                    <Colh flex={2}>TEL</Colh>
                    <Colh flex={2}>총점수</Colh>
                    <Colh flex={2}>총상금</Colh>
                    <Colh flex={1}>연결</Colh>
                  </Rowh>
                  {!playerQuery.loading &&
                    playerQuery.data &&
                    playerQuery.data.players &&
                    playerQuery.data.players.map((player) => (
                      <div key={player.id}>
                        <Row>
                          <Col flex={1}>{player.rank}</Col>
                          <Col flex={1}>{player.name}</Col>
                          <Col flex={2}>{player.tel}</Col>
                          <Col flex={2}>{numberFormat(player.score)} pts</Col>
                          <Col flex={2}>{player.prize + "k"}</Col>
                          <Col flex={1}>
                            <button onClick={() => setPlayerId(player.id)}>
                              연결하기
                            </button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                </ContainerWrap>
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 200px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(p) => p.theme.verylightgreyColor};
  padding: 16px;
  padding-bottom: 1px;
  margin-top: 16px;
  display: block;

  & .default {
    display: block;
  }

  &.show .default {
    display: none;
  }

  & .update {
    display: none;
  }

  &.show .update {
    display: block;
  }
`;

const ContainerWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const ContainerWrap2 = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled.div``;

const UpdateLabel = styled.div`
  color: black;
  cursor: pointer;
  display: block;
  text-align: right;
  margin-bottom: 16px;
`;

const DeleteLabel = styled.div`
  color: red;
  cursor: pointer;
  float: right;
  margin-bottom: 16px;
`;

const DeleteConfirm = styled.div`
  color: red;
  cursor: pointer;
  margin-left: 16px;
`;

const DeleteWrap = styled.div`
  width: 100%;
  margin-top: 16px;
  justify-content: flex-end;

  ${(p) => (p.show ? "display:flex;" : "display:none;")};
  margin-bottom: 16px;
`;

const Rowh = styled.div`
  border-top: 4px solid black;
  border-bottom: 4px solid black;
  display: flex;
  flex-direction: row;
`;

const Colh = styled.div`
  font-size: 16px;
  flex: 1;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weigth: 600;

  flex: ${(p) => p.flex};
`;

const Row = styled.div`
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  flex: 1;
  font-size: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
  flex: ${(p) => p.flex};
`;
