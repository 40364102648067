import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

// const CREATE_DRAFT = gql`
//   mutation newCM {
//     newCM {
//       id
//     }
//   }
// `;

// const GET_ALL = gql`
//   {
//     abouts {
//       id
//       name
//       published
//     }
//   }
// `;

const GET_ONE = gql`
  query about($id: Int!) {
    post: about(where: { id: $id }) {
      id
      r1
      r2
      r3
      r4
      r5
      r6
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateOneAbout(
    $id: Int!
    $r1: String
    $r2: String
    $r3: String
    $r4: String
    $r5: String
    $r6: String
  ) {
    updateOneAbout(
      where: { id: $id }
      data: { r1: $r1, r2: $r2, r3: $r3, r4: $r4, r5: $r5, r6: $r6 }
    ) {
      id
      r1
      r2
      r3
      r4
      r5
      r6
    }
  }
`;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  // const [postName, setPostName] = React.useState("");
  // const [postPublished, setPostPublished] = React.useState(false);
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postState, setPostState] = React.useState({
    r1: [],
    r2: [],
    r3: [],
    r4: [],
    r5: [],
    r6: [],
  });

  const [inputState, setInputState] = React.useState({
    r2: {
      head: "",
      body: "",
    },
    r3: {
      head: "",
      body: "",
    },
    r4: {
      head: "",
      body: "",
    },
    r5: {
      head: "",
      body: "",
    },
    r6: {
      head: "",
      body: "",
    },
  });

  const [delState, setDelState] = React.useState({
    r2: -1,
    r3: -1,
    r4: -1,
    r5: -1,
    r6: -1,
  });
  // const [postLinkUrl, setPostLinkUrl] = React.useState("");

  // const { data, loading } = useQuery(GET_ALL);

  const me = useQuery(ME);

  const postQuery = useQuery(GET_ONE, {
    variables: {
      id: parseInt(postid),
    },
    fetchPolicy: "network-only",
  });

  // console.log(postQuery);

  // const [createDraftMutation] = useMutation(CREATE_DRAFT, {
  //   refetchQueries: [{ query: GET_ALL }]
  // });

  const [updatePostMutation] = useMutation(UPDATE_ONE);

  const toPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  // const draftPage = () => {
  //   createDraftMutation();
  // };

  const uploadFile = (e) => {
    const today = new Date();
    // AXIOS
    // const formData = new FormData();
    const file = e.target.files[0];
    const contentType = file.type;
    // console.log(file);
    // const name = file.name;
    // const [, type] = name.split(".");
    // const splited = name.split(".");

    // const generatePutUrl = "http://localhost:4000/s3put";
    const generatePutUrl = "https://backend.ksop.co.kr/s3put";
    const options = {
      params: {
        Key:
          "files/" +
          today.getFullYear().toString() +
          "/" +
          today.getMonth() +
          "/" +
          file.name,
        ContentType: contentType,
      },
      headers: {
        "Content-Type": contentType,
      },
    };

    axios.get(generatePutUrl, options).then((res) => {
      const {
        data: { putURL },
      } = res;
      console.log(putURL);
      axios
        .put(putURL, file, {})
        .then((res) => {
          // console.log(res);
          setInputState({
            ...inputState,
            r2: {
              body:
                "https://cdnksop.s3.ap-northeast-2.amazonaws.com/files/" +
                today.getFullYear().toString() +
                "/" +
                today.getMonth() +
                "/" +
                file.name,
              head: inputState.r2.head,
            },
          });

          setUploading(false);
        })
        .catch((err) => {
          // this.setState({ message: "Sorry, something went wrong" });
          console.error("err", err);
        });
    });
  };

  const uploadFile2 = (e) => {
    const today = new Date();
    // AXIOS
    // const formData = new FormData();
    const file = e.target.files[0];
    const contentType = file.type;
    // console.log(file);
    // const name = file.name;
    // const [, type] = name.split(".");
    // const splited = name.split(".");

    // const generatePutUrl = "http://localhost:4000/s3put";
    const generatePutUrl = "https://backend.ksop.co.kr/s3put";
    const options = {
      params: {
        Key:
          "files/" +
          today.getFullYear().toString() +
          "/" +
          today.getMonth() +
          "/" +
          file.name,
        ContentType: contentType,
      },
      headers: {
        "Content-Type": contentType,
      },
    };

    axios.get(generatePutUrl, options).then((res) => {
      const {
        data: { putURL },
      } = res;
      console.log(putURL);
      axios
        .put(putURL, file, {})
        .then((res) => {
          // console.log(res);
          setPostState({
            ...postState,
            r2:
              "https://cdnksop.s3.ap-northeast-2.amazonaws.com/files/" +
              today.getFullYear().toString() +
              "/" +
              today.getMonth() +
              "/" +
              file.name,
          });

          setUploading(false);
        })
        .catch((err) => {
          // this.setState({ message: "Sorry, something went wrong" });
          console.error("err", err);
        });
    });
  };

  const uploadFile3 = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    formData.append("file", e.target.files[0], "img." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setInputState({
            ...inputState,
            r3: {
              body: response.data.location,
              body2: inputState.r3.body2,
              head: inputState.r3.head,
            },
          });
          setUploading(false);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadFile4 = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    formData.append("file", e.target.files[0], "img." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setInputState({
            ...inputState,
            r3: {
              body2: response.data.location,
              body: inputState.r3.body,
              head: inputState.r3.head,
            },
          });
          setUploading(false);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  // const handleImgUrl = e => {
  //   setPostImgUrl(e.target.value);
  // };

  const resetState = () => {
    setPostid("");
    setPostImgUrl("");
    setPostState({
      r1: [],
      r2: [],
      r3: [],
      r4: [],
      r5: [],
      r6: [],
    });
  };

  React.useEffect(() => {
    if (postQuery.loading === false) {
      if (postQuery.data.post && postQuery.data.post !== null) {
        {
          if (postid === 1) {
            setPostState({
              r1: postQuery.data.post.r1,
              r2: JSON.parse(postQuery.data.post.r2),
              r3: JSON.parse(postQuery.data.post.r3),
              r4: JSON.parse(postQuery.data.post.r4),
              r5: JSON.parse(postQuery.data.post.r5),
              r6: postQuery.data.post.r6,
            });
          } else if (postid === 2) {
            setPostState({
              r1: postQuery.data.post.r1,
              r2: JSON.parse(postQuery.data.post.r2),
              r3: postQuery.data.post.r3,
              r4: JSON.parse(postQuery.data.post.r4),
              r5: "",
              r6: "",
            });
          } else if (postid === 3) {
            setPostState({
              r1: postQuery.data.post.r1,
              r2: postQuery.data.post.r2,
              r3: "",
              r4: "",
              r5: "",
              r6: "",
            });
          } else if (postid === 4) {
            setPostState({
              r1: postQuery.data.post.r1,
              r2: JSON.parse(postQuery.data.post.r2),
              r3: JSON.parse(postQuery.data.post.r3),
              r4: JSON.parse(postQuery.data.post.r4),
              r5: JSON.parse(postQuery.data.post.r5),
              r6: JSON.parse(postQuery.data.post.r6),
            });
          } else if (postid === 5) {
            setPostState({
              r1: postQuery.data.post.r1,
              r2: postQuery.data.post.r2,
              r3: JSON.parse(postQuery.data.post.r3),
              r4: "",
              r5: "",
              r6: "",
            });
          } else if (postid === 6) {
            setPostState({
              r1: postQuery.data.post.r1,
              r2: "",
              r3: "",
              r4: "",
              r5: "",
              r6: "",
            });
          }
        }
      }
    }
  }, [postQuery.loading, postQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>소개&안내</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          {
            // <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          }
          <Wrapper>
            <LabelPost
              onClick={() => {
                setPostid(1);
                setPage("about");
              }}
              style={{ cursor: "pointer" }}
            >
              ▶ KSOP에 대하여
            </LabelPost>
            <Separator />
            <LabelPost
              onClick={() => {
                setPostid(2);
                setPage("rules");
              }}
              style={{ cursor: "pointer" }}
            >
              ▶ 대회 규칙 가이드
            </LabelPost>
            <Separator />
            <LabelPost
              onClick={() => {
                setPostid(3);
                setPage("sponsor");
              }}
              style={{ cursor: "pointer" }}
            >
              ▶ 협찬 기업 모집
            </LabelPost>
            <Separator />
            <LabelPost
              onClick={() => {
                setPostid(4);
                setPage("recruit");
              }}
              style={{ cursor: "pointer" }}
            >
              ▶ 채용 정보
            </LabelPost>
            <Separator />
            <LabelPost
              onClick={() => {
                setPostid(5);
                setPage("link");
              }}
              style={{ cursor: "pointer" }}
            >
              ▶ 링크용 배너
            </LabelPost>
            <Separator />
            <LabelPost
              onClick={() => {
                setPostid(6);
                setPage("warning");
              }}
              style={{ cursor: "pointer" }}
            >
              ▶ 이벤트 주의사항
            </LabelPost>
          </Wrapper>
        </>
      )}
      {page === "about" && (
        <>
          <Title>KSOP에 대하여 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button
              onClick={() => {
                updatePostMutation({
                  variables: {
                    id: postid,
                    r1: postState.r1,
                    r2: JSON.stringify(postState.r2),
                    r3: JSON.stringify(postState.r3),
                    r4: JSON.stringify(postState.r4),
                    r5: JSON.stringify(postState.r5),
                    r6: postState.r6,
                  },
                });
                setPage("overview");
                resetState();
              }}
            >
              {"V 적용하기"}
            </Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>머릿말</LabelPost>
                <textarea
                  style={{ width: "800px", height: "150px" }}
                  value={postState.r1}
                  onChange={(e) =>
                    setPostState({ ...postState, r1: e.target.value })
                  }
                />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>회사 소개</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r2.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r2: {
                            head: e.target.value,
                            body: inputState.r2.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r2.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r2: {
                            body: e.target.value,
                            head: inputState.r2.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r2.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r2.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r2;
                              newArray.splice(index, 0, {
                                head: inputState.r2.head,
                                body: inputState.r2.body,
                              });

                              setPostState({
                                ...postState,
                                r2: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r2 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r2: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r2: -1 });

                                var newArray = postState.r2;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r2: newArray });
                              }}
                              style={
                                delState.r2 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r2: -1 });
                              }}
                              style={
                                delState.r2 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r2: [
                        ...postState.r2,
                        { head: inputState.r2.head, body: inputState.r2.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>

                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>협찬/후원</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r3.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r3: {
                            head: e.target.value,
                            body: inputState.r3.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r3.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r3: {
                            body: e.target.value,
                            head: inputState.r3.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r3.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r3.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r3;
                              newArray.splice(index, 0, {
                                head: inputState.r3.head,
                                body: inputState.r3.body,
                              });

                              setPostState({
                                ...postState,
                                r3: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r3 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r3: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r3: -1 });

                                var newArray = postState.r3;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r3: newArray });
                              }}
                              style={
                                delState.r3 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r3: -1 });
                              }}
                              style={
                                delState.r3 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r3: [
                        ...postState.r3,
                        { head: inputState.r3.head, body: inputState.r3.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>

                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>조직</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력(세줄로 입력시 첫줄은 직책, 둘째줄은 이름, 셋째줄은
                    영어이름)
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r4.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r4: {
                            head: e.target.value,
                            body: inputState.r4.body,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "200px",
                        height: "150px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r4.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r4: {
                            body: e.target.value,
                            head: inputState.r4.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r4.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r4.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r4;
                              newArray.splice(index, 0, {
                                head: inputState.r4.head,
                                body: inputState.r4.body,
                              });

                              setPostState({
                                ...postState,
                                r4: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r4 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r4: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r4: -1 });

                                var newArray = postState.r4;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r4: newArray });
                              }}
                              style={
                                delState.r4 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r4: -1 });
                              }}
                              style={
                                delState.r4 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r4: [
                        ...postState.r4,
                        { head: inputState.r4.head, body: inputState.r4.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>

                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>약관 및 면책사항</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력 (*번호는 자동입력되므로 입력하지 말것)
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r5.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r5: {
                            head: e.target.value,
                            body: inputState.r5.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r5.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r5: {
                            body: e.target.value,
                            head: inputState.r5.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r5.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r5.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r5;
                              newArray.splice(index, 0, {
                                head: inputState.r5.head,
                                body: inputState.r5.body,
                              });

                              setPostState({
                                ...postState,
                                r5: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r5 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r5: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r5: -1 });

                                var newArray = postState.r5;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r5: newArray });
                              }}
                              style={
                                delState.r5 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r5: -1 });
                              }}
                              style={
                                delState.r5 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r5: [
                        ...postState.r5,
                        { head: inputState.r5.head, body: inputState.r5.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>
                  개인 정보 보호 정책 (*번호는 자동입력되므로 입력하지 말것)
                </LabelPost>
                <textarea
                  style={{ width: "800px", height: "150px" }}
                  value={postState.r6}
                  onChange={(e) =>
                    setPostState({ ...postState, r6: e.target.value })
                  }
                />
              </>
            )}
          </WrapperPost>
        </>
      )}
      {page === "rules" && (
        <>
          <Title>대회 규칙 가이드 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button
              onClick={() => {
                updatePostMutation({
                  variables: {
                    id: postid,
                    r1: postState.r1,
                    r2: JSON.stringify(postState.r2),
                    r3: postState.r3,
                    r4: JSON.stringify(postState.r4),
                    r5: "",
                    r6: "",
                  },
                });
                setPage("overview");
                resetState();
              }}
            >
              {"V 적용하기"}
            </Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>머릿말</LabelPost>
                <textarea
                  style={{ width: "800px", height: "150px" }}
                  value={postState.r1}
                  onChange={(e) =>
                    setPostState({ ...postState, r1: e.target.value })
                  }
                />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>TDA 자료</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    이름
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    링크
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r2.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r2: {
                            head: e.target.value,
                            body: inputState.r2.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r2.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r2: {
                            body: e.target.value,
                            head: inputState.r2.head,
                          },
                        })
                      }
                    />
                    <Wrap>
                      <ImgInput
                        disabled={uploading}
                        type="file"
                        onChange={(e) => {
                          setUploading(true);
                          uploadFile(e);
                        }}
                      />
                      {uploading && <div>!! 업로드중...</div>}
                    </Wrap>
                  </div>
                </div>
                {postState.r2.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r2.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r2;
                              newArray.splice(index, 0, {
                                head: inputState.r2.head,
                                body: inputState.r2.body,
                              });

                              setPostState({
                                ...postState,
                                r2: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r2 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r2: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r2: -1 });

                                var newArray = postState.r2;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r2: newArray });
                              }}
                              style={
                                delState.r2 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r2: -1 });
                              }}
                              style={
                                delState.r2 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r2: [
                        ...postState.r2,
                        { head: inputState.r2.head, body: inputState.r2.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>

                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>대회 규칙-작은설명</LabelPost>
                <InputPost
                  value={postState.r3}
                  onChange={(e) =>
                    setPostState({ ...postState, r3: e.target.value })
                  }
                  long={true}
                />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>대회 규칙</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더 입력 (*번호 입력 안해도됨)
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용 입력
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r4.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r4: {
                            head: e.target.value,
                            body: inputState.r4.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r4.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r4: {
                            body: e.target.value,
                            head: inputState.r4.head,
                          },
                        })
                      }
                      style={{ width: "500px", height: "150px" }}
                    />
                  </div>
                </div>
                {postState.r4.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r4.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r4;
                              newArray.splice(index, 0, {
                                head: inputState.r4.head,
                                body: inputState.r4.body,
                              });

                              setPostState({
                                ...postState,
                                r4: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r4 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r4: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r4: -1 });

                                var newArray = postState.r4;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r4: newArray });
                              }}
                              style={
                                delState.r4 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r4: -1 });
                              }}
                              style={
                                delState.r4 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r4: [
                        ...postState.r4,
                        { head: inputState.r4.head, body: inputState.r4.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>
              </>
            )}
          </WrapperPost>
        </>
      )}
      {page === "sponsor" && (
        <>
          <Title>협찬 기업 모집 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button
              onClick={() => {
                updatePostMutation({
                  variables: {
                    id: postid,
                    r1: postState.r1,
                    r2: postState.r2,
                    r3: "",
                    r4: "",
                    r5: "",
                    r6: "",
                  },
                });
                setPage("overview");
                resetState();
              }}
            >
              {"V 적용하기"}
            </Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>
                  머릿말 (*마지막 두줄은 각각 '기관명', '대표이름'이 되도록)
                </LabelPost>
                <textarea
                  style={{ width: "800px", height: "150px" }}
                  value={postState.r1}
                  onChange={(e) =>
                    setPostState({ ...postState, r1: e.target.value })
                  }
                />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>협찬 혜택 (*번호는 적지않아도 됨)</LabelPost>
                <textarea
                  style={{ width: "800px", height: "150px" }}
                  value={postState.r2}
                  onChange={(e) =>
                    setPostState({ ...postState, r2: e.target.value })
                  }
                />
              </>
            )}
          </WrapperPost>
        </>
      )}
      {page === "recruit" && (
        <>
          <Title>채용 정보 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button
              onClick={() => {
                updatePostMutation({
                  variables: {
                    id: postid,
                    r1: postState.r1,
                    r2: JSON.stringify(postState.r2),
                    r3: JSON.stringify(postState.r3),
                    r4: JSON.stringify(postState.r4),
                    r5: JSON.stringify(postState.r5),
                    r6: JSON.stringify(postState.r6),
                  },
                });
                setPage("overview");
                resetState();
              }}
            >
              {"V 적용하기"}
            </Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>머릿말</LabelPost>
                <textarea
                  style={{ width: "800px", height: "150px" }}
                  value={postState.r1}
                  onChange={(e) =>
                    setPostState({ ...postState, r1: e.target.value })
                  }
                />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>업무 내용</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r2.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r2: {
                            head: e.target.value,
                            body: inputState.r2.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r2.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r2: {
                            body: e.target.value,
                            head: inputState.r2.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r2.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r2.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r2;
                              newArray.splice(index, 0, {
                                head: inputState.r2.head,
                                body: inputState.r2.body,
                              });

                              setPostState({
                                ...postState,
                                r2: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r2 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r2: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r2: -1 });

                                var newArray = postState.r2;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r2: newArray });
                              }}
                              style={
                                delState.r2 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r2: -1 });
                              }}
                              style={
                                delState.r2 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r2: [
                        ...postState.r2,
                        { head: inputState.r2.head, body: inputState.r2.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>

                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>경험/기술</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r3.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r3: {
                            head: e.target.value,
                            body: inputState.r3.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r3.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r3: {
                            body: e.target.value,
                            head: inputState.r3.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r3.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r3.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r3;
                              newArray.splice(index, 0, {
                                head: inputState.r3.head,
                                body: inputState.r3.body,
                              });

                              setPostState({
                                ...postState,
                                r3: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r3 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r3: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r3: -1 });

                                var newArray = postState.r3;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r3: newArray });
                              }}
                              style={
                                delState.r3 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r3: -1 });
                              }}
                              style={
                                delState.r3 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r3: [
                        ...postState.r3,
                        { head: inputState.r3.head, body: inputState.r3.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>

                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>근무지</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r4.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r4: {
                            head: e.target.value,
                            body: inputState.r4.body,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "200px",
                        height: "150px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r4.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r4: {
                            body: e.target.value,
                            head: inputState.r4.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r4.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r4.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r4;
                              newArray.splice(index, 0, {
                                head: inputState.r4.head,
                                body: inputState.r4.body,
                              });

                              setPostState({
                                ...postState,
                                r4: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r4 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r4: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r4: -1 });

                                var newArray = postState.r4;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r4: newArray });
                              }}
                              style={
                                delState.r4 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r4: -1 });
                              }}
                              style={
                                delState.r4 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r4: [
                        ...postState.r4,
                        { head: inputState.r4.head, body: inputState.r4.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>

                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>정규직/계약직</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r5.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r5: {
                            head: e.target.value,
                            body: inputState.r5.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r5.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r5: {
                            body: e.target.value,
                            head: inputState.r5.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r5.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r5.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r5;
                              newArray.splice(index, 0, {
                                head: inputState.r5.head,
                                body: inputState.r5.body,
                              });

                              setPostState({
                                ...postState,
                                r5: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r5 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r5: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r5: -1 });

                                var newArray = postState.r5;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r5: newArray });
                              }}
                              style={
                                delState.r5 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r5: -1 });
                              }}
                              style={
                                delState.r5 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r5: [
                        ...postState.r5,
                        { head: inputState.r5.head, body: inputState.r5.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>아르바이트</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    헤더입력
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    내용입력 (*번호는 자동입력되므로 입력하지 말것)
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r6.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r6: {
                            head: e.target.value,
                            body: inputState.r6.body,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <textarea
                      value={inputState.r6.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r6: {
                            body: e.target.value,
                            head: inputState.r6.head,
                          },
                        })
                      }
                      style={{
                        marginBottom: "0px",
                        width: "800px",
                        height: "150px",
                      }}
                    />
                  </div>
                </div>
                {postState.r6.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r6.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r6;
                              newArray.splice(index, 0, {
                                head: inputState.r6.head,
                                body: inputState.r6.body,
                              });

                              setPostState({
                                ...postState,
                                r6: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r6 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r6: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r6: -1 });

                                var newArray = postState.r6;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r6: newArray });
                              }}
                              style={
                                delState.r6 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r6: -1 });
                              }}
                              style={
                                delState.r6 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r6: [
                        ...postState.r6,
                        { head: inputState.r6.head, body: inputState.r6.body },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>
              </>
            )}
          </WrapperPost>
        </>
      )}

      {page === "link" && (
        <>
          <Title>링크용 배너 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button
              onClick={() => {
                updatePostMutation({
                  variables: {
                    id: postid,
                    r1: postState.r1,
                    r2: postState.r2,
                    r3: JSON.stringify(postState.r3),
                    r4: "",
                    r5: "",
                    r6: "",
                  },
                });
                setPage("overview");
                resetState();
              }}
            >
              {"V 적용하기"}
            </Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>머릿말</LabelPost>
                <textarea
                  style={{ width: "800px", height: "150px" }}
                  value={postState.r1}
                  onChange={(e) =>
                    setPostState({ ...postState, r1: e.target.value })
                  }
                />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>ZIP 파일</LabelPost>
                <InputPost
                  value={postState.r2}
                  onChange={(e) =>
                    setPostState({
                      ...postState,
                      r2: e.target.value,
                    })
                  }
                />
                <Wrap>
                  <ImgInput
                    disabled={uploading}
                    type="file"
                    onChange={(e) => {
                      setUploading(true);
                      uploadFile2(e);
                    }}
                  />
                  {uploading && <div>!! 업로드중...</div>}
                </Wrap>
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>배너 이미지</LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    크기 입력
                  </div>
                  <div
                    style={{
                      flex: "2",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    배너 이미지1
                  </div>
                  <div
                    style={{
                      flex: "2",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    배너 이미지2
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r3.head}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r3: {
                            head: e.target.value,
                            body: inputState.r3.body,
                            body2: inputState.r3.body2,
                          },
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "2",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r3.body}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r3: {
                            head: inputState.r3.head,
                            body: e.target.value,
                            body2: inputState.r3.body2,
                          },
                        })
                      }
                    />
                    <Wrap>
                      <ImgInput
                        disabled={uploading}
                        type="file"
                        onChange={(e) => {
                          setUploading(true);
                          uploadFile3(e);
                        }}
                      />
                      {uploading && <div>!! 업로드중...</div>}
                    </Wrap>
                  </div>
                  <div
                    style={{
                      flex: "2",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.r3.body2}
                      onChange={(e) =>
                        setInputState({
                          ...inputState,
                          r3: {
                            body2: e.target.value,
                            head: inputState.r3.head,
                            body: inputState.r3.body,
                          },
                        })
                      }
                    />
                    <Wrap>
                      <ImgInput
                        disabled={uploading}
                        type="file"
                        onChange={(e) => {
                          setUploading(true);
                          uploadFile4(e);
                        }}
                      />
                      {uploading && <div>!! 업로드중...</div>}
                    </Wrap>
                  </div>
                </div>
                {postState.r3.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.r3.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = postState.r3;
                              newArray.splice(index, 0, {
                                head: inputState.r3.head,
                                body: inputState.r3.body,
                                body2: inputState.r3.body2,
                              });

                              setPostState({
                                ...postState,
                                r3: newArray,
                              });
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body2.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState.r3 === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() =>
                                setDelState({ ...delState, r3: index })
                              }
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r3: -1 });

                                var newArray = postState.r3;
                                newArray.splice(index, 1);
                                setPostState({ ...postState, r3: newArray });
                              }}
                              style={
                                delState.r3 === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState({ ...delState, r3: -1 });
                              }}
                              style={
                                delState.r3 === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState({
                      ...postState,
                      r3: [
                        ...postState.r3,
                        {
                          head: inputState.r3.head,
                          body: inputState.r3.body,
                          body2: inputState.r3.body2,
                        },
                      ],
                    })
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>
              </>
            )}
          </WrapperPost>
        </>
      )}

      {page === "warning" && (
        <>
          <Title>이벤트 주의사항 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button
              onClick={() => {
                updatePostMutation({
                  variables: {
                    id: postid,
                    r1: postState.r1,
                    r2: "",
                    r3: "",
                    r4: "",
                    r5: "",
                    r6: "",
                  },
                });
                setPage("overview");
                resetState();
              }}
            >
              {"V 적용하기"}
            </Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>주의사항 내용 (*체크기호 넣지말것)</LabelPost>
                <textarea
                  style={{ width: "800px", height: "150px" }}
                  value={postState.r1}
                  onChange={(e) =>
                    setPostState({ ...postState, r1: e.target.value })
                  }
                />
              </>
            )}
          </WrapperPost>
        </>
      )}
      {page === "post" && (
        <>
          <Title>파트너사 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>ID: {postQuery.data.post.id}</LabelPost>
                {
                  // <Wrap>
                  //   <LabelPost>이미지</LabelPost>
                  //   <ImgPreview imgurl={postImgUrl} />
                  //   <ImgInput type="file" onChange={uploadImg} />
                  // </Wrap>
                }
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
