import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const TICKET_META = gql`
  {
    ticketMeta {
      id
      title
      published
    }
  }
`;

const NEW_TICKET = gql`
  mutation createTicket(
    $playerId: ID!
    $title: String!
    $count: Int!
    $category: Int!
  ) {
    createTicket(
      playerId: $playerId
      title: $title
      count: $count
      category: $category
    ) {
      id
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation createTicketMeta {
    createTicketMeta {
      id
    }
  }
`;

const UPDATE_TICKET = gql`
  mutation updateOneTicket($id: ID!, $count: Int!) {
    updateOneTicket(id: $id, count: $count) {
      id
      count
      title
      category
    }
  }
`;

const DELETE_TICKET = gql`
  mutation deleteTicket($ticketId: ID!) {
    deleteTicket(ticketId: $ticketId) {
      id
    }
  }
`;

const TICKETS = gql`
  query tickets($playerId: ID!, $category: Int!) {
    tickets(playerId: $playerId, category: $category) {
      id
      title
      count
      createdAt
    }
  }
`;

const GET_ALL = gql`
  {
    items: ticketMeta {
      id
      title
      published
    }
  }
`;

const GET_ONE = gql`
  query oneTicketMeta($id: Int!) {
    post: oneTicketMeta(id: $id) {
      id
      title
      published
    }
  }
`;

const GET_LOGS = gql`
  query getTicketLog(
    $category: Int!
    $first: Int!
    $skip: Int!
    $date: String!
  ) {
    logs: getTicketLog(
      category: $category
      first: $first
      skip: $skip
      date: $date
    ) {
      id
      count
      createdAt
      player {
        id
        name
        tel
        rank
        team
        score
      }
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateTicketMeta(
    $ticketMetaId: Int!
    $title: String!
    $published: Boolean!
  ) {
    updateTicketMeta(
      ticketMetaId: $ticketMetaId
      published: $published
      title: $title
    ) {
      id
      title
      published
    }
  }
`;

const DELETE_ONE = gql`
  mutation deleteBanner($id: ID!) {
    deleteBanner(id: $id) {
      id
    }
  }
`;

const today = new Date();

export default () => {
  const limit = 10;

  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState(0);
  const [postPriority, setPostPriority] = React.useState(0);
  const [postTitle, setPostTitle] = React.useState("");
  const [searchState, setSearchState] = React.useState({
    input: "",
    output: "",
  });
  const [postName, setPostName] = React.useState("");
  const [postTeam, setPostTeam] = React.useState("");
  const [postRank, setPostRank] = React.useState(0);
  const [playerState, setPlayerState] = React.useState({
    id: "",
  });
  const [postPublished, setPostPublished] = React.useState(false);
  const [postCategory, setPostCategory] = React.useState("이미지");
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postLinkUrl, setPostLinkUrl] = React.useState("");
  const meta = useQuery(TICKET_META);

  const [ticketState, setTicketState] = React.useState({
    category: 1,
    title: "",
    count: 0,
    ticketId: "",
  });

  const [ticketModState, setTicketModState] = React.useState({
    id: "",
    count: 0,
  });

  const [skip, setSkip] = React.useState(0);

  const { data, loading } = useQuery(GET_ALL);

  const me = useQuery(ME);

  const tickets = useQuery(TICKETS, {
    variables: { playerId: playerState.id, category: ticketState.category },
  });

  const [createTicket] = useMutation(NEW_TICKET, {
    variables: {
      playerId: playerState.id,
      title: ticketState.title,
      count: ticketState.count,
      category: ticketState.category,
    },
    refetchQueries: [
      {
        query: TICKETS,
        variables: { playerId: playerState.id, category: ticketState.category },
      },
    ],
  });

  const [deleteTicket] = useMutation(DELETE_TICKET, {
    variables: {
      ticketId: ticketState.ticketId,
    },
    refetchQueries: [
      {
        query: TICKETS,
        variables: { playerId: playerState.id, category: ticketState.category },
      },
    ],
  });

  const [updateTicket] = useMutation(UPDATE_TICKET, {
    variables: {
      id: ticketModState.id,
      count: ticketModState.count,
    },
    refetchQueries: [
      {
        query: TICKETS,
        variables: { playerId: playerState.id, category: ticketState.category },
      },
    ],
  });

  const postQuery = useQuery(GET_ONE, {
    variables: {
      id: postid,
    },
    fetchPolicy: "network-only",
  });

  const logQuery = useQuery(GET_LOGS, {
    variables: {
      category: postid,
      first: limit,
      skip: skip,
      date: searchState.output,
    },
  });

  // console.log(postQuery);

  const [createDraftMutation] = useMutation(CREATE_DRAFT, {
    refetchQueries: [{ query: GET_ALL }],
  });

  const [deletePostMutation] = useMutation(DELETE_ONE, {
    variables: {
      id: postid,
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const [updatePostMutation] = useMutation(UPDATE_ONE, {
    variables: {
      ticketMetaId: postid,
      title: postTitle,
      published: postPublished,
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const toPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createDraftMutation();
  };

  const deletePost = () => {
    setPage("overview");
    resetState();
    deletePostMutation();
  };

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const splited = name.split(".");

    formData.append(
      "file",
      e.target.files[0],
      "img." + splited[splited.length - 1]
    );

    try {
      axios
        .post(
          // "https://pokertoday-backend.xero0001.now.sh/upload",
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  // const handleImgUrl = e => {
  //   setPostImgUrl(e.target.value);
  // };

  const numberFormat = (inputNumber) => {
    var parts = inputNumber.toString().split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "")
    );
  };

  const resetState = () => {
    setPostid(0);
    setPostTitle("");
    setPostPublished(false);
  };

  React.useEffect(() => {
    if (postQuery.loading === false) {
      if (postQuery.data.post && postQuery.data.post !== null) {
        setPostTitle(postQuery.data.post.title);
        setPostPublished(postQuery.data.post.published);
      }
    }
  }, [postQuery.loading, postQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>티켓종류</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="1">인덱스</Label>
              <Label flex="1">이름</Label>
              <Label flex="1">공개</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.items &&
              data.items.map((item) => {
                if (item.category !== "상단배너") {
                  return (
                    <Item
                      key={item.id}
                      pointer={true}
                      onClick={() => toPost(item.id)}
                      published={item.published}
                    >
                      <Label flex="1">{item.id}</Label>
                      <Label flex="1">{item.title}</Label>
                      <Label flex="1">{item.published ? "O" : "X"}</Label>
                    </Item>
                  );
                }
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>티켓종류 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>ID: {postQuery.data.post.id}</LabelPost>
                <LabelPost>이름</LabelPost>
                <InputPost
                  value={postTitle}
                  onChange={(e) => setPostTitle(e.target.value)}
                  long={true}
                />
                <Wrap>
                  <LabelPost>공개여부</LabelPost>
                  <Select
                    name="published"
                    value={postPublished}
                    onChange={handlePublished}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <hr style={{ marginBottom: "32px" }} />
                <LabelPost>날짜검색</LabelPost>
                <Wrap>
                  <InputPost
                    style={{ marginBottom: "0px", height: "24px" }}
                    value={searchState.input}
                    placeholder={
                      today.getFullYear().toString() +
                      pad((today.getMonth() + 1).toString(), 2) +
                      pad(today.getDate(), 2)
                    }
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        setSearchState({
                          input: searchState.input,
                          output: searchState.input,
                        });
                        setSkip(0);
                      }
                    }}
                    onChange={(e) => {
                      setSearchState({
                        input: e.target.value,
                        output: searchState.output,
                      });
                    }}
                  />
                  <Button
                    style={{ marginTop: "0px", height: "24px" }}
                    onClick={() => {
                      setSearchState({
                        input: searchState.input,
                        output: searchState.input,
                      });
                      setSkip(0);
                    }}
                  >
                    검색하기
                  </Button>
                </Wrap>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 32,
                  }}
                >
                  {skip > 0 && (
                    <button
                      style={{ width: 64 }}
                      onClick={() => setSkip(skip - limit)}
                    >
                      앞으로
                    </button>
                  )}
                  <Label style={{ marginLeft: 16, marginRight: 16 }}>
                    {skip + 1 + " ~ " + (skip + limit)}
                  </Label>
                  <button
                    style={{ width: 64 }}
                    onClick={() => setSkip(skip + limit)}
                  >
                    뒤로
                  </button>
                </div>
                <Wrapper>
                  <Item pointer={false} published={true}>
                    <Label flex="2">사용 일자</Label>
                    <Label flex="1">사용 티켓 수</Label>
                    <Label flex="1">랭킹</Label>
                    <Label flex="1">이름</Label>
                    <Label flex="2">팀</Label>
                    <Label flex="2">점수</Label>
                    <Label flex="2">TEL</Label>
                  </Item>
                  <Separator />
                  {!logQuery.loading &&
                    logQuery.data &&
                    logQuery.data.logs &&
                    logQuery.data.logs.map((log) => {
                      return (
                        <Item
                          key={log.id}
                          pointer={true}
                          onClick={() => {
                            setPage("ticket");
                            setPlayerState({
                              id: log.player.id,
                            });
                            setPostName(log.player.name);
                            setPostTeam(log.player.team);
                            setTicketState({
                              ...ticketState,
                              category: postQuery.data.post.id,
                            });
                          }}
                          published={true}
                        >
                          <Label flex="2">{log.createdAt.substr(0, 19)}</Label>
                          <Label flex="1">{log.count}</Label>
                          <Label flex="1">
                            {log.player.rank === 0
                              ? "신규선수"
                              : "#" + log.player.rank}
                          </Label>
                          <Label flex="1">{log.player.name}</Label>
                          <Label flex="2">{log.player.team}</Label>
                          <Label flex="2">
                            {numberFormat(log.player.score.toFixed(3))} pts
                          </Label>
                          <Label flex="2">{log.player.tel}</Label>
                        </Item>
                      );
                    })}
                </Wrapper>
              </>
            )}
          </WrapperPost>
        </>
      )}
      {page === "ticket" && (
        <>
          <Title>티켓 수정</Title>
          <Button onClick={() => setPage("post")}>{"< 뒤로"}</Button>
          <WrapperPost>
            {!meta.loading && meta.data && meta.data.ticketMeta && (
              <>
                <LabelPost>ID: {playerState.id}</LabelPost>
                <LabelPost>이름: {postName}</LabelPost>
                <LabelPost>팀: {postTeam}</LabelPost>
                <LabelPost>
                  랭킹: {postRank === 0 ? "신규" : "#" + postRank}
                </LabelPost>
                <Wrap>
                  <LabelPost>
                    티켓종류:{" "}
                    {meta.data.ticketMeta[ticketState.category - 1].title}
                  </LabelPost>
                </Wrap>
                <hr />
                <LabelPost>티켓이름</LabelPost>
                <InputPost
                  value={ticketState.title}
                  onChange={(e) =>
                    setTicketState({ ...ticketState, title: e.target.value })
                  }
                />
                <LabelPost>티켓수량</LabelPost>
                <InputPost
                  value={ticketState.count}
                  onChange={(e) =>
                    setTicketState({
                      ...ticketState,
                      count: parseInt(e.target.value),
                    })
                  }
                  type="number"
                />
                <Button
                  onClick={() => {
                    createTicket();
                  }}
                >
                  {"+ 티켓발급"}
                </Button>
                <hr />
                {!tickets.loading &&
                  tickets.data &&
                  tickets.data.tickets &&
                  tickets.data.tickets.map((ticket) => {
                    const date = new Date(ticket.createdAt);

                    return (
                      <div
                        key={ticket.id}
                        style={{
                          backgroundColor: "rgba(0,0,0,0.04)",
                          padding: "16px",
                          marginTop: "24px",
                        }}
                      >
                        <LabelPost>티켓이름: {ticket.title}</LabelPost>
                        <LabelPost>
                          티켓발급일:{" "}
                          {date.getFullYear() +
                            "년 " +
                            (date.getMonth() + 1) +
                            "월 " +
                            date.getDate() +
                            "일 " +
                            pad(date.getHours(), 2) +
                            ":" +
                            pad(date.getMinutes(), 2) +
                            ":" +
                            pad(date.getSeconds(), 2)}
                        </LabelPost>
                        {ticketModState.id === ticket.id ? (
                          <Wrap>
                            <LabelPost>티켓수량</LabelPost>
                            <InputPost
                              value={ticketModState.count}
                              onChange={(e) => {
                                setTicketModState({
                                  id: ticketModState.id,
                                  count: parseInt(e.target.value),
                                });
                              }}
                              type="number"
                            />
                          </Wrap>
                        ) : (
                          <LabelPost>티켓수량:{ticket.count}</LabelPost>
                        )}
                        {ticketState.ticketId !== ticket.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                color: "red",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setTicketState({
                                  ...ticketState,
                                  ticketId: ticket.id,
                                })
                              }
                            >
                              티켓삭제
                            </div>
                          </div>
                        )}
                        {ticketState.ticketId === ticket.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                color: "red",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setTicketState({ ...ticketState, ticketId: "" })
                              }
                            >
                              취소
                            </div>
                            <div
                              style={{
                                color: "red",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                deleteTicket();
                              }}
                            >
                              확인
                            </div>
                          </div>
                        )}
                        {ticketModState.id !== ticket.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              marginTop: "16px",
                            }}
                          >
                            <div
                              style={{
                                color: "blue",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setTicketModState({
                                  id: ticket.id,
                                  count: ticket.count,
                                })
                              }
                            >
                              티켓수정
                            </div>
                          </div>
                        )}
                        {ticketModState.id === ticket.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              marginTop: "16px",
                            }}
                          >
                            <div
                              style={{
                                color: "blue",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setTicketModState({
                                  id: "",
                                  count: 0,
                                });
                              }}
                            >
                              취소
                            </div>
                            <div
                              style={{
                                color: "blue",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                updateTicket();
                                setTicketModState({
                                  id: "",
                                  count: 0,
                                });
                              }}
                            >
                              확인
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
