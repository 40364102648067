import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const ABOUT = gql`
  query about($id: Int!) {
    post: about(where: { id: $id }) {
      id
      r1
      r2
      r3
      r4
      r5
      r6
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateOneAbout(
    $id: Int!
    $r1: String
    $r2: String
    $r3: String
    $r4: String
    $r5: String
    $r6: String
  ) {
    updateOneAbout(
      where: { id: $id }
      data: { r1: $r1, r2: $r2, r3: $r3, r4: $r4, r5: $r5, r6: $r6 }
    ) {
      id
      r1
      r2
      r3
      r4
      r5
      r6
    }
  }
`;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("0");
  const [uploading, setUploading] = React.useState(false);
  const [postState, setPostState] = React.useState({
    r1: "",
    r2: "",
    r3: "",
    r4: "",
    r5: "",
    r6: "",
  });

  const me = useQuery(ME);

  const { data, loading } = useQuery(ABOUT, {
    variables: { id: 8 },
    fetchPolicy: "network-only",
  });

  // console.log(postState);

  const [updatePostMutation] = useMutation(UPDATE_ONE);

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const uploadImg = async (e) => {
    // REACT-S3
    // const config = {
    //   bucketName: "cdn.pokertoday.co.kr",
    //   dirName: "images" /* optional */,
    //   region: "ap-northeast-2",
    //   accessKeyId: "",
    //   secretAccessKey: ""
    // };

    // S3FileUpload.uploadFile(e.target.files[0], config)
    //   .then(data => console.log(data))
    //   .catch(err => console.error(err));

    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    // console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostState({
            ...postState,
            r3: response.data.location,
          });
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadImg2 = async (e) => {
    // REACT-S3
    // const config = {
    //   bucketName: "cdn.pokertoday.co.kr",
    //   dirName: "images" /* optional */,
    //   region: "ap-northeast-2",
    //   accessKeyId: "",
    //   secretAccessKey: ""
    // };

    // S3FileUpload.uploadFile(e.target.files[0], config)
    //   .then(data => console.log(data))
    //   .catch(err => console.error(err));

    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    // console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostState({
            ...postState,
            r4: response.data.location,
          });
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadImg3 = async (e) => {
    // REACT-S3
    // const config = {
    //   bucketName: "cdn.pokertoday.co.kr",
    //   dirName: "images" /* optional */,
    //   region: "ap-northeast-2",
    //   accessKeyId: "",
    //   secretAccessKey: ""
    // };

    // S3FileUpload.uploadFile(e.target.files[0], config)
    //   .then(data => console.log(data))
    //   .catch(err => console.error(err));

    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    // console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostState({
            ...postState,
            r5: response.data.location,
          });
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (loading === false) {
      if (data.post && data.post !== null) {
        console.log(data.post);
        setPostState({
          r1: data.post.r1,
          r2: data.post.r2,
          r3: data.post.r3,
          r4: data.post.r4,
          r5: data.post.r5,
          r6: "",
        });
      }
    }
  }, [loading, data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>KSOP 앱</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button
            onClick={() => {
              updatePostMutation({
                variables: {
                  id: 8,
                  r1: postState.r1,
                  r2: postState.r2,
                  r3: postState.r3,
                  r4: postState.r4,
                  r5: postState.r5,
                  r6: "",
                },
                refetchQueries: [{ query: ABOUT, variables: { id: 8 } }],
              });
            }}
          >
            {"V 적용하기"}
          </Button>
          <WrapperPost>
            {!loading && data && data.post && (
              <>
                <Wrap>
                  <LabelPost>머릿말</LabelPost>
                  <InputPost
                    value={postState.r1}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        r1: e.target.value,
                      });
                    }}
                  />
                </Wrap>
                <LabelPost>설명</LabelPost>
                <textarea
                  value={postState.r2}
                  style={{ width: "100%", height: "96px" }}
                  onChange={(e) => {
                    setPostState({
                      ...postState,
                      r2: e.target.value,
                    });
                  }}
                />
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <Wrap>
                  <LabelPost>이미지1</LabelPost>
                  <ImgPreview imgurl={postState.r3} />
                  <ImgInput type="file" onChange={uploadImg} />
                </Wrap>
                <Wrap>
                  <LabelPost>이미지2</LabelPost>
                  <ImgPreview imgurl={postState.r4} />
                  <ImgInput type="file" onChange={uploadImg2} />
                </Wrap>
                <Wrap>
                  <LabelPost>이미지3</LabelPost>
                  <ImgPreview imgurl={postState.r5} />
                  <ImgInput type="file" onChange={uploadImg3} />
                </Wrap>
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
