import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation newLocation {
    newLocation {
      id
    }
  }
`;

const GET_ALL = gql`
  {
    items: adminLocations {
      id
      title
      location
      address
      tel
    }
  }
`;

const GET_ONE = gql`
  query oneLocation($id: Int!) {
    post: oneLocation(id: $id) {
      id
      title
      imgUrl
      imgUrlOn
      location
      address
      tel
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateLocation(
    $id: Int!
    $title: String!
    $location: String!
    $imgUrl: String!
    $imgUrlOn: String!
    $address: String!
    $tel: String!
  ) {
    updateLocation(
      id: $id
      title: $title
      location: $location
      imgUrl: $imgUrl
      imgUrlOn: $imgUrlOn
      address: $address
      tel: $tel
    ) {
      id
      title
      location
      imgUrl
      imgUrlOn
    }
  }
`;

// const DELETE_ONE = gql`
//   mutation deleteBanner($id: ID!) {
//     deleteBanner(id: $id) {
//       id
//     }
//   }
// `;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState(0);
  const [postTitle, setPostTitle] = React.useState("");
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postImgUrlOn, setPostImgUrlOn] = React.useState("");
  const [postLocation, setPostLocation] = React.useState([0.000001, 0.000001]);
  const [postAddress, setPostAddress] = React.useState("");
  const [postTel, setPostTel] = React.useState("");

  const { data, loading } = useQuery(GET_ALL);

  const me = useQuery(ME);

  const postQuery = useQuery(GET_ONE, {
    variables: {
      id: postid,
    },
    fetchPolicy: "network-only",
  });

  // console.log(postQuery);

  const [createDraftMutation] = useMutation(CREATE_DRAFT, {
    refetchQueries: [{ query: GET_ALL }],
  });

  const [updatePostMutation] = useMutation(UPDATE_ONE, {
    variables: {
      id: postid,
      title: postTitle,
      location: JSON.stringify(postLocation),
      imgUrl: postImgUrl,
      imgUrlOn: postImgUrlOn,
      address: postAddress,
      tel: postTel,
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  // const [updatePostMutation] = useMutation(UPDATE_ONE, {
  //   variables: {
  //     id: 1,
  //     title: postTitle,
  //     location: postLocation,
  //     imgUrl: postImgUrl
  //   },
  //   refetchQueries: [{ query: GET_ALL }]
  // });

  const toPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createDraftMutation();
  };

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadImg2 = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrlOn(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  // const handleImgUrl = e => {
  //   setPostImgUrl(e.target.value);
  // };

  const resetState = () => {
    setPostid(0);
    setPostTitle("");
    setPostImgUrl("");
    setPostImgUrlOn("");
    setPostLocation([0.000001, 0.000001]);
    setPostAddress("");
    setPostTel("");
  };

  React.useEffect(() => {
    if (postQuery.loading === false) {
      if (postQuery.data.post && postQuery.data.post !== null) {
        setPostTitle(postQuery.data.post.title);
        setPostImgUrl(postQuery.data.post.imgUrl);
        setPostImgUrlOn(postQuery.data.post.imgUrlOn);
        setPostLocation(JSON.parse(postQuery.data.post.location));
        setPostAddress(postQuery.data.post.address);
        setPostTel(postQuery.data.post.tel);
      }
    }
  }, [postQuery.loading, postQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>대회장소</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="1">ID</Label>
              <Label flex="2">이름</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.items &&
              data.items.map((item) => {
                return (
                  <Item
                    key={item.id}
                    pointer={true}
                    onClick={() => toPost(item.id)}
                    published={true}
                  >
                    <Label flex="1">{item.id}</Label>
                    <Label flex="2">{item.title}</Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>대회장소 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>ID: {postQuery.data.post.id}</LabelPost>
                <LabelPost>이름</LabelPost>
                <InputPost
                  value={postTitle}
                  onChange={(e) => setPostTitle(e.target.value)}
                  long={true}
                />
                <LabelPost>주소</LabelPost>
                <InputPost
                  value={postAddress}
                  onChange={(e) => setPostAddress(e.target.value)}
                  long={true}
                />
                <LabelPost>TEL</LabelPost>
                <InputPost
                  value={postTel}
                  onChange={(e) => setPostTel(e.target.value)}
                  long={false}
                />
                <Wrap>
                  <LabelPost>위도</LabelPost>
                  <InputPost
                    value={postLocation[1]}
                    onChange={(e) =>
                      setPostLocation([
                        postLocation[0],
                        parseFloat(e.target.value),
                      ])
                    }
                    type="number"
                    step="0.000001"
                    short={true}
                  />
                  <LabelPost>경도</LabelPost>
                  <InputPost
                    value={postLocation[0]}
                    onChange={(e) =>
                      setPostLocation([
                        parseFloat(e.target.value),
                        postLocation[1],
                      ])
                    }
                    type="number"
                    step="0.000001"
                    short={true}
                  />
                </Wrap>
                <Wrap>
                  <LabelPost>이미지_OFF</LabelPost>
                  <ImgPreview imgurl={postImgUrl} />
                  <ImgInput type="file" onChange={uploadImg} />
                </Wrap>
                <Wrap>
                  <LabelPost>이미지_ON</LabelPost>
                  <ImgPreview imgurl={postImgUrlOn} />
                  <ImgInput type="file" onChange={uploadImg2} />
                </Wrap>
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
