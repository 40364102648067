import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";
import TextEditor from "../../Components/TextEditor";

// const ReactS3Uploader = require("react-s3-uploader");

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation newMonthly($id: Int!) {
    newMonthly(id: $id) {
      id
    }
  }
`;

const GET_ALL = gql`
  {
    items: adminMonthly {
      id
      completed
      published
    }
  }
`;

const GET_ONE = gql`
  query oneMonthly($id: Int!) {
    post: oneMonthly(id: $id) {
      id
      published
      completed
      imgUrl
      headerDescription
      schedule
      location
      playersGuide
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateMonthly(
    $id: Int!
    $published: Boolean!
    $completed: Boolean!
    $imgUrl: String!
    $headerDescription: String!
    $schedule: String!
    $location: String!
    $playersGuide: String!
  ) {
    updateMonthly(
      id: $id
      published: $published
      completed: $completed
      imgUrl: $imgUrl
      headerDescription: $headerDescription
      schedule: $schedule
      location: $location
      playersGuide: $playersGuide
    ) {
      id
      published
      completed
      imgUrl
      headerDescription
      schedule
      location
      playersGuide
    }
  }
`;

const ADMIN_LOCATIONS = gql`
  query adminLocations {
    locations: adminLocations {
      id
      title
      imgUrl
      imgUrlOn
      location
      address
      tel
    }
  }
`;

const today = new Date();

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState(0);
  const [postPublished, setPostPublished] = React.useState(false);
  const [postCompleted, setPostCompleted] = React.useState(false);
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postHeaderDescription, setPostHeaderDescription] = React.useState("");
  const [postSchedule, setPostSchedule] = React.useState([]);
  const [postLocation, setPostLocation] = React.useState([]);
  const [postPlayersGuide, setPostPlayersGuide] = React.useState([]);
  const [postSearch, setPostSearch] = React.useState("");
  const [postSearch2, setPostSearch2] = React.useState("");
  const [newTime, setNewTime] = React.useState({
    time: 1500,
    name: "",
    buyin: 0,
    starting: 0,
  });
  const [newScheduleDate, setNewScheduleDate] = React.useState(20191231);

  const [inputState, setInputState] = React.useState({ head: "", body: "" });
  const [uploading, setUploading] = React.useState(false);
  const [postState, setPostState] = React.useState([]);
  const [delState, setDelState] = React.useState(-1);

  const onProgress = () => {};
  const onFinish = () => {};
  const onError = () => {};

  const { data, loading } = useQuery(GET_ALL);

  const me = useQuery(ME);

  const postQuery = useQuery(GET_ONE, {
    variables: {
      id: parseInt(postid),
    },
    fetchPolicy: "network-only",
  });

  // console.log(postQuery);

  const [createDraftMutation] = useMutation(CREATE_DRAFT, {
    refetchQueries: [{ query: GET_ALL }],
  });

  const [updatePostMutation] = useMutation(UPDATE_ONE, {
    variables: {
      id: postid,
      published: postPublished,
      completed: postCompleted,
      imgUrl: postImgUrl,
      headerDescription: postHeaderDescription,
      schedule: JSON.stringify(postSchedule),
      location: JSON.stringify(postLocation),
      playersGuide: JSON.stringify(postState),
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const locationQuery = useQuery(ADMIN_LOCATIONS);

  const toPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const uploadFile = (e) => {
    // AXIOS
    // const formData = new FormData();
    const file = e.target.files[0];
    const contentType = file.type;
    // console.log(file);
    // const name = file.name;
    // const [, type] = name.split(".");
    // const splited = name.split(".");

    // const generatePutUrl = "http://localhost:4000/s3put";
    const generatePutUrl = "https://backend.ksop.co.kr/s3put";
    const options = {
      params: {
        Key: "files/"+today.getFullYear().toString()+"/"+today.getMonth()+"/" + file.name,
        ContentType: contentType,
      },
      headers: {
        "Content-Type": contentType,
      },
    };

    axios.get(generatePutUrl, options).then((res) => {
      const {
        data: { putURL },
      } = res;
      console.log(putURL);
      axios
        .put(putURL, file, {})
        .then((res) => {
          // console.log(res);
          setInputState({
            body: "https://cdnksop.s3.ap-northeast-2.amazonaws.com/files/"+today.getFullYear().toString()+"/"+today.getMonth()+"/" + file.name,
            head: inputState.head,
          })
          
          setUploading(false);
        })
        .catch((err) => {
          // this.setState({ message: "Sorry, something went wrong" });
          console.error("err", err);
        });
    });
  };

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const splited = name.split(".");

    formData.append(
      "file",
      e.target.files[0],
      "img." + splited[splited.length - 1]
    );

    try {
      axios
        .post(
          // "https://pokertoday-backend.xero0001.now.sh/upload",
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  // const handleImgUrl = e => {
  //   setPostImgUrl(e.target.value);
  // };

  const resetState = () => {
    setPostid(0);
    setPostPublished(false);
    setPostCompleted(false);
    setPostImgUrl("");
    setPostHeaderDescription("");
    setPostSchedule([]);
    setPostLocation([]);
    setPostState([]);

    setNewScheduleDate(20191231);
  };

  const nextMonth = (item) => {
    if (item % 100 === 12) {
      return (Math.floor(item / 100) + 1) * 100 + 1;
    } else {
      return item + 1;
    }
  };

  React.useEffect(() => {
    if (postQuery.loading === false) {
      if (postQuery.data.post && postQuery.data.post !== null) {
        setPostHeaderDescription(postQuery.data.post.headerDescription);
        setPostImgUrl(postQuery.data.post.imgUrl);
        setPostCompleted(postQuery.data.post.completed);
        setPostPublished(postQuery.data.post.published);
        setPostSchedule(JSON.parse(postQuery.data.post.schedule));
        setPostLocation(JSON.parse(postQuery.data.post.location));
        setPostState(JSON.parse(postQuery.data.post.playersGuide));

        var newSDate = postQuery.data.post.id;
        newSDate = newSDate * 100 + 1;

        setNewScheduleDate(newSDate);
      }
    }
  }, [postQuery.loading, postQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>월별 메인이벤트</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          {!loading && data && data.items && data.items.length > 0 && (
            <Button
              onClick={() =>
                createDraftMutation({
                  variables: { id: nextMonth(data.items[0].id) },
                })
              }
              style={{ width: "150px" }}
            >
              {"+ " +
                nextMonth(data.items[0].id).toString().substr(0, 4) +
                "년 " +
                nextMonth(data.items[0].id).toString().substr(4, 2) +
                "월 추가"}
            </Button>
          )}
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="2">날짜</Label>
              <Label flex="1">종료</Label>
              <Label flex="1">공개</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.items &&
              data.items.map((item) => {
                return (
                  <Item
                    key={item.id}
                    pointer={true}
                    onClick={() => toPost(item.id)}
                    published={item.published}
                  >
                    <Label flex="2">
                      {Math.floor(item.id / 100) +
                        "년 " +
                        pad(item.id % 100, 2) +
                        "월"}
                    </Label>
                    <Label flex="1">{item.completed ? "O" : "X"}</Label>
                    <Label flex="1">{item.published ? "O" : "X"}</Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>월별 메인이벤트 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>
                  ID:{" "}
                  {Math.floor(postQuery.data.post.id / 100) +
                    "년 " +
                    pad(postQuery.data.post.id % 100, 2) +
                    "월"}
                </LabelPost>
                <Wrap>
                  <LabelPost>공개여부</LabelPost>
                  <Select
                    name="published"
                    value={postPublished}
                    onChange={handlePublished}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <Wrap>
                  <LabelPost>종료여부</LabelPost>
                  <Select
                    name="completed"
                    value={postCompleted}
                    onChange={(e) => {
                      if (e.target.value === "false") {
                        setPostCompleted(false);
                      } else {
                        setPostCompleted(true);
                      }
                    }}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <Wrap>
                  <LabelPost>이미지</LabelPost>
                  <ImgPreview imgurl={postImgUrl} />
                  <ImgInput type="file" onChange={uploadImg} />
                </Wrap>
                <LabelPost>상단 안내 설명</LabelPost>
                <textarea
                  style={{ width: "500px", height: "200px" }}
                  value={postHeaderDescription}
                  onChange={(e) => setPostHeaderDescription(e.target.value)}
                />
                <hr />
                <LabelPost style={{ marginTop: "16px" }}>
                  *수정완료 후 상단에서 적용하기를 눌러주세요
                </LabelPost>
                <Wrap style={{ marginTop: "16px" }}>
                  <LabelPost style={{ marginBottom: "0px" }}>
                    추가 경기내용 날짜
                  </LabelPost>
                  <InputPost
                    value={newScheduleDate}
                    onChange={(e) => {
                      setNewScheduleDate(e.target.value);
                    }}
                    long={false}
                    type="number"
                    style={{ marginBottom: "0px" }}
                  />
                </Wrap>
                <SButton
                  style={{ marginBottom: "48px" }}
                  onClick={() => {
                    if (newScheduleDate > 20191231) {
                      var newPostSchedule = [...postSchedule];

                      if (newPostSchedule.length === 0) {
                        newPostSchedule.push({
                          yearmonthday: parseInt(newScheduleDate),
                          schedule: [],
                        });
                      } else {
                        for (var i = 0; i < newPostSchedule.length; i++) {
                          if (
                            parseInt(newPostSchedule[i].yearmonthday) >
                            parseInt(newScheduleDate)
                          ) {
                            newPostSchedule.splice(i, 0, {
                              yearmonthday: parseInt(newScheduleDate),
                              schedule: [],
                            });
                            break;
                          } else if (
                            parseInt(newPostSchedule[i].yearmonthday) ===
                            parseInt(newScheduleDate)
                          ) {
                            break;
                          } else if (i === newPostSchedule.length - 1) {
                            newPostSchedule.push({
                              yearmonthday: parseInt(newScheduleDate),
                              schedule: [],
                            });
                          }
                        }
                      }

                      setPostSchedule(newPostSchedule);
                    }
                  }}
                >
                  {"+ 새 일정 추가"}
                </SButton>
                {postSchedule.map((schedule, index) => (
                  <Card key={"card" + schedule.yearmonthday}>
                    <Wrap>
                      <LabelPost>날짜</LabelPost>
                      <LabelPost>{schedule.yearmonthday}</LabelPost>
                      <SButton
                        style={{ marginTop: "0px" }}
                        onClick={() => {
                          var newPostSchedule = [...postSchedule];
                          newPostSchedule.splice(index, 1);

                          setPostSchedule(newPostSchedule);
                        }}
                      >
                        {"X 이 일정 삭제"}
                      </SButton>
                    </Wrap>
                    <LabelPost>시간별 스케쥴</LabelPost>
                    <Rowh>
                      <Colh>Time</Colh>
                      <Colh>Tournament Name</Colh>
                      <Colh>Buy-in</Colh>
                      <Colh>Starting Chips</Colh>
                      <Colh>추가</Colh>
                    </Rowh>
                    <Row style={{ marginBottom: "32px" }}>
                      <Col>
                        <input
                          value={newTime.time}
                          type="number"
                          onChange={(e) =>
                            setNewTime({ ...newTime, time: e.target.value })
                          }
                        />
                      </Col>
                      <Col>
                        <input
                          value={newTime.name}
                          type="text"
                          onChange={(e) =>
                            setNewTime({ ...newTime, name: e.target.value })
                          }
                        />
                      </Col>
                      <Col>
                        <input
                          value={newTime.buyin}
                          type="number"
                          onChange={(e) =>
                            setNewTime({ ...newTime, buyin: e.target.value })
                          }
                        />
                      </Col>
                      <Col>
                        <input
                          value={newTime.starting}
                          type="number"
                          onChange={(e) =>
                            setNewTime({ ...newTime, starting: e.target.value })
                          }
                        />
                      </Col>
                      <Col>
                        <span
                          onClick={() => {
                            var newTimeSchedule = postSchedule[index].schedule;

                            if (newTimeSchedule.length === 0) {
                              newTimeSchedule.push({
                                ...newTime,
                                time: parseInt(newTime.time),
                              });
                            } else {
                              for (var i = 0; i < newTimeSchedule.length; i++) {
                                if (newTime.time < newTimeSchedule[i].time) {
                                  newTimeSchedule.splice(i, 0, {
                                    ...newTime,
                                    time: parseInt(newTime.time),
                                  });
                                  break;
                                } else if (i === newTimeSchedule.length - 1) {
                                  newTimeSchedule.push({
                                    ...newTime,
                                    time: parseInt(newTime.time),
                                  });
                                  break;
                                }
                              }
                            }

                            var newSchedule = postSchedule[index];
                            newSchedule = {
                              ...newSchedule,
                              schedule: newTimeSchedule,
                            };

                            var newPostSchedule = [...postSchedule];
                            newPostSchedule.splice(index, 1, newSchedule);

                            setPostSchedule(newPostSchedule);
                          }}
                          style={{ fontSize: "16px", cursor: "pointer" }}
                        >
                          +
                        </span>
                      </Col>
                    </Row>
                    <Rowh>
                      <Colh>Time</Colh>
                      <Colh>Tournament Name</Colh>
                      <Colh>Buy-in</Colh>
                      <Colh>Starting Chips</Colh>
                      <Colh>삭제</Colh>
                    </Rowh>
                    {schedule.schedule.map((time, index2) => (
                      <Row key={schedule.yearmonthday + index2}>
                        <Col>{pad(time.time, 4)}</Col>
                        <Col>{time.name}</Col>
                        <Col>{time.buyin}</Col>
                        <Col>{time.starting}</Col>
                        <Col>
                          <span
                            onClick={() => {
                              var newTimeSchedule =
                                postSchedule[index].schedule;
                              newTimeSchedule.splice(index2, 1);

                              var newSchedule = postSchedule[index];
                              newSchedule = {
                                ...newSchedule,
                                schedule: newTimeSchedule,
                              };

                              var newPostSchedule = [...postSchedule];
                              newPostSchedule.splice(index, 1, newSchedule);

                              setPostSchedule(newPostSchedule);
                            }}
                            style={{ cursor: "pointer", color: "red" }}
                          >
                            X
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </Card>
                ))}
                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost style={{ marginTop: "32px" }}>
                  Players Guide
                </LabelPost>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid rgb(228,228,228)",
                    borderTop: "4px solid black",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    이름
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    링크
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "4px solid black",
                    borderTop: "1px solid rgb(128,128,128)",
                    marginBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.head}
                      onChange={(e) =>
                        setInputState({
                          head: e.target.value,
                          body: inputState.body,
                        })
                      }
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "3",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <InputPost
                      value={inputState.body}
                      onChange={(e) =>
                        setInputState({
                          body: e.target.value,
                          head: inputState.head,
                        })
                      }
                    />
                    <Wrap>
                      <ImgInput
                        disabled={uploading}
                        type="file"
                        accept="*"
                        onChange={(e) => {
                          setUploading(true);
                          uploadFile(e);
                        }}
                      />

                      {
                        // uploading && <div>!! 업로드중...</div>
                      }
                    </Wrap>
                  </div>
                </div>
                {postState.length > 0 && (
                  <div
                    style={{
                      borderTop: "4px solid black",
                      borderBottom: "4px solid black",
                    }}
                  >
                    {postState.map((row, index) => (
                      <div key={index}>
                        <div>
                          <button
                            onClick={() => {
                              var newArray = [...postState];
                              newArray.splice(index, 0, {
                                head: inputState.head,
                                body: inputState.body,
                              });

                              console.log(newArray);

                              setPostState(newArray);
                            }}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            + 여기에 추가하기
                          </button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid rgb(128,128,128)",
                            borderBottom: "1px solid rgb(128,128,128)",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              backgroundColor: "rgb(240,240,240)",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.head}
                          </div>
                          <div
                            style={{
                              flex: "2",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {row.body.split("\n").map((line, index) => (
                              <p key={index} style={{ marginBottom: "8px" }}>
                                {line}
                              </p>
                            ))}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <span
                              style={
                                delState === index
                                  ? { display: "none" }
                                  : { display: "block", cursor: "pointer" }
                              }
                              onClick={() => setDelState(index)}
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setDelState(-1);

                                var newArray = postState;
                                newArray.splice(index, 1);
                                setPostState(newArray);
                              }}
                              style={
                                delState === index
                                  ? { display: "block", cursor: "pointer" }
                                  : { display: "none" }
                              }
                            >
                              삭제
                            </span>
                            <span
                              onClick={() => {
                                setDelState(-1);
                              }}
                              style={
                                delState === index
                                  ? {
                                      display: "block",
                                      marginLeft: "16px",
                                      cursor: "pointer",
                                    }
                                  : { display: "none" }
                              }
                            >
                              취소
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  onClick={() =>
                    setPostState([
                      ...postState,
                      {
                        head: inputState.head,
                        body: inputState.body,
                      },
                    ])
                  }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  + 추가하기
                </button>

                <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                <LabelPost>*대회장소관리에서 장소 추가 후 사용</LabelPost>
                <LabelPost style={{ marginTop: "32px" }}>
                  현재 대회 장소
                </LabelPost>
                {postLocation.map((loc, index) => (
                  <Card key={loc.id}>
                    <LabelPost>매장이름: {loc.title}</LabelPost>
                    <LabelPost>ID: {loc.id}</LabelPost>
                    <button
                      onClick={() => {
                        var newPostLocation = [...postLocation];
                        newPostLocation.splice(index, 1);

                        setPostLocation(newPostLocation);
                      }}
                    >
                      - 이 장소 제거
                    </button>
                  </Card>
                ))}
                <LabelPost style={{ marginTop: "32px" }}>
                  대회장소 목록
                </LabelPost>
                {!locationQuery.loading &&
                  locationQuery.data &&
                  locationQuery.data.locations &&
                  locationQuery.data.locations.map((loc) => (
                    <Card key={loc.id}>
                      <LabelPost>매장이름: {loc.title}</LabelPost>
                      <LabelPost>ID: {loc.id}</LabelPost>
                      <button
                        onClick={() => {
                          setPostLocation([
                            ...postLocation,
                            {
                              id: loc.id,
                              title: loc.title,
                              imgUrl: loc.imgUrl,
                              imgUrlOn: loc.imgUrlOn,
                              location: loc.location,
                              address: loc.address,
                              tel: loc.tel,
                            },
                          ]);
                        }}
                      >
                        + 이 장소 추가
                      </button>
                    </Card>
                  ))}
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Card = styled.div`
  width: 100%;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
`;

const Rowh = styled.div`
  border-top: 4px solid black;
  border-bottom: 4px solid black;
  display: flex;
  flex-direction: row;
`;

const Colh = styled.div`
  font-size: 16px;
  flex: 1;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weigth: 600;
`;

const Row = styled.div`
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  flex: 1;
  font-size: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
`;

const SButton = styled.button`
  display: block;
  height: 50px;
  width: 200px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
