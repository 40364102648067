import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const TICKET_META = gql`
  {
    ticketMeta {
      id
      title
      published
    }
  }
`;

const UPDATE_TICKET = gql`
  mutation updateOneTicket($id: ID!, $count: Int!) {
    updateOneTicket(id: $id, count: $count) {
      id
      count
      title
      category
    }
  }
`;

const NEW_TICKET = gql`
  mutation createTicket(
    $playerId: ID!
    $title: String!
    $count: Int!
    $category: Int!
  ) {
    createTicket(
      playerId: $playerId
      title: $title
      count: $count
      category: $category
    ) {
      id
    }
  }
`;

const DELETE_TICKET = gql`
  mutation deleteTicket($ticketId: ID!) {
    deleteTicket(ticketId: $ticketId) {
      id
    }
  }
`;

const TICKETS = gql`
  query tickets($playerId: ID!, $category: Int!) {
    tickets(playerId: $playerId, category: $category) {
      id
      title
      count
      createdAt
    }
  }
`;

const NEW_PLAYER = gql`
  mutation createPlayerDraft {
    createPlayerDraft {
      id
    }
  }
`;

const GET_ADMIN_PLAYERS = gql`
  query getAdminPlayers($first: Int!, $skip: Int!, $name: String!) {
    players: getAdminPlayers(first: $first, skip: $skip, name: $name) {
      id
      createdAt
      name
      score
      published
      rank
      team
      tel
    }
  }
`;

const GET_ONE_PLAYER = gql`
  query getOnePlayer($id: ID!) {
    post: getOnePlayer(id: $id) {
      id
      createdAt

      name
      score
      published
      rank
      imgUrl
      team
      memo
      tel
    }

    records: getPlayerRecords(id: $id) {
      id
      score
      tournament {
        id
        idx
        title
      }
    }

    tournament: getNewestTournament {
      id
      idx
      title
    }
  }
`;

const GET_NEWEST_TOURNAMENT = gql`
  {
    tournament: getNewestTournament {
      id
      idx
      title
    }
  }
`;

const GET_WINNERS = gql`
  query getWinnerByPlayer($id: ID!) {
    winners: getWinnerByPlayer(id: $id) {
      id
      title
      linked
      eventName
      record
      tournament {
        id
        idx
        title
      }
    }
  }
`;

const NEW_WINNER = gql`
  mutation createWinnerDraft(
    $id: ID!
    $title: String!
    $eventName: String!
    $record: String!
    $linked: Boolean!
    $idx: Int!
  ) {
    createWinnerDraft(
      id: $id
      title: $title
      eventName: $eventName
      record: $record
      linked: $linked
      idx: $idx
    ) {
      id
      title
      eventName
      record
      linked
    }
  }
`;

const NEW_RECORD = gql`
  mutation createRecord($playerid: ID!, $tournamentidx: Int!, $score: Float!) {
    createRecord(
      playerid: $playerid
      tournamentidx: $tournamentidx
      score: $score
    ) {
      id
    }
  }
`;

const DELETE_RECORD = gql`
  mutation deleteRecord($id: ID!) {
    deleteRecord(id: $id) {
      id
    }
  }
`;

const DELETE_PLAYER = gql`
  mutation deletePlayer($id: ID!) {
    deletePlayer(id: $id) {
      id
    }
  }
`;

const DELETE_WINNER = gql`
  mutation deleteWinner($id: Int!) {
    deleteWinner(id: $id) {
      id
    }
  }
`;

const UPDATE_PLAYER = gql`
  mutation updatePlayer(
    $id: ID!
    $name: String!
    $published: Boolean!
    $imgUrl: String
    $team: String
    $memo: String!
    $tel: String!
  ) {
    updatePlayer(
      id: $id
      name: $name
      published: $published
      imgUrl: $imgUrl
      team: $team
      memo: $memo
      tel: $tel
    ) {
      id
      name
      published
      imgUrl
      team
      memo
      tel
    }
  }
`;

const UPDATE_WINNER = gql`
  mutation updateWinner(
    $id: Int!
    $title: String!
    $eventName: String!
    $record: String!
    $linked: Boolean!
    $idx: Int!
  ) {
    updateWinner(
      id: $id
      title: $title
      eventName: $eventName
      record: $record
      linked: $linked
      idx: $idx
    ) {
      id
      title
      eventName
      record
      linked
    }
  }
`;

export default () => {
  const ct = 50;
  const [subPage, setSubPage] = React.useState(0);

  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("");
  const [ticketState, setTicketState] = React.useState({
    category: 1,
    title: "",
    count: 0,
    ticketId: "",
  });

  const [ticketModState, setTicketModState] = React.useState({
    id: "",
    count: 0,
  });

  const [postName, setPostName] = React.useState("");
  const [postTeam, setPostTeam] = React.useState("");
  const [postMemo, setPostMemo] = React.useState("");
  const [postTel, setPostTel] = React.useState("");

  const [WTitle, setWTitle] = React.useState("");
  const [WEventName, setWEventName] = React.useState("");
  const [WRecord, setWRecord] = React.useState("");
  const [WLinked, setWLinked] = React.useState(false);
  const [WIdx, setWIdx] = React.useState(0);

  const [postScore, setPostScore] = React.useState(0.0);
  const [postRecordScore, setPostRecordScore] = React.useState(0.001);
  const [postIdx, setPostIdx] = React.useState(1);

  const [postPublished, setPostPublished] = React.useState(false);
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postRank, setPostRank] = React.useState(0);

  const [postDeletion, setPostDeletion] = React.useState("");
  const [WDeletion, setWDeletion] = React.useState("");
  const [WUpdate, setWUpdate] = React.useState(0);
  const [WState, setWState] = React.useState({
    title: "",
    eventName: "",
    record: "",
    linked: false,
    idx: 0,
  });

  const [state, setState] = React.useState({ name: "", ct: ct });
  const [searchName, setSearchName] = React.useState("");

  const meta = useQuery(TICKET_META);

  const { data, loading } = useQuery(GET_ADMIN_PLAYERS, {
    variables: { skip: subPage, first: state.ct, name: state.name },
    fetchPolicy: "network-only",
  });

  const tickets = useQuery(TICKETS, {
    variables: { playerId: postid, category: ticketState.category },
  });

  const newestTournament = useQuery(GET_NEWEST_TOURNAMENT);

  const me = useQuery(ME);

  const postQuery = useQuery(GET_ONE_PLAYER, {
    variables: {
      id: postid,
    },
  });

  const winnersQuery = useQuery(GET_WINNERS, {
    variables: {
      id: postid,
    },
  });

  const [createPlayerMutation] = useMutation(NEW_PLAYER, {
    refetchQueries: [
      {
        query: GET_ADMIN_PLAYERS,
        variables: { skip: subPage, first: state.ct, name: "" },
      },
    ],
  });

  const [createTicket] = useMutation(NEW_TICKET, {
    variables: {
      playerId: postid,
      title: ticketState.title,
      count: ticketState.count,
      category: ticketState.category,
    },
    refetchQueries: [
      {
        query: TICKETS,
        variables: { playerId: postid, category: ticketState.category },
      },
    ],
  });

  const [deleteTicket] = useMutation(DELETE_TICKET, {
    variables: {
      ticketId: ticketState.ticketId,
    },
    refetchQueries: [
      {
        query: TICKETS,
        variables: { playerId: postid, category: ticketState.category },
      },
    ],
  });

  const [updateTicket] = useMutation(UPDATE_TICKET, {
    variables: {
      id: ticketModState.id,
      count: ticketModState.count,
    },
    refetchQueries: [
      {
        query: TICKETS,
        variables: { playerId: postid, category: ticketState.category },
      },
    ],
  });

  const [createWinnerMutation] = useMutation(NEW_WINNER, {
    variables: {
      id: postid,
      title: WTitle,
      eventName: WEventName,
      record: WRecord,
      linked: WLinked,
      idx: WIdx,
    },
    refetchQueries: [{ query: GET_WINNERS, variables: { id: postid } }],
  });

  const [createRecordMutation] = useMutation(NEW_RECORD, {
    variables: {
      playerid: postid,
      tournamentidx: postIdx,
      score: postRecordScore,
    },
    refetchQueries: [
      {
        query: GET_ONE_PLAYER,
        variables: {
          id: postid,
        },
      },
    ],
  });

  const [deleteRecordMutation] = useMutation(DELETE_RECORD, {
    variables: {
      id: postDeletion,
    },
    refetchQueries: [
      {
        query: GET_ONE_PLAYER,
        variables: {
          id: postid,
        },
      },
    ],
  });

  const [deleteWinnerMutation] = useMutation(DELETE_WINNER, {
    variables: {
      id: WDeletion,
    },
    refetchQueries: [{ query: GET_WINNERS, variables: { id: postid } }],
  });

  const [updateWinnerMutation] = useMutation(UPDATE_WINNER, {
    refetchQueries: [{ query: GET_WINNERS, variables: { id: postid } }],
  });

  const [deletePostMutation] = useMutation(DELETE_PLAYER, {
    variables: {
      id: postid,
    },
    refetchQueries: [
      {
        query: GET_ADMIN_PLAYERS,
        variables: { skip: subPage, first: ct, name: "" },
      },
    ],
  });

  const [updatePostMutation] = useMutation(UPDATE_PLAYER, {
    variables: {
      id: postid,
      name: postName,
      published: postPublished,
      team: postTeam,
      imgUrl: postImgUrl,
      memo: postMemo,
      tel: postTel,
    },
    refetchQueries: [
      {
        query: GET_ADMIN_PLAYERS,
        variables: { skip: subPage, first: ct, name: "" },
      },
    ],
  });

  const playerPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createPlayerMutation();
  };

  const draftRecord = () => {
    createRecordMutation();
  };

  const draftWinner = () => {
    createWinnerMutation();
  };

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const splited = name.split(".");

    formData.append(
      "file",
      e.target.files[0],
      "img." + splited[splited.length - 1]
    );

    try {
      axios
        .post(
          // "https://pokertoday-backend.xero0001.now.sh/upload",
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const deletePost = () => {
    setPage("overview");
    resetState();
    deletePostMutation();
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const deleteRecord = () => {
    deleteRecordMutation();
  };

  const handleName = (e) => {
    setPostName(e.target.value);
  };

  const setDeletion = (props) => {
    setPostDeletion(props);
  };

  const handleTeam = (e) => {
    setPostTeam(e.target.value);
  };

  const handleMemo = (e) => {
    setPostMemo(e.target.value);
  };

  const handleScore = (e) => {
    setPostScore(e.target.value);
  };

  const handleRecordScore = (e) => {
    if (
      e.target.value !== NaN &&
      e.target.value !== "" &&
      e.target.value !== "NaN" &&
      e.target.value !== undefined
    ) {
      setPostRecordScore(parseFloat(e.target.value));
    } else {
      setPostRecordScore(0.001);
    }
  };

  const handleIdx = (e) => {
    if (
      e.target.value !== NaN &&
      e.target.value !== "" &&
      e.target.value !== "NaN" &&
      e.target.value !== undefined
    ) {
      setPostIdx(parseInt(e.target.value));
    } else {
      setPostIdx(1);
    }
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  const numberFormat = (inputNumber) => {
    var parts = inputNumber.toString().split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "")
    );
  };

  const resetState = () => {
    setPostid("");
    setPostName("");
    setPostPublished(false);
    setPostTeam("");
    setPostImgUrl("");
    setPostRank(0);
    setPostScore(0.0);
    setPostRecordScore(0.001);
    setPostIdx(1);

    setPostMemo("");
    setPostTel("");

    setWTitle("");
    setWEventName("");
    setWRecord("");
    setWLinked(false);
    setWIdx(0);
  };

  React.useEffect(() => {
    if (postQuery.loading === false) {
      if (postQuery.data.post) {
        if (postName !== postQuery.data.post.name) {
          setPostName(postQuery.data.post.name);
          setPostImgUrl(postQuery.data.post.imgUrl);
          setPostPublished(postQuery.data.post.published);
          setPostRank(postQuery.data.post.rank);
          setPostTeam(postQuery.data.post.team);
          setPostScore(postQuery.data.post.score);
          setPostIdx(postQuery.data.tournament[0].idx);
          setPostMemo(postQuery.data.post.memo);
          setPostTel(postQuery.data.post.tel);
        }
      }
    }
  }, [postQuery.loading, postQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>선수</Title>
          <div>
            {!me.loading &&
              me.data &&
              me.data.me &&
              me.data.me.class &&
              me.data.me.name && (
                <div>
                  사용자: {me.data.me.class} {me.data.me.name}
                </div>
              )}
          </div>
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>

          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 32,
              marginBottom: 16,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span style={{ lineHeight: "32px" }}>이름검색: </span>
            <InputPost
              style={{ height: "32px" }}
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  if (searchName === "") {
                    setState({ name: "", ct: ct });
                  } else {
                    setState({ name: searchName, ct: 0 });
                    setSearchName("");
                  }
                }
              }}
            />
            <button
              style={{ width: 64, height: "32px" }}
              onClick={() => {
                if (searchName === "") {
                  setState({ name: "", ct: ct });
                } else {
                  setState({ name: searchName, ct: 0 });
                  setSearchName("");
                }
              }}
            >
              검색
            </button>
          </div>
          {state.name === "" && (
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 32 }}
            >
              {subPage >= ct && (
                <button
                  style={{ width: 64 }}
                  onClick={() => setSubPage(subPage - 50)}
                >
                  앞으로
                </button>
              )}
              <Label style={{ marginLeft: 16, marginRight: 16 }}>
                {subPage + 1 + " ~ " + (subPage + 50)}
              </Label>
              <button
                style={{ width: 64 }}
                onClick={() => setSubPage(subPage + 50)}
              >
                뒤로
              </button>
            </div>
          )}
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="1">랭킹</Label>
              <Label flex="1">이름</Label>
              <Label flex="2">팀</Label>
              <Label flex="2">점수</Label>
              <Label flex="2">TEL</Label>
              <Label flex="1">공개</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.players &&
              data.players.map((player) => {
                return (
                  <Item
                    key={player.id}
                    pointer={true}
                    onClick={() => playerPost(player.id)}
                    published={player.published}
                  >
                    <Label flex="1">
                      {player.rank === 0 ? "신규선수" : "#" + player.rank}
                    </Label>
                    <Label flex="1">{player.name}</Label>
                    <Label flex="2">{player.team}</Label>
                    <Label flex="2">
                      {numberFormat(player.score.toFixed(3))} pts
                    </Label>
                    <Label flex="2">{player.tel}</Label>
                    <Label flex="1">{player.published ? "O" : "X"}</Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>선수 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
            <Button onClick={() => deletePost()}>{"X 삭제하기"}</Button>
          </Wrap>
          <Wrap>
            <Button onClick={() => setPage("ticket")}>{"티켓"}</Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>ID: {postQuery.data.post.id}</LabelPost>
                <LabelPost>이름</LabelPost>
                <InputPost value={postName} onChange={handleName} />
                <LabelPost>팀</LabelPost>
                <InputPost value={postTeam} onChange={handleTeam} />
                <Wrap>
                  <LabelPost>공개여부</LabelPost>
                  <Select
                    name="published"
                    value={postPublished}
                    onChange={handlePublished}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <LabelPost>
                  생성일: {postQuery.data.post.createdAt.substr(0, 10)}
                </LabelPost>
                <LabelPost>TEL</LabelPost>
                <InputPost
                  value={postTel}
                  onChange={(e) => setPostTel(e.target.value)}
                />
                <LabelPost>메모</LabelPost>
                <InputPost value={postMemo} onChange={handleMemo} long={true} />

                <Wrap>
                  <LabelPost>프로필 이미지</LabelPost>
                  <ImgPreview imgurl={postImgUrl} />
                  <ImgInput type="file" onChange={uploadImg} />
                </Wrap>
                <hr />
                <LabelPost>
                  랭킹: {postRank === 0 ? "신규 선수" : "#" + postRank}
                </LabelPost>
                <LabelPost>
                  점수: {numberFormat(postScore.toFixed(3))} pts
                </LabelPost>
                <hr />
                <ContainerWrap>
                  {postQuery.data.records.map((record) => (
                    <Container key={record.id}>
                      <LabelPost>대회인덱스: {record.tournament.idx}</LabelPost>
                      <LabelPost>대회이름: {record.tournament.title}</LabelPost>
                      <LabelPost>
                        획득포인트: {numberFormat(record.score.toFixed(3))} pts
                      </LabelPost>
                      <DeleteButton>
                        <DeleteLabel onClick={() => setDeletion(record.id)}>
                          삭제
                        </DeleteLabel>
                        <DeleteWrap
                          show={record.id === postDeletion ? true : false}
                        >
                          <DeleteConfirm onClick={() => setDeletion("0")}>
                            취소
                          </DeleteConfirm>
                          <DeleteConfirm onClick={() => deleteRecord()}>
                            확인
                          </DeleteConfirm>
                        </DeleteWrap>
                      </DeleteButton>
                    </Container>
                  ))}
                </ContainerWrap>
              </>
            )}
          </WrapperPost>
        </>
      )}
      {page === "ticket" && (
        <>
          <Title>선수티켓</Title>
          <Button onClick={() => setPage("post")}>{"< 뒤로"}</Button>
          <WrapperPost>
            {!meta.loading && meta.data && meta.data.ticketMeta && (
              <>
                <LabelPost>이름: {postName}</LabelPost>
                <LabelPost>팀: {postTeam}</LabelPost>
                <LabelPost>
                  랭킹: {postRank === 0 ? "신규" : "#" + postRank}
                </LabelPost>
                <Wrap>
                  <LabelPost>티켓종류</LabelPost>
                  <Select
                    name="category"
                    value={ticketState.category}
                    onChange={(e) =>
                      setTicketState({
                        ...ticketState,
                        category: parseInt(e.target.value),
                      })
                    }
                  >
                    {meta.data.ticketMeta.map((meta) => (
                      <option key={meta.id} value={meta.id}>
                        {meta.title}
                      </option>
                    ))}
                  </Select>
                </Wrap>
                <hr />
                <LabelPost>티켓이름</LabelPost>
                <InputPost
                  value={ticketState.title}
                  onChange={(e) =>
                    setTicketState({ ...ticketState, title: e.target.value })
                  }
                />
                <LabelPost>티켓수량</LabelPost>
                <InputPost
                  value={ticketState.count}
                  onChange={(e) =>
                    setTicketState({
                      ...ticketState,
                      count: parseInt(e.target.value),
                    })
                  }
                  type="number"
                />
                <Button
                  onClick={() => {
                    createTicket();
                  }}
                >
                  {"+ 티켓발급"}
                </Button>
                <hr />
                {!tickets.loading &&
                  tickets.data &&
                  tickets.data.tickets &&
                  tickets.data.tickets.map((ticket) => {
                    const date = new Date(ticket.createdAt);

                    return (
                      <div
                        key={ticket.id}
                        style={{
                          backgroundColor: "rgba(0,0,0,0.04)",
                          padding: "16px",
                          marginTop: "24px",
                        }}
                      >
                        <LabelPost>티켓이름: {ticket.title}</LabelPost>
                        <LabelPost>
                          티켓발급일:{" "}
                          {date.getFullYear() +
                            "년 " +
                            (date.getMonth() + 1) +
                            "월 " +
                            date.getDate() +
                            "일 " +
                            pad(date.getHours(), 2) +
                            ":" +
                            pad(date.getMinutes(), 2) +
                            ":" +
                            pad(date.getSeconds(), 2)}
                        </LabelPost>
                        {ticketModState.id === ticket.id ? (
                          <Wrap>
                            <LabelPost>티켓수량</LabelPost>
                            <InputPost
                              value={ticketModState.count}
                              onChange={(e) => {
                                setTicketModState({
                                  id: ticketModState.id,
                                  count: parseInt(e.target.value),
                                });
                              }}
                              type="number"
                            />
                          </Wrap>
                        ) : (
                          <LabelPost>티켓수량:{ticket.count}</LabelPost>
                        )}
                        {ticketState.ticketId !== ticket.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                color: "red",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setTicketState({
                                  ...ticketState,
                                  ticketId: ticket.id,
                                })
                              }
                            >
                              티켓삭제
                            </div>
                          </div>
                        )}
                        {ticketState.ticketId === ticket.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                color: "red",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setTicketState({ ...ticketState, ticketId: "" })
                              }
                            >
                              취소
                            </div>
                            <div
                              style={{
                                color: "red",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                deleteTicket();
                              }}
                            >
                              확인
                            </div>
                          </div>
                        )}
                        {ticketModState.id !== ticket.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              marginTop: "16px",
                            }}
                          >
                            <div
                              style={{
                                color: "blue",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setTicketModState({
                                  id: ticket.id,
                                  count: ticket.count,
                                })
                              }
                            >
                              티켓수정
                            </div>
                          </div>
                        )}
                        {ticketModState.id === ticket.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              marginTop: "16px",
                            }}
                          >
                            <div
                              style={{
                                color: "blue",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setTicketModState({
                                  id: "",
                                  count: 0,
                                });
                              }}
                            >
                              취소
                            </div>
                            <div
                              style={{
                                color: "blue",
                                marginLeft: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                updateTicket();
                                setTicketModState({
                                  id: "",
                                  count: 0,
                                });
                              }}
                            >
                              확인
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 200px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(p) => p.theme.verylightgreyColor};
  padding: 16px;
  padding-bottom: 1px;
  margin-top: 16px;
  display: block;

  & .default {
    display: block;
  }

  &.show .default {
    display: none;
  }

  & .update {
    display: none;
  }

  &.show .update {
    display: block;
  }
`;

const ContainerWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
`;

const ContainerWrap2 = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled.div``;

const UpdateLabel = styled.div`
  color: black;
  cursor: pointer;
  display: block;
  text-align: right;
  margin-bottom: 16px;
`;

const DeleteLabel = styled.div`
  color: red;
  cursor: pointer;
  float: right;
  margin-bottom: 16px;
`;

const DeleteConfirm = styled.div`
  color: red;
  cursor: pointer;
  margin-left: 16px;
`;

const DeleteWrap = styled.div`
  width: 100%;
  margin-top: 16px;
  justify-content: flex-end;

  ${(p) => (p.show ? "display:flex;" : "display:none;")};
  margin-bottom: 16px;
`;
