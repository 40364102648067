import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
import * as xlsx from "xlsx";
// const xlsx = require("xlsx"); // import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const GET_APPLICATION = gql`
  mutation getApplication($year: Int!, $month: Int!) {
    getApplication(year: $year, month: $month) {
      id
      uid
      createdAt
      serial
      name
      phone
      address
    }
  }
`;

const META = gql`
  query meta($slug: String!) {
    post: meta(slug: $slug) {
      id
      slug
      content
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateMeta($slug: String!, $content: String!) {
    updateMeta(slug: $slug, content: $content) {
      id
      slug
      content
    }
  }
`;

// const UPDATE_ONE = gql`
//   mutation updateOneAbout(
//     $id: Int!
//     $r1: String
//     $r2: String
//     $r3: String
//     $r4: String
//     $r5: String
//     $r6: String
//     $r7: String
//     $r8: String
//     $r9: String
//   ) {
//     updateOneAbout(
//       where: { id: $id }
//       data: {
//         r1: $r1
//         r2: $r2
//         r3: $r3
//         r4: $r4
//         r5: $r5
//         r6: $r6
//         r7: $r7
//         r8: $r8
//         r9: $r9
//       }
//     ) {
//       id
//       r1
//       r2
//       r3
//       r4
//       r5
//       r6
//       r7
//       r8
//       r9
//     }
//   }
// `;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [eLoading, setELoading] = React.useState(false);
  const [postid, setPostid] = React.useState("0");
  const [uploading, setUploading] = React.useState(false);
  const [postState, setPostState] = React.useState({
    appheader: "",
  });

  const [excelSelectorState, setExcelSelectorState] = React.useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  console.log(excelSelectorState);

  const me = useQuery(ME);

  const { data, loading } = useQuery(META, {
    variables: { slug: "APPHEADER" },
    fetchPolicy: "network-only",
  });

  console.log(postState);

  const [updatePostMutation] = useMutation(UPDATE_ONE);
  const [getApplication] = useMutation(GET_APPLICATION);

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  React.useEffect(() => {
    if (loading === false) {
      if (data.post && data.post !== null) {
        const content = JSON.parse(data.post.content);
        setPostState({
          appheader: {
            title: content.title,
            ranks: content.ranks,
          },
        });
      }
    }
  }, [loading, data]);

  function buildExcel(appData) {
    const ws_name = "WPL 신청서";

    /* make worksheet */
    let ws_data = [
      ["id", "신청일", "uid", "serial", "이름", "연락처", "주소", "OS", "종류"],
    ];

    appData.forEach((dataRow) => {
      ws_data.push([
        dataRow.id,
        dataRow.createdAt,
        dataRow.uid,
        dataRow.serial,
        dataRow.name,
        dataRow.phone,
        dataRow.address,
        dataRow.os,
        dataRow.type,
      ]);
    });

    var ws = xlsx.utils.aoa_to_sheet(ws_data);
    const workbook = xlsx.utils.book_new();

    /* Add the worksheet to the workbook */
    xlsx.utils.book_append_sheet(workbook, ws, ws_name);

    xlsx.writeFile(
      workbook,
      `WPL신청서 ${excelSelectorState.year}-${excelSelectorState.month}.xlsx`
    );
  }

  return (
    <>
      {page === "overview" && (
        <>
          <Title>KSOP ONLINE</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button
            onClick={() => {
              updatePostMutation({
                variables: {
                  slug: "APPHEADER",
                  content: JSON.stringify({
                    title: postState.appheader.title,
                    ranks: postState.appheader.ranks,
                  }),
                },
                refetchQueries: [
                  { query: META, variables: { slug: "APPHEADER" } },
                ],
              });
            }}
          >
            {"V 적용하기"}
          </Button>
          <WrapperPost>
            {!loading && data && data.post && (
              <>
                <Wrap>
                  <LabelPost>WPL 신청제목</LabelPost>
                  <InputPost
                    value={postState.appheader.title}
                    long={true}
                    onChange={(e) => {
                      const newPostState = {
                        ...postState,
                        appheader: {
                          ...postState.appheader,
                          title: e.target.value,
                        },
                      };
                      setPostState({
                        ...newPostState,
                      });
                    }}
                  />
                </Wrap>
                <LabelPost>WPL 신청서 종류</LabelPost>
                {postState.appheader &&
                  postState.appheader.ranks &&
                  postState.appheader.ranks.map((rank, index) => (
                    <Wrap>
                      <InputPost
                        key={index}
                        value={rank}
                        onChange={(e) => {
                          let newPostStateRanks = [
                            ...postState.appheader.ranks,
                          ];
                          newPostStateRanks[index] = e.target.value;

                          const newPostState = {
                            ...postState,
                            appheader: {
                              ...postState.appheader,
                              ranks: [...newPostStateRanks],
                            },
                          };

                          setPostState({
                            ...newPostState,
                          });
                        }}
                      />
                    </Wrap>
                  ))}
                <Wrap>
                  <Button
                    onClick={() => {
                      const newPostStateRanks = [
                        ...postState.appheader.ranks,
                      ].slice(0, -1);

                      const newPostState = {
                        ...postState,
                        appheader: {
                          ...postState.appheader,
                          ranks: [...newPostStateRanks],
                        },
                      };

                      setPostState({
                        ...newPostState,
                      });
                    }}
                  >
                    -
                  </Button>
                  <Button
                    onClick={() => {
                      let newPostStateRanks = [
                        ...postState.appheader.ranks,
                        "",
                      ];

                      const newPostState = {
                        ...postState,
                        appheader: {
                          ...postState.appheader,
                          ranks: [...newPostStateRanks],
                        },
                      };

                      setPostState({
                        ...newPostState,
                      });
                    }}
                  >
                    +
                  </Button>
                </Wrap>
                {/* <InputPost
                    value={postState.appheader}
                    long={true}
                    onChange={(e) => {
                      setPostState({
                        ...postState,
                        appheader: e.target.value,
                      });
                    }}
                  /> */}
              </>
            )}
            <hr />
            <LabelPost>
              *다운받을 WPL 신청서 년월 입력후 다운로드 버튼 클릭
            </LabelPost>
            <Wrap>
              <LabelPost>년</LabelPost>
              <InputPost
                value={excelSelectorState.year}
                long={false}
                onChange={(e) => {
                  setExcelSelectorState({
                    ...excelSelectorState,
                    year: parseInt(e.target.value),
                  });
                }}
                type="number"
              />
            </Wrap>
            <Wrap>
              <LabelPost>월</LabelPost>
              <InputPost
                value={excelSelectorState.month}
                long={false}
                onChange={(e) => {
                  setExcelSelectorState({
                    ...excelSelectorState,
                    month: parseInt(e.target.value),
                  });
                }}
                type="number"
              />
            </Wrap>
            <Button
              disabled={eLoading}
              onClick={async () => {
                setELoading(true);
                const { data } = await getApplication({
                  variables: {
                    year: excelSelectorState.year,
                    month: excelSelectorState.month,
                  },
                });

                const appData = data.getApplication;

                buildExcel(appData);

                setELoading(false);
              }}
            >
              {"WPL 신청서 다운로드"}
            </Button>
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url("${(p) => p.imgurl}");
  background-size: cover;
  background-position: center;
`;
