const BOX_BORDER = "1px solid rgba(0,0,0,0.55)";
const BORDER_RADIUS = "4px";

export default {
  bgColor: "rgb(255,255,255)",
  lightColor: "rgba(255,255,255,0.98)",
  lesslightColor: "rgba(255,255,255,0.7)",
  deepdarkColor: "rgba(0,0,0,0.9)",
  darkerColor: "rgba(0,0,0,0.84)",
  blackColor: "rgba(0, 0, 0, 0.8)",
  greyColor: "rgba(0,0,0,0.55)",
  lightgreyColor: "rgba(0,0,0,0.15)",
  verylightgreyColor: "rgba(0,0,0,0.05)",
  blueColor: "rgba(0,100,200,0.9)",
  redColor: "rgba(200,50,50,0.9)",
  pokerredColor: "rgb(249, 25, 52)",
  pokerblueColor: "rgb(80, 180, 255)",
  pokergreenColor: "rgb(18, 120, 116)",
  boxBorder: "1px solid rgba(0,0,0,0.55)",
  borderRadius: "4px",
  whiteBox: `${BOX_BORDER};
             ${BORDER_RADIUS};
             background-color:rgb(255,255,255);
            `,
  wideShadow: "rgba(0,0,0,0.05) 0px 4px 12px 0px",
  hPadding: "padding-left:24px;padding-right:24px;",
  vPadding: "padding-top:40px;padding-bottom:40px;",
  largemaxWidth: "max-width: 1080px;",
  Flex: "display:flex;",
  sansFont: "font-family: 'Noto Sans','Noto Sans KR', 'Roboto', sans-serif;",
  serifFont: "font-family: 'Noto Serif', 'Noto Serif KR', serif;",
  lightShadow:
    "box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.10);"
};
