import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const NEW_TOURNAMENT = gql`
  mutation createTournament {
    createTournament {
      id
    }
  }
`;

const GET_TOURNAMENTS = gql`
  {
    tournaments: getTournaments {
      id
      idx
      title
      startDate
      endDate
      published
      winnerPublished
      location
      locationDetail
    }
  }
`;

const GET_ONE_TOURNAMENT = gql`
  query getOneTournament($id: ID!) {
    post: getOneTournament(id: $id) {
      id
      idx
      title
      startDate
      endDate
      published
      winnerPublished
      winnerImgUrl
      location
      locationDetail
      schedule
      yearmonth {
        id
      }
    }

    records: getTournamentRecords(id: $id) {
      id
      score
      prize
      place
      entries
      eventName
      buyin
      player {
        name
        rank
        score
        tel
      }
    }
  }
`;

const PLAYER = gql`
  query adminPlayerbyName($name: String!) {
    players: adminPlayerByName(name: $name) {
      id
      rank
      name
      tel
      score
      prize
    }
  }
`;

const UPDATE_RANK = gql`
  mutation updateRank($idx: Int!) {
    updateRank(idx: $idx)
  }
`;

const UPDATE_RECORD = gql`
  mutation updateRecord(
    $id: ID!
    $eventName: String!
    $place: Int!
    $entries: Int!
    $prize: Int!
    $buyin: Int!
    $score: Float!
  ) {
    updateRecord(
      id: $id
      eventName: $eventName
      place: $place
      prize: $prize
      entries: $entries
      buyin: $buyin
      score: $score
    ) {
      id
    }
  }
`;

const NEW_RECORD = gql`
  mutation newRecord(
    $playerid: ID!
    $tournamentid: ID!
    $eventName: String!
    $place: Int!
    $entries: Int!
    $prize: Int!
    $buyin: Int!
    $score: Float!
  ) {
    newRecord(
      playerid: $playerid
      tournamentid: $tournamentid
      eventName: $eventName
      place: $place
      prize: $prize
      entries: $entries
      buyin: $buyin
      score: $score
    ) {
      id
    }
  }
`;

const DELETE_RECORD = gql`
  mutation deleteRecord($id: ID!) {
    deleteRecord(id: $id) {
      id
    }
  }
`;

const UPDATE_TOURNAMENT = gql`
  mutation updateTournament(
    $id: ID!
    $title: String!
    $startDate: String!
    $endDate: String!
    $published: Boolean!
    $winnerPublished: Boolean!
    $winnerImgUrl: String!
    $location: String!
    $locationDetail: String!
    $schedule: String!
    $yearmonth: Int
  ) {
    updateTournament(
      id: $id
      title: $title
      startDate: $startDate
      endDate: $endDate
      published: $published
      winnerPublished: $winnerPublished
      winnerImgUrl: $winnerImgUrl
      location: $location
      locationDetail: $locationDetail
      schedule: $schedule
      yearmonth: $yearmonth
    ) {
      id
    }
  }
`;

const UPDATE_MAIN_EVENT_SCHEDULE = gql`
  mutation updateMainEventSchedule($schedule: String!) {
    updateMainEventSchedule(schedule: $schedule) {
      id
      schedule
    }
  }
`;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState(0);
  const [postIdx, setPostIdx] = React.useState(0);
  const [postTitle, setPostTitle] = React.useState("");
  const [postStartDate, setPostStartDate] = React.useState("");
  const [postEndDate, setPostEndDate] = React.useState("");
  const [postPublished, setPostPublished] = React.useState(false);
  const [postWinnerPublished, setPostWinnerPublished] = React.useState("");
  const [postWinnerImgUrl, setPostWinnerImgUrl] = React.useState("");
  const [postLocation, setPostLocation] = React.useState("");
  const [postLocationDetail, setPostLocationDetail] = React.useState("");
  const [postSchedule, setPostSchedule] = React.useState([]);
  const dt = new Date();
  const [postYearMonth, setPostYearMonth] = React.useState(
    dt.getFullYear() * 100 + dt.getMonth() + 1
  );

  const [modId, setModId] = React.useState("");
  const [delId, setDelId] = React.useState("");
  const [newId, setNewId] = React.useState("");

  const [search, setSearch] = React.useState("");
  const [playerName, setPlayerName] = React.useState("");

  const [newScheduleDate, setNewScheduleDate] = React.useState(20191231);
  const [newTime, setNewTime] = React.useState({
    time: 1500,
    no: 1,
    name: "",
    buyin: 0,
  });

  const [recordState, setRecordState] = React.useState({
    score: 0.0,
    prize: 0,
    eventName: "",
    place: 0,
    entries: 0,
    buyin: 0,
  });

  const { data, loading } = useQuery(GET_TOURNAMENTS);

  const playerQuery = useQuery(PLAYER, {
    variables: {
      name: playerName,
    },
  });

  const me = useQuery(ME);

  const tournamentPostQuery = useQuery(GET_ONE_TOURNAMENT, {
    variables: {
      id: postid,
    },
    fetchPolicy: "network-only",
  });

  const [createTournamentMutation] = useMutation(NEW_TOURNAMENT, {
    refetchQueries: [{ query: GET_TOURNAMENTS }],
  });

  const [updatePostMutation] = useMutation(UPDATE_TOURNAMENT, {
    variables: {
      id: postid,
      title: postTitle,
      startDate: postStartDate,
      endDate: postEndDate,
      published: postPublished,
      winnerPublished: postWinnerPublished,
      winnerImgUrl: postWinnerImgUrl,
      location: postLocation,
      locationDetail: postLocationDetail,
      schedule: JSON.stringify(postSchedule),
      yearmonth: parseInt(postYearMonth),
    },
    refetchQueries: [{ query: GET_TOURNAMENTS }],
  });

  const [updateRankMutation] = useMutation(UPDATE_RANK, {
    variables: {
      idx: postIdx,
    },
    refetchQueries: [
      {
        query: GET_ONE_TOURNAMENT,
        variables: {
          id: postid,
        },
      },
    ],
  });

  const [updateRecordMutation] = useMutation(UPDATE_RECORD, {
    variables: {
      id: modId,
      eventName: recordState.eventName,
      place: parseInt(recordState.place),
      entries: parseInt(recordState.entries),
      buyin: parseInt(recordState.buyin),
      prize: parseInt(recordState.prize),
      score: parseFloat(parseFloat(recordState.score).toFixed(3)),
    },
    refetchQueries: [
      {
        query: GET_ONE_TOURNAMENT,
        variables: {
          id: postid,
        },
      },
    ],
  });

  const [newRecordMutation] = useMutation(NEW_RECORD, {
    variables: {
      playerid: newId,
      tournamentid: postid,
      eventName: recordState.eventName,
      place: parseInt(recordState.place),
      entries: parseInt(recordState.entries),
      buyin: parseInt(recordState.buyin),
      prize: parseInt(recordState.prize),
      score: parseFloat(parseFloat(recordState.score).toFixed(3)),
    },
    refetchQueries: [
      {
        query: GET_ONE_TOURNAMENT,
        variables: {
          id: postid,
        },
      },
    ],
  });

  const [deleteRecordMutation] = useMutation(DELETE_RECORD, {
    variables: {
      id: delId,
    },
    refetchQueries: [
      {
        query: GET_ONE_TOURNAMENT,
        variables: {
          id: postid,
        },
      },
    ],
  });

  const tournamentPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createTournamentMutation();
  };

  // const uploadImg = async e => {
  //   // REACT-S3
  //   // const config = {
  //   //   bucketName: "cdn.pokertoday.co.kr",
  //   //   dirName: "images" /* optional */,
  //   //   region: "ap-northeast-2",
  //   //   accessKeyId: "",
  //   //   secretAccessKey: ""
  //   // };

  //   // S3FileUpload.uploadFile(e.target.files[0], config)
  //   //   .then(data => console.log(data))
  //   //   .catch(err => console.error(err));

  //   // AXIOS
  //   const formData = new FormData();

  //   const name = e.target.files[0].name;
  //   const [, type] = name.split(".");

  //   // console.log(name);

  //   formData.append("file", e.target.files[0], "thumbnail." + type);

  //   try {
  //     //   // setIsLoading(true);
  //     axios
  //       .post(
  //         "https://pokertoday-backend.xero0001.now.sh/upload",
  //         // "https://express-now.xero0001.now.sh/upload",
  //         // "http://localhost:4000/upload",
  //         formData,
  //         {
  //           headers: {
  //             "content-type": "multipart/form-data"
  //           }
  //         }
  //       )
  //       .then(response => {
  //         setPostImgUrl(response.data.location);
  //       });
  //   } catch (err) {
  //     alert(err);
  //   } finally {
  //     //   // setIsLoading(false);
  //   }
  // };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const numberFormat = (inputNumber) => {
    return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const updateRankF = () => {
    // console.log(postIdx);
    updateRankMutation();
    setPage("overview");
    resetState();
  };

  const handleTitle = (e) => {
    setPostTitle(e.target.value);
  };

  const handleStartDate = (e) => {
    setPostStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setPostEndDate(e.target.value);
  };

  const resetState = () => {
    setPostid(0);
    setPostTitle("");
    setPostStartDate("");
    setPostEndDate("");
    setPostIdx(0);
    setPostPublished(false);
    setPostWinnerPublished(false);
    setPostWinnerImgUrl("");
    setPostLocation("");
    setPostLocationDetail("");
    setPostSchedule([]);
    setPostYearMonth(dt.getFullYear() * 100 + dt.getMonth() + 1);
  };

  React.useEffect(() => {
    if (tournamentPostQuery.loading === false) {
      if (tournamentPostQuery.data.post) {
        if (postTitle !== tournamentPostQuery.data.post.title) {
          setPostTitle(tournamentPostQuery.data.post.title);
          setPostIdx(tournamentPostQuery.data.post.idx);
          setPostStartDate(tournamentPostQuery.data.post.startDate);
          setPostEndDate(tournamentPostQuery.data.post.endDate);
          setPostPublished(tournamentPostQuery.data.post.published);
          setPostWinnerPublished(tournamentPostQuery.data.post.winnerPublished);
          setPostWinnerImgUrl(tournamentPostQuery.data.post.winnerImgUrl);
          setPostLocation(tournamentPostQuery.data.post.location);
          setPostLocationDetail(tournamentPostQuery.data.post.locationDetail);
          setPostSchedule(JSON.parse(tournamentPostQuery.data.post.schedule));
          if (
            tournamentPostQuery.data.post.yearmonth &&
            tournamentPostQuery.data.post.yearmonth.id
          ) {
            setPostYearMonth(tournamentPostQuery.data.post.yearmonth.id);
          }

          var newSDate = parseInt(
            tournamentPostQuery.data.post.startDate.substr(0, 4)
          );
          newSDate =
            newSDate * 100 +
            parseInt(tournamentPostQuery.data.post.startDate.substr(5, 2));
          newSDate =
            newSDate * 100 +
            parseInt(tournamentPostQuery.data.post.startDate.substr(8, 2));
          setNewScheduleDate(newSDate);
        }
      }
    }
  }, [tournamentPostQuery.loading, tournamentPostQuery.data]);

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const splited = name.split(".");

    formData.append(
      "file",
      e.target.files[0],
      "img." + splited[splited.length - 1]
    );

    try {
      axios
        .post(
          // "https://pokertoday-backend.xero0001.now.sh/upload",
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostWinnerImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  return (
    <>
      {page === "overview" && (
        <>
          <Title>개별 메인이벤트</Title>
          <div>
            {!me.loading &&
              me.data &&
              me.data.me &&
              me.data.me.class &&
              me.data.me.name && (
                <div>
                  사용자: {me.data.me.class} {me.data.me.name}
                </div>
              )}
          </div>
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="1">인덱스</Label>
              <Label flex="2">대회이름</Label>
              <Label flex="2">시작일자</Label>
              <Label flex="2">종료일자</Label>
              <Label flex="1">공개</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.tournaments &&
              data.tournaments.map((tournament) => {
                return (
                  <Item
                    key={tournament.id}
                    pointer={true}
                    onClick={() => tournamentPost(tournament.id)}
                    published={tournament.published}
                  >
                    <Label flex="1">{tournament.idx}</Label>
                    <Label flex="2">{tournament.title}</Label>
                    <Label flex="2">{tournament.startDate.substr(0, 10)}</Label>
                    <Label flex="2">{tournament.endDate.substr(0, 10)}</Label>
                    <Label flex="1">{tournament.published ? "O" : "X"}</Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>메인이벤트 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!tournamentPostQuery.loading &&
              tournamentPostQuery.data &&
              tournamentPostQuery.data.post && (
                <>
                  <LabelPost>ID: {tournamentPostQuery.data.post.id}</LabelPost>
                  <LabelPost>
                    인덱스: {tournamentPostQuery.data.post.idx}
                  </LabelPost>
                  <LabelPost>
                    이 대회가 속하는 월별메인이벤트(!중요 ex)201912)
                  </LabelPost>
                  <InputPost
                    value={postYearMonth}
                    onChange={(e) => setPostYearMonth(e.target.value)}
                    long={false}
                    type="number"
                  />
                  <LabelPost>대회이름</LabelPost>
                  <InputPost
                    value={postTitle}
                    onChange={handleTitle}
                    long={true}
                  />
                  <LabelPost>대회시작일자</LabelPost>
                  <LabelPost>
                    <InputPost
                      value={postStartDate.substr(0, 10)}
                      onChange={handleStartDate}
                      long={true}
                    />
                  </LabelPost>
                  <LabelPost>대회종료일자</LabelPost>
                  <LabelPost>
                    <InputPost
                      value={postEndDate.substr(0, 10)}
                      onChange={handleEndDate}
                      long={true}
                    />
                  </LabelPost>
                  <LabelPost>지역</LabelPost>
                  <InputPost
                    value={postLocation}
                    onChange={(e) => setPostLocation(e.target.value)}
                    long={false}
                  />
                  <LabelPost>장소-상세</LabelPost>
                  <InputPost
                    value={postLocationDetail}
                    onChange={(e) => setPostLocationDetail(e.target.value)}
                    long={true}
                  />
                  <Wrap>
                    <LabelPost>공개여부</LabelPost>
                    <Select
                      name="published"
                      value={postPublished}
                      onChange={(e) => {
                        if (e.target.value === "false") {
                          setPostPublished(false);
                        } else if (e.target.value === "true") {
                          setPostPublished(true);
                        }
                      }}
                    >
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>우승자 공개여부</LabelPost>
                    <Select
                      name="winnerPublished"
                      value={postWinnerPublished}
                      onChange={(e) => {
                        if (e.target.value === "false") {
                          setPostWinnerPublished(false);
                        } else if (e.target.value === "true") {
                          setPostWinnerPublished(true);
                        }
                      }}
                    >
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>우승 이미지</LabelPost>
                    <ImgPreview imgurl={postWinnerImgUrl} />
                    <ImgInput type="file" onChange={uploadImg} />
                  </Wrap>
                  <hr />
                  <LabelPost style={{ marginTop: "16px" }}>
                    *수정완료 후 상단에서 적용하기를 눌러주세요
                  </LabelPost>
                  <Wrap style={{ marginTop: "16px" }}>
                    <LabelPost style={{ marginBottom: "0px" }}>
                      추가일정 날짜
                    </LabelPost>
                    <InputPost
                      value={newScheduleDate}
                      onChange={(e) => {
                        setNewScheduleDate(e.target.value);
                      }}
                      long={false}
                      type="number"
                      style={{ marginBottom: "0px" }}
                    />
                  </Wrap>
                  <SButton
                    style={{ marginBottom: "48px" }}
                    onClick={() => {
                      if (newScheduleDate > 20191231) {
                        var newPostSchedule = [...postSchedule];

                        if (newPostSchedule.length === 0) {
                          newPostSchedule.push({
                            yearmonthday: parseInt(newScheduleDate),
                            schedule: [],
                          });
                        } else {
                          for (var i = 0; i < newPostSchedule.length; i++) {
                            if (
                              parseInt(newPostSchedule[i].yearmonthday) >
                              parseInt(newScheduleDate)
                            ) {
                              newPostSchedule.splice(i, 0, {
                                yearmonthday: parseInt(newScheduleDate),
                                schedule: [],
                              });
                              break;
                            } else if (
                              parseInt(newPostSchedule[i].yearmonthday) ===
                              parseInt(newScheduleDate)
                            ) {
                              break;
                            } else if (i === newPostSchedule.length - 1) {
                              newPostSchedule.push({
                                yearmonthday: parseInt(newScheduleDate),
                                schedule: [],
                              });
                            }
                          }
                        }

                        setPostSchedule(newPostSchedule);
                      }
                    }}
                  >
                    {"+ 새 일정 추가"}
                  </SButton>
                  {postSchedule.map((schedule, index) => (
                    <Card key={"card" + schedule.yearmonthday}>
                      <Wrap>
                        <LabelPost>날짜</LabelPost>
                        <LabelPost>{schedule.yearmonthday}</LabelPost>
                        <SButton
                          style={{ marginTop: "0px" }}
                          onClick={() => {
                            var newPostSchedule = [...postSchedule];
                            newPostSchedule.splice(index, 1);

                            setPostSchedule(newPostSchedule);
                          }}
                        >
                          {"X 이 일정 삭제"}
                        </SButton>
                      </Wrap>
                      <LabelPost>시간별 스케쥴</LabelPost>
                      <Rowh>
                        <Colh>Time</Colh>
                        <Colh>Event#</Colh>
                        <Colh>Tournament Name</Colh>
                        <Colh>Buy-in</Colh>
                        <Colh>추가</Colh>
                      </Rowh>
                      <Row style={{ marginBottom: "32px" }}>
                        <Col>
                          <input
                            value={newTime.time}
                            type="number"
                            onChange={(e) =>
                              setNewTime({ ...newTime, time: e.target.value })
                            }
                          />
                        </Col>
                        <Col>
                          <input
                            value={newTime.no}
                            type="text"
                            onChange={(e) =>
                              setNewTime({ ...newTime, no: e.target.value })
                            }
                          />
                        </Col>
                        <Col>
                          <input
                            value={newTime.name}
                            type="text"
                            onChange={(e) =>
                              setNewTime({ ...newTime, name: e.target.value })
                            }
                          />
                        </Col>
                        <Col
                          onClick={() => {
                            console.log(newTime);
                            console.log(postSchedule);
                          }}
                        >
                          <input
                            value={newTime.buyin}
                            type="number"
                            onChange={(e) =>
                              setNewTime({ ...newTime, buyin: e.target.value })
                            }
                          />
                        </Col>
                        <Col>
                          <span
                            onClick={() => {
                              var newTimeSchedule =
                                postSchedule[index].schedule;

                              if (newTimeSchedule.length === 0) {
                                newTimeSchedule.push({
                                  ...newTime,
                                  time: parseInt(newTime.time),
                                });
                              } else {
                                for (
                                  var i = 0;
                                  i < newTimeSchedule.length;
                                  i++
                                ) {
                                  if (newTime.time < newTimeSchedule[i].time) {
                                    newTimeSchedule.splice(i, 0, {
                                      ...newTime,
                                      time: parseInt(newTime.time),
                                    });
                                    break;
                                  } else if (i === newTimeSchedule.length - 1) {
                                    newTimeSchedule.push({
                                      ...newTime,
                                      time: parseInt(newTime.time),
                                    });
                                    break;
                                  }
                                }
                              }

                              var newSchedule = postSchedule[index];
                              newSchedule = {
                                ...newSchedule,
                                schedule: newTimeSchedule,
                              };

                              var newPostSchedule = [...postSchedule];
                              newPostSchedule.splice(index, 1, newSchedule);

                              setPostSchedule(newPostSchedule);
                            }}
                            style={{ fontSize: "16px", cursor: "pointer" }}
                          >
                            +
                          </span>
                        </Col>
                      </Row>
                      <Rowh>
                        <Colh>Time</Colh>
                        <Colh>Event#</Colh>
                        <Colh>Tournament Name</Colh>
                        <Colh>Buy-in</Colh>
                        <Colh>삭제</Colh>
                      </Rowh>
                      {schedule.schedule.map((time, index2) => (
                        <Row key={schedule.yearmonthday + index2}>
                          <Col>{pad(time.time, 4)}</Col>
                          <Col>{time.no}</Col>
                          <Col>{time.name}</Col>
                          <Col>{time.buyin}</Col>
                          <Col>
                            <span
                              onClick={() => {
                                var newTimeSchedule =
                                  postSchedule[index].schedule;
                                newTimeSchedule.splice(index2, 1);

                                var newSchedule = postSchedule[index];
                                newSchedule = {
                                  ...newSchedule,
                                  schedule: newTimeSchedule,
                                };

                                var newPostSchedule = [...postSchedule];
                                newPostSchedule.splice(index, 1, newSchedule);

                                setPostSchedule(newPostSchedule);
                              }}
                              style={{ cursor: "pointer", color: "red" }}
                            >
                              X
                            </span>
                          </Col>
                        </Row>
                      ))}
                    </Card>
                  ))}
                  <hr />
                  <SButton
                    style={{ marginLeft: "auto", color: "red" }}
                    onClick={() => updateRankF()}
                  >
                    {"!! 이 대회로 랭킹 적용"}
                  </SButton>
                  <LabelPost style={{ textAlign: "right", color: "red" }}>
                    * 랭킹 적용 이후에 계속 사용하시기 위해서는 5~10초 후에
                    새로고침을 해주세요. (그전에 뭔가를 한다고 문제가 발생하는건
                    아닙니다.)
                  </LabelPost>
                  <hr />
                  <LabelPost>기록 목록</LabelPost>
                  <ContainerWrap>
                    <Rowh>
                      <Colh flex={1}>현재순위</Colh>
                      <Colh flex={1}>이름</Colh>
                      <Colh flex={2}>TEL</Colh>
                      <Colh flex={2}>획득점수</Colh>
                      <Colh flex={2}>획득상금</Colh>
                      <Colh flex={2}>이벤트이름</Colh>
                      <Colh flex={2}>순위</Colh>
                      <Colh flex={2}>엔트리</Colh>
                      <Colh flex={2}>buy-in</Colh>
                      <Colh flex={1}>수정</Colh>
                      <Colh flex={1}>삭제</Colh>
                    </Rowh>
                    {tournamentPostQuery.data.records &&
                      tournamentPostQuery.data.records.map((record) => (
                        <div key={record.id}>
                          <Row>
                            <Col flex={1}>{record.player.rank}</Col>
                            <Col flex={1}>{record.player.name}</Col>
                            <Col flex={2}>{record.player.tel}</Col>
                            <Col flex={2}>
                              {numberFormat(record.score.toFixed(3))} pts
                            </Col>
                            <Col flex={2}>{record.prize}k</Col>
                            <Col flex={2}>{record.eventName}</Col>
                            <Col flex={2}>{record.place}</Col>
                            <Col flex={2}>{record.entries}</Col>
                            <Col flex={2}>{record.buyin}</Col>
                            <Col flex={1} style={{ color: "blue" }}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setRecordState({
                                    score: record.score,
                                    prize: record.prize,
                                    eventName: record.eventName,
                                    place: record.place,
                                    entries: record.entries,
                                    buyin: record.buyin,
                                  });
                                  setDelId("");
                                  setModId(record.id);
                                }}
                              >
                                수정
                              </span>
                            </Col>
                            <Col
                              flex={1}
                              style={{
                                color: "red",
                                textAlign: "right",
                              }}
                            >
                              <span
                                onClick={() => {
                                  setModId("");
                                  setDelId(record.id);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                X
                              </span>
                            </Col>
                          </Row>
                          <Row
                            style={
                              modId === record.id
                                ? {
                                    display: "flex",
                                    backgroundColor: "rgb(244,244,244)",
                                  }
                                : { display: "none" }
                            }
                          >
                            <Col flex={1}>
                              <button
                                onClick={() => {
                                  updateRecordMutation();
                                  setModId("");
                                }}
                              >
                                수정완료
                              </button>
                            </Col>
                            <Col flex={1}>
                              <button onClick={() => setModId("")}>취소</button>
                            </Col>
                            <Col flex={2}></Col>
                            <Col flex={2}>
                              <input
                                value={recordState.score}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    score: e.target.value,
                                  })
                                }
                                step="0.001"
                                type="number"
                              />
                              pts
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.prize}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    prize: e.target.value,
                                  })
                                }
                                type="number"
                                step="1"
                              />
                              k
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.eventName}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    eventName: e.target.value,
                                  })
                                }
                                type="text"
                              />
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.place}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    place: e.target.value,
                                  })
                                }
                                type="number"
                                step="1"
                              />
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.entries}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    entries: e.target.value,
                                  })
                                }
                                type="number"
                                step="1"
                              />
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.buyin}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    buyin: e.target.value,
                                  })
                                }
                                type="number"
                                step="1"
                              />
                            </Col>
                            <Col flex={1} style={{ color: "blue" }}></Col>
                            <Col
                              flex={1}
                              style={{
                                color: "red",
                                textAlign: "right",
                              }}
                            ></Col>
                          </Row>
                          <Row
                            style={
                              delId === record.id
                                ? {
                                    display: "flex",
                                    backgroundColor: "rgb(244,244,244)",
                                  }
                                : { display: "none" }
                            }
                          >
                            <Colh flex={1}></Colh>
                            <Colh flex={1}></Colh>
                            <Colh flex={2}></Colh>
                            <Colh flex={2}></Colh>
                            <Colh flex={2}></Colh>
                            <Colh flex={2}></Colh>
                            <Colh flex={2}></Colh>
                            <Colh flex={2}></Colh>
                            <Colh flex={2}></Colh>
                            <Colh flex={1}>
                              <button
                                style={{ color: "red" }}
                                onClick={() => deleteRecordMutation()}
                              >
                                확인
                              </button>
                            </Colh>
                            <Colh flex={1}>
                              <button
                                style={{ color: "red" }}
                                onClick={() => setDelId("")}
                              >
                                취소
                              </button>
                            </Colh>
                          </Row>
                        </div>
                      ))}
                  </ContainerWrap>
                  <hr style={{ marginTop: "32px", marginBottom: "32px" }} />
                  <ContainerWrap>
                    <LabelPost style={{ marginBottom: "16px" }}>
                      기록 추가
                    </LabelPost>
                    <LabelPost>선수 검색</LabelPost>
                    <Wrap>
                      <input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        style={{ padding: "8px", fontSize: "16px" }}
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            setPlayerName(search);
                          }
                        }}
                      />
                      <button
                        style={{ fontSize: "16px" }}
                        onClick={() => setPlayerName(search)}
                      >
                        검색
                      </button>
                    </Wrap>
                    <Rowh style={{ marginTop: "32px" }}>
                      <Colh flex={1}>순위</Colh>
                      <Colh flex={1}>이름</Colh>
                      <Colh flex={2}>TEL</Colh>
                      <Colh flex={2}>총점수</Colh>
                      <Colh flex={2}>총상금</Colh>
                      <Colh flex={1}>추가</Colh>
                    </Rowh>
                    {!playerQuery.loading &&
                      playerQuery.data &&
                      playerQuery.data.players &&
                      playerQuery.data.players.map((player) => (
                        <div key={player.id}>
                          <Row>
                            <Col flex={1}>{player.rank}</Col>
                            <Col flex={1}>{player.name}</Col>
                            <Col flex={2}>{player.tel}</Col>
                            <Col flex={2}>{numberFormat(player.score)} pts</Col>
                            <Col flex={2}>{player.prize + "k"}</Col>
                            <Col flex={1}>
                              <button onClick={() => setNewId(player.id)}>
                                추가하기
                              </button>
                            </Col>
                          </Row>
                          <Rowh
                            style={
                              newId === player.id
                                ? {
                                    display: "flex",
                                    backgroundColor: "rgb(244,244,244)",
                                  }
                                : { display: "none" }
                            }
                          >
                            <Colh flex={1}></Colh>
                            <Colh flex={1}></Colh>
                            <Colh flex={2}></Colh>
                            <Colh flex={2}>획득점수</Colh>
                            <Colh flex={2}>획득상금</Colh>
                            <Colh flex={2}>이벤트이름</Colh>
                            <Colh flex={2}>순위</Colh>
                            <Colh flex={2}>엔트리</Colh>
                            <Colh flex={2}>buy-in</Colh>
                            <Colh flex={1}></Colh>
                            <Colh flex={1}></Colh>
                          </Rowh>
                          <Row
                            style={
                              newId === player.id
                                ? {
                                    display: "flex",
                                    backgroundColor: "rgb(244,244,244)",
                                  }
                                : { display: "none" }
                            }
                          >
                            <Col flex={1}>
                              <button
                                onClick={() => {
                                  newRecordMutation();
                                  setNewId("");
                                }}
                              >
                                추가하기
                              </button>
                            </Col>
                            <Col flex={1}>
                              <button onClick={() => setNewId("")}>취소</button>
                            </Col>
                            <Col flex={2}></Col>
                            <Col flex={2}>
                              <input
                                value={recordState.score}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    score: e.target.value,
                                  })
                                }
                                step="0.001"
                                type="number"
                              />
                              pts
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.prize}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    prize: e.target.value,
                                  })
                                }
                                type="number"
                                step="1"
                              />
                              k
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.eventName}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    eventName: e.target.value,
                                  })
                                }
                                type="text"
                              />
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.place}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    place: e.target.value,
                                  })
                                }
                                type="number"
                                step="1"
                              />
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.entries}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    entries: e.target.value,
                                  })
                                }
                                type="number"
                                step="1"
                              />
                            </Col>
                            <Col flex={2}>
                              <input
                                value={recordState.buyin}
                                onChange={(e) =>
                                  setRecordState({
                                    ...recordState,
                                    buyin: e.target.value,
                                  })
                                }
                                type="number"
                                step="1"
                              />
                            </Col>
                            <Col flex={1} style={{ color: "blue" }}></Col>
                            <Col
                              flex={1}
                              style={{
                                color: "red",
                                textAlign: "right",
                              }}
                            ></Col>
                          </Row>
                        </div>
                      ))}
                  </ContainerWrap>
                </>
              )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Rowh = styled.div`
  border-top: 4px solid black;
  border-bottom: 4px solid black;
  display: flex;
  flex-direction: row;
`;

const Colh = styled.div`
  font-size: 16px;
  flex: 1;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weigth: 600;

  flex: ${(p) => p.flex};
`;

const Row = styled.div`
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  flex: 1;
  font-size: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
  flex: ${(p) => p.flex};
`;

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Card = styled.div`
  width: 100%;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const SButton = styled.button`
  display: block;
  height: 50px;
  width: 200px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 200px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;

const ContainerWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled.div``;

const DeleteLabel = styled.div`
  color: red;
  cursor: pointer;
  float: right;
  margin-bottom: 16px;
`;

const DeleteConfirm = styled.div`
  color: red;
  cursor: pointer;
  margin-left: 16px;
`;

const DeleteWrap = styled.div`
  width: 100%;
  margin-top: 16px;
  justify-content: flex-end;

  ${(p) => (p.show ? "display:flex;" : "display:none;")};
  margin-bottom: 16px;
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(p) => p.theme.verylightgreyColor};
  padding: 16px;
  padding-bottom: 1px;
  margin-top: 16px;
  display: block;
`;
