// import ApolloClient from "apollo-boost";
// import { defaults, resolvers } from "./LocalState";

// export default new ApolloClient({
//   // uri: "http://localhost:4000/graphql",
//   uri: "https://pokertoday-backend.xero0001.now.sh/graphql",
//   fetchOptions: {
//     mode: "no-cors"
//   },
//   // uri: process.env.BACKEND_URL,
//   request: async operation => {
//     // Get JWT token from local storage
//     const token = window.localStorage.getItem("token");

//     // Pass token to headers
//     operation.setContext({
//       headers: {
//         Authorization: token ? `Bearer ${token}` : ""
//       }
//     });
//   },
//   clientState: {
//     defaults,
//     resolvers
//   }
// });

import ApolloClient from "apollo-boost";
import { defaults, resolvers } from "./LocalState";

export default new ApolloClient({
  uri: "https://backend.ksop.co.kr/graphql",
  // uri: "http://localhost:4000/graphql",
  fetchOptions: {
    credentials: "include",
  },
  clientState: {
    defaults,
    resolvers,
  },
  headers: {
    Authorization: `Bearer ${localStorage.getItem("PTtoken")}`,
  },
});
