import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";
// import S3FileUpload from "react-s3";

import TextEditor from "../../Components/TextEditor";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation newBanner {
    newBanner {
      id
    }
  }
`;

const GET_ALL = gql`
  {
    items: adminBanner {
      id
      priority
      published
      category
      title
    }
  }
`;

const GET_ONE = gql`
  query oneBanner($id: ID!) {
    post: oneBanner(id: $id) {
      id
      priority
      published
      category
      title
      imgUrl
      link
    }
  }
`;

const UPDATE_ONE = gql`
  mutation updateBanner(
    $id: ID!
    $priority: Int!
    $published: Boolean!
    $category: String!
    $title: String!
    $imgUrl: String!
    $link: String!
  ) {
    updateBanner(
      id: $id
      priority: $priority
      published: $published
      category: $category
      title: $title
      imgUrl: $imgUrl
      link: $link
    ) {
      id
      priority
      published
      category
      title
      imgUrl
      link
    }
  }
`;

const DELETE_ONE = gql`
  mutation deleteBanner($id: ID!) {
    deleteBanner(id: $id) {
      id
    }
  }
`;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("");
  const [postPriority, setPostPriority] = React.useState(0);
  const [postTitle, setPostTitle] = React.useState("");
  const [postPublished, setPostPublished] = React.useState(false);
  const [postCategory, setPostCategory] = React.useState("이미지");
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postLinkUrl, setPostLinkUrl] = React.useState("");

  const { data, loading } = useQuery(GET_ALL);

  const me = useQuery(ME);

  const postQuery = useQuery(GET_ONE, {
    variables: {
      id: postid,
    },
    fetchPolicy: "network-only",
  });

  // console.log(postQuery);

  const [createDraftMutation] = useMutation(CREATE_DRAFT, {
    refetchQueries: [{ query: GET_ALL }],
  });

  const [deletePostMutation] = useMutation(DELETE_ONE, {
    variables: {
      id: postid,
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const [updatePostMutation] = useMutation(UPDATE_ONE, {
    variables: {
      id: postid,
      priority: postPriority,
      title: postTitle,
      category: postCategory,
      published: postPublished,
      imgUrl: postImgUrl,
      link: postLinkUrl,
    },
    refetchQueries: [{ query: GET_ALL }],
  });

  const toPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createDraftMutation();
  };

  const deletePost = () => {
    setPage("overview");
    resetState();
    deletePostMutation();
  };

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const splited = name.split(".");

    formData.append(
      "file",
      e.target.files[0],
      "img." + splited[splited.length - 1]
    );

    try {
      axios
        .post(
          // "https://pokertoday-backend.xero0001.now.sh/upload",
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  // const handleImgUrl = e => {
  //   setPostImgUrl(e.target.value);
  // };

  const resetState = () => {
    setPostid("");
    setPostPriority(0);
    setPostTitle("");
    setPostLinkUrl("");
    setPostImgUrl("");
    setPostCategory("이미지");
    setPostPublished(false);
  };

  React.useEffect(() => {
    if (postQuery.loading === false) {
      if (postQuery.data.post && postQuery.data.post !== null) {
        setPostPriority(postQuery.data.post.priority);
        setPostTitle(postQuery.data.post.title);
        setPostImgUrl(postQuery.data.post.imgUrl);
        setPostLinkUrl(postQuery.data.post.link);
        setPostCategory(postQuery.data.post.category);
        setPostPublished(postQuery.data.post.published);
      }
    }
  }, [postQuery.loading, postQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>배너</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          <Wrapper>
            <LabelPost>메인배너</LabelPost>
            <Item pointer={false} published={true}>
              <Label flex="1">우선순위</Label>
              <Label flex="1">이름</Label>
              <Label flex="1">종류</Label>
              <Label flex="1">공개</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.items &&
              data.items.map((item) => {
                if (item.category !== "상단배너") {
                  return (
                    <Item
                      key={item.id}
                      pointer={true}
                      onClick={() => toPost(item.id)}
                      published={item.published}
                    >
                      <Label flex="1">{item.priority}</Label>
                      <Label flex="1">{item.title}</Label>
                      <Label flex="1">{item.category}</Label>
                      <Label flex="1">{item.published ? "O" : "X"}</Label>
                    </Item>
                  );
                }
              })}
          </Wrapper>
          <Wrapper>
            <LabelPost>선수상세 상단배너 (*우선순위 의미없음)</LabelPost>
            <Item pointer={false} published={true}>
              <Label flex="1">우선순위</Label>
              <Label flex="1">이름</Label>
              <Label flex="1">종류</Label>
              <Label flex="1">공개</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.items &&
              data.items.map((item) => {
                if (item.category === "상단배너") {
                  return (
                    <Item
                      key={item.id}
                      pointer={true}
                      onClick={() => toPost(item.id)}
                      published={item.published}
                    >
                      <Label flex="1">{item.priority}</Label>
                      <Label flex="1">{item.title}</Label>
                      <Label flex="1">{item.category}</Label>
                      <Label flex="1">{item.published ? "O" : "X"}</Label>
                    </Item>
                  );
                }
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>배너 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
            <Button onClick={() => deletePost()}>{"X 삭제하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!postQuery.loading && postQuery.data && postQuery.data.post && (
              <>
                <LabelPost>ID: {postQuery.data.post.id}</LabelPost>
                <LabelPost>이름</LabelPost>
                <InputPost
                  value={postTitle}
                  onChange={(e) => setPostTitle(e.target.value)}
                  long={true}
                />
                <Wrap>
                  <LabelPost>우선순위</LabelPost>
                  <InputPost
                    value={postPriority}
                    onChange={(e) => setPostPriority(parseInt(e.target.value))}
                    long={false}
                    type="number"
                  />
                  {postCategory === "상단배너" && (
                    <LabelPost>(*우선순의 무의미)</LabelPost>
                  )}
                </Wrap>
                <Wrap>
                  <LabelPost>공개여부</LabelPost>
                  <Select
                    name="published"
                    value={postPublished}
                    onChange={handlePublished}
                  >
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </Select>
                </Wrap>
                <Wrap>
                  <LabelPost>종류</LabelPost>
                  <Select
                    name="category"
                    value={postCategory}
                    onChange={(e) => setPostCategory(e.target.value)}
                  >
                    <option value="이미지">메인이미지</option>
                    <option value="유튜브">메인유튜브</option>
                    <option value="상단배너">상단배너이미지</option>
                  </Select>
                </Wrap>
                {postCategory === "이미지" && <LabelPost>링크</LabelPost>}
                {postCategory === "상단배너" && <LabelPost>링크</LabelPost>}
                {postCategory === "유튜브" && (
                  <LabelPost>유튜브 비디오 ID</LabelPost>
                )}
                <InputPost
                  value={postLinkUrl}
                  onChange={(e) => setPostLinkUrl(e.target.value)}
                  long={true}
                />
                {postCategory !== "유튜브" && (
                  <Wrap>
                    <LabelPost>이미지</LabelPost>
                    <ImgPreview imgurl={postImgUrl} />
                    <ImgInput type="file" onChange={uploadImg} />
                  </Wrap>
                )}
              </>
            )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
