import React from "react";
import axios from "axios";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo-hooks";

const ME = gql`
  {
    me {
      name
      class
    }
  }
`;

const NEW_INTRODUCTION = gql`
  mutation createIntroductionDraft {
    createIntroductionDraft {
      id
    }
  }
`;

const GET_ADMIN_INTRODUCTIONS = gql`
  {
    introductions: getAdminIntroductions {
      id
      createdAt

      category
      title
      imgUrl

      published
      recommended
    }
  }
`;

const GET_ONE_INTRODUCTION = gql`
  query getOneIntroduction($id: ID!) {
    getOneIntroduction(id: $id) {
      id
      createdAt

      title
      imgUrl
      contentImgUrl
      category

      region
      address

      published
      recommended

      content
      openTime
      tel
      location
      link
    }
  }
`;

const DELETE_INTRODUCTION = gql`
  mutation deleteIntroduction($id: ID!) {
    deleteIntroduction(id: $id) {
      id
    }
  }
`;

const UPDATE_INTRODUCTION = gql`
  mutation updateIntroduction(
    $id: ID!
    $title: String!
    $published: Boolean!
    $recommended: Boolean!
    $category: String!
    $imgUrl: String!
    $contentImgUrl: String!
    $region: String!
    $address: String!
    $createdAt: String!
    $openTime: String!
    $tel: String!
    $location: String!
    $link: String!
    $content: String!
  ) {
    updateIntroduction(
      id: $id
      title: $title
      published: $published
      recommended: $recommended
      category: $category
      imgUrl: $imgUrl
      contentImgUrl: $contentImgUrl
      region: $region
      address: $address
      createdAt: $createdAt
      openTime: $openTime
      tel: $tel
      location: $location
      link: $link
      content: $content
    ) {
      id
      title
      published
      recommended
      category
      imgUrl
      contentImgUrl
      region
      address
      createdAt
      openTime
      tel
      location
      link
      content
    }
  }
`;

export default () => {
  const [page, setPage] = React.useState("overview");
  const [postid, setPostid] = React.useState("");
  const [postTitle, setPostTitle] = React.useState("");
  const [postPublished, setPostPublished] = React.useState(false);
  const [postRecommended, setPostRecommended] = React.useState(false);
  const [postCategory, setPostCategory] = React.useState("국내");
  const [postImgUrl, setPostImgUrl] = React.useState("");
  const [postContentImgUrl, setPostContentImgUrl] = React.useState("");
  const [postRegion, setPostRegion] = React.useState("서울");
  const [postAddress, setPostAddress] = React.useState("");

  const [postCreatedAtYMD, setPostCreatedAtYMD] = React.useState("");
  const [postCreatedAtHMS, setPostCreatedAtHMS] = React.useState("");

  const [postOpenTime, setPostOpenTime] = React.useState("");
  const [postTel, setPostTel] = React.useState("");

  const [postLocation, setPostLocation] = React.useState([0.000001, 0.000001]);
  const [postLink, setPostLink] = React.useState([]);
  const [postContent, setPostContent] = React.useState("");

  const { data, loading } = useQuery(GET_ADMIN_INTRODUCTIONS);

  const me = useQuery(ME);

  const introductionPostQuery = useQuery(GET_ONE_INTRODUCTION, {
    variables: {
      id: postid,
    },
  });

  const [createIntroductionDraftMutation] = useMutation(NEW_INTRODUCTION, {
    refetchQueries: [{ query: GET_ADMIN_INTRODUCTIONS }],
  });

  const [deletePostMutation] = useMutation(DELETE_INTRODUCTION, {
    variables: {
      id: postid,
    },
    refetchQueries: [{ query: GET_ADMIN_INTRODUCTIONS }],
  });

  const [updatePostMutation] = useMutation(UPDATE_INTRODUCTION, {
    variables: {
      id: postid,
      title: postTitle,
      published: postPublished,
      recommended: postRecommended,
      category: postCategory,
      imgUrl: postImgUrl,
      contentImgUrl: postContentImgUrl,
      region: postRegion,
      address: postAddress,
      createdAt: postCreatedAtYMD + "T" + postCreatedAtHMS + ".000Z",
      tel: postTel,
      openTime: postOpenTime,
      location: JSON.stringify(postLocation),
      link: JSON.stringify(postLink),
      content: postContent,
    },
    refetchQueries: [{ query: GET_ADMIN_INTRODUCTIONS }],
  });

  const newsPost = (id) => {
    setPostid(id);
    setPage("post");
  };

  const overviewPage = () => {
    setPage("overview");
    resetState();
  };

  const draftPage = () => {
    createIntroductionDraftMutation();
  };

  const uploadImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const uploadContentImg = async (e) => {
    // AXIOS
    const formData = new FormData();

    const name = e.target.files[0].name;
    const [, type] = name.split(".");

    // console.log(name);

    formData.append("file", e.target.files[0], "thumbnail." + type);

    try {
      //   // setIsLoading(true);
      axios
        .post(
          "https://backend.ksop.co.kr/uploadimg",
          // "https://express-now.xero0001.now.sh/upload",
          // "http://localhost:4000/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setPostContentImgUrl(response.data.location);
        });
    } catch (err) {
      alert(err);
    } finally {
      //   // setIsLoading(false);
    }
  };

  const pad = (n, width) => {
    n = n + "";
    return n.length >= width
      ? n
      : new Array(width - n.length + 1).join("0") + n;
  };

  const GetDate = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        <Wrap>
          <InputPost short={true} defaultValue={dt.getFullYear()} />
          -
          <InputPost short={true} defaultValue={pad(dt.getMonth() + 1, 2)} />
          -
          <InputPost short={true} defaultValue={pad(dt.getDate(), 2)} />
        </Wrap>
        <Wrap>
          <InputPost short={true} defaultValue={pad(dt.getHours(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getMinutes(), 2)} />
          :
          <InputPost short={true} defaultValue={pad(dt.getSeconds(), 2)} />
        </Wrap>
      </>
    );
  };

  const Time = ({ createdAt }) => {
    let dt = new Date(
      createdAt.substr(0, 4),
      createdAt.substr(5, 2) - 1,
      createdAt.substr(8, 2),
      createdAt.substr(11, 2),
      createdAt.substr(14, 2),
      createdAt.substr(17, 2)
    );

    dt.setHours(dt.getHours() + 9);

    return (
      <>
        {dt.getFullYear() +
          "-" +
          pad(dt.getMonth() + 1, 2) +
          "-" +
          pad(dt.getDate(), 2) +
          "T" +
          pad(dt.getHours(), 2) +
          ":" +
          pad(dt.getMinutes(), 2) +
          ":" +
          pad(dt.getSeconds(), 2)}
      </>
    );
  };

  const deletePost = () => {
    setPage("overview");
    resetState();
    deletePostMutation();
  };

  const updatePost = () => {
    setPage("overview");
    updatePostMutation();
    resetState();
  };

  const handleTitle = (e) => {
    setPostTitle(e.target.value);
  };

  const handleAddress = (e) => {
    setPostAddress(e.target.value);
  };

  const handleOpenTime = (e) => {
    setPostOpenTime(e.target.value);
  };

  const handleTel = (e) => {
    setPostTel(e.target.value);
  };

  const handleLoc1 = (e) => {
    setPostLocation([parseFloat(e.target.value), postLocation[1]]);
  };

  const handleLoc2 = (e) => {
    setPostLocation([postLocation[0], parseFloat(e.target.value)]);
  };

  const handlePublished = (e) => {
    if (e.target.value === "false") {
      setPostPublished(false);
    } else {
      setPostPublished(true);
    }
  };

  const handleRecommended = (e) => {
    if (e.target.value === "false") {
      setPostRecommended(false);
    } else {
      setPostRecommended(true);
    }
  };

  const handleYMD = (e) => {
    setPostCreatedAtYMD(e.target.value);
  };

  const handleHMS = (e) => {
    setPostCreatedAtHMS(e.target.value);
  };

  const handleCategory = (e) => {
    setPostCategory(e.target.value);
  };

  const handleRegion = (e) => {
    setPostRegion(e.target.value);
  };

  const resetState = () => {
    setPostid("");
    setPostTitle("");
    setPostImgUrl("");
    setPostContentImgUrl("");
    setPostPublished(false);
    setPostRecommended(false);
    setPostCategory("포커뉴스");
    setPostRegion("서울");
    setPostAddress("");
    setPostCreatedAtYMD("");
    setPostCreatedAtHMS("");
    setPostOpenTime("");
    setPostTel("");
    setPostLocation([0.000001, 0.000001]);
    setPostLink([]);
    setPostContent("");
  };

  const formatDate = (date) => {
    if (date.toString().length < 2) {
      return "0" + date;
    } else {
      return date.toString();
    }
  };

  const addLink = () => {
    setPostLink([
      ...postLink,
      { id: postLink.length, type: "instagram", link: "" },
    ]);
  };

  const removeLink = () => {
    const tp = postLink.filter((item, index) => index < postLink.length - 1);
    setPostLink(tp);
  };

  const handleLinkType = (e) => {
    var tp = [...postLink];
    const index = parseInt(e.target.id);
    tp[index].type = e.target.value;

    setPostLink(tp);
  };

  const handleLinkLink = (e) => {
    var tp = [...postLink];
    const index = parseInt(e.target.id);
    tp[index].link = e.target.value;

    setPostLink(tp);
  };

  const handleContent = (e) => {
    setPostContent(e.target.value);
  };

  React.useEffect(() => {
    if (introductionPostQuery.loading === false) {
      if (introductionPostQuery.data.getOneIntroduction) {
        if (postTitle !== introductionPostQuery.data.getOneIntroduction.title) {
          const dt = new Date(
            introductionPostQuery.data.getOneIntroduction.createdAt
          );

          const loc = JSON.parse(
            introductionPostQuery.data.getOneIntroduction.location
          );

          const link = JSON.parse(
            introductionPostQuery.data.getOneIntroduction.link
          );

          setPostTitle(introductionPostQuery.data.getOneIntroduction.title);
          setPostImgUrl(introductionPostQuery.data.getOneIntroduction.imgUrl);
          setPostContentImgUrl(
            introductionPostQuery.data.getOneIntroduction.contentImgUrl
          );
          setPostPublished(
            introductionPostQuery.data.getOneIntroduction.published
          );
          setPostRecommended(
            introductionPostQuery.data.getOneIntroduction.recommended
          );
          setPostCategory(
            introductionPostQuery.data.getOneIntroduction.category
          );
          setPostRegion(introductionPostQuery.data.getOneIntroduction.region);
          setPostAddress(introductionPostQuery.data.getOneIntroduction.address);
          setPostCreatedAtYMD(
            dt.getFullYear() +
              "-" +
              formatDate(dt.getMonth() + 1) +
              "-" +
              formatDate(dt.getDate())
          );
          setPostCreatedAtHMS(
            formatDate(dt.getHours()) +
              ":" +
              formatDate(dt.getMinutes()) +
              ":" +
              formatDate(dt.getSeconds())
          );
          setPostOpenTime(
            introductionPostQuery.data.getOneIntroduction.openTime
          );
          setPostTel(introductionPostQuery.data.getOneIntroduction.tel);
          setPostLocation(loc);
          setPostLink(link);
          setPostContent(introductionPostQuery.data.getOneIntroduction.content);
        }
      }
    }
  }, [introductionPostQuery.loading, introductionPostQuery.data]);

  return (
    <>
      {page === "overview" && (
        <>
          <Title>매장소개</Title>
          {!me.loading &&
            me.data &&
            me.data.me &&
            me.data.me.class &&
            me.data.me.name && (
              <div>
                사용자: {me.data.me.class} {me.data.me.name}
              </div>
            )}
          <Button onClick={() => draftPage()}>{"+ 추가"}</Button>
          <Wrapper>
            <Item pointer={false} published={true}>
              <Label flex="1">생성일</Label>
              <Label flex="2">제목</Label>
              <Label flex="1">분류</Label>
              <Label flex="1">공개</Label>
              <Label flex="1">추천</Label>
            </Item>
            <Separator />
            {!loading &&
              data &&
              data.introductions &&
              data.introductions.map((introduction) => {
                return (
                  <Item
                    key={introduction.id}
                    pointer={true}
                    onClick={() => newsPost(introduction.id)}
                    published={introduction.published}
                  >
                    <Label flex="1">
                      <Time createdAt={introduction.createdAt} />
                    </Label>
                    <Label flex="2">{introduction.title}</Label>
                    <Label flex="1">{introduction.category}</Label>
                    <Label flex="1">{introduction.published ? "O" : "X"}</Label>
                    <Label flex="1">
                      {introduction.recommended ? "O" : "X"}
                    </Label>
                  </Item>
                );
              })}
          </Wrapper>
        </>
      )}
      {page === "post" && (
        <>
          <Title>매장소개 - 수정</Title>
          <Button onClick={() => overviewPage()}>{"< 뒤로"}</Button>
          <Wrap flex={true}>
            <Button onClick={() => updatePost()}>{"V 적용하기"}</Button>
            <Button onClick={() => deletePost()}>{"X 삭제하기"}</Button>
          </Wrap>
          <WrapperPost>
            {!introductionPostQuery.loading &&
              introductionPostQuery.data &&
              introductionPostQuery.data.getOneIntroduction && (
                <>
                  <LabelPost>
                    ID: {introductionPostQuery.data.getOneIntroduction.id}
                  </LabelPost>
                  <LabelPost>제목</LabelPost>
                  <InputPost
                    value={postTitle}
                    onChange={handleTitle}
                    long={true}
                  />
                  <LabelPost>
                    생성일-년월일 (*형식에 맞게 입력해주세요)
                  </LabelPost>
                  <InputPost value={postCreatedAtYMD} onChange={handleYMD} />
                  <LabelPost>
                    생성일-시분초 (*형식에 맞게 입력해주세요)
                  </LabelPost>
                  <InputPost value={postCreatedAtHMS} onChange={handleHMS} />
                  <Wrap>
                    <LabelPost>공개여부</LabelPost>
                    <Select
                      name="published"
                      value={postPublished}
                      onChange={handlePublished}
                    >
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>추천여부</LabelPost>
                    <Select
                      name="recommended"
                      value={postRecommended}
                      onChange={handleRecommended}
                    >
                      <option value={false}>false</option>
                      <option value={true}>true</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>종류</LabelPost>
                    <Select
                      name="category"
                      value={postCategory}
                      onChange={handleCategory}
                    >
                      <option value="국내">국내</option>
                      <option value="해외">해외</option>
                    </Select>
                  </Wrap>
                  <Wrap>
                    <LabelPost>지역</LabelPost>
                    <Select
                      name="region"
                      value={postRegion}
                      onChange={handleRegion}
                    >
                      <option value="서울">서울</option>
                      <option value="인천">인천</option>
                      <option value="경기">경기</option>
                      <option value="강원">강원</option>
                      <option value="충청">충청</option>
                      <option value="호남">호남</option>
                      <option value="영남">영남</option>
                      <option value="제주">제주</option>
                    </Select>
                  </Wrap>
                  <LabelPost>영업시간</LabelPost>
                  <InputPost
                    value={postOpenTime}
                    onChange={handleOpenTime}
                    long={true}
                  />
                  <LabelPost>주소</LabelPost>
                  <InputPost
                    value={postAddress}
                    onChange={handleAddress}
                    long={true}
                  />
                  <LabelPost>TEL</LabelPost>
                  <InputPost value={postTel} onChange={handleTel} />
                  <LabelPost>위치 - 위도 / 경도</LabelPost>
                  <Wrap>
                    <InputPost
                      value={postLocation[1]}
                      type="number"
                      step="0.000001"
                      onChange={handleLoc2}
                    />
                    /
                    <InputPost
                      value={postLocation[0]}
                      type="number"
                      step="0.000001"
                      onChange={handleLoc1}
                    />
                  </Wrap>
                  <LabelPost>SNS 링크관리</LabelPost>
                  <Wrap>
                    <Button onClick={() => addLink()}>{"* 링크추가"}</Button>
                    <Button onClick={() => removeLink()}>
                      {"-* 링크제거"}
                    </Button>
                  </Wrap>

                  <LabelPost />
                  {postLink.map((link, index) => (
                    <div key={index}>
                      <LabelPost>idx: {index}</LabelPost>
                      <LabelPost>
                        종류:
                        <Select
                          name="linktype"
                          value={link.type}
                          id={index}
                          onChange={handleLinkType}
                        >
                          <option value="instagram">instagram</option>
                          <option value="facebook">facebook</option>
                          <option value="homepage">homepage</option>
                        </Select>
                      </LabelPost>
                      <InputPost
                        value={link.link}
                        id={index}
                        onChange={handleLinkLink}
                        long={true}
                      />
                      <LabelPost />
                    </div>
                  ))}
                  <LabelPost />
                  <Wrap>
                    <LabelPost>썸네일 이미지</LabelPost>
                    <ImgPreview imgurl={postImgUrl} />
                    <ImgInput type="file" onChange={uploadImg} />
                  </Wrap>
                  <Wrap>
                    <LabelPost>내용 이미지</LabelPost>
                    <ImgPreview imgurl={postContentImgUrl} />
                    <ImgInput type="file" onChange={uploadContentImg} />
                  </Wrap>
                  <LabelPost>매장소개</LabelPost>
                  <ContentArea value={postContent} onChange={handleContent} />
                </>
              )}
          </WrapperPost>
        </>
      )}
    </>
  );
};

const ContentArea = styled.textarea`
  width: 680px;
  height: 500px;
`;

const Title = styled.div`
  font-size: 33px;
`;

const Wrapper = styled.div`
  margin-top: 32px;
  padding: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  border-radius: 4px;
`;

const WrapperPost = styled.div`
  margin-top: 32px;
  padding: 16px;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;

  ${(p) => (p.pointer ? "cursor:pointer;" : "")};
  ${(p) =>
    p.published
      ? "color:" + p.theme.darkerColor + ";"
      : "color:" + p.theme.greyColor + ";"};
`;

const Separator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 10px;
`;

const Label = styled.div`
  flex: ${(p) => p.flex};
`;

const LabelPost = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
  min-height: 1px;
  margin-right: 16px;
  min-width: 120px;
`;

const InputPost = styled.input`
  font-size: 18px;
  margin-bottom: 16px;

  ${(p) => (p.short ? "width:100px;" : "")};
  ${(p) => (p.long ? "width:800px;" : "")};
`;

const Button = styled.button`
  display: block;
  height: 50px;
  width: 100px;
  margin-top: 16px;
  border-radius: 4px;
`;

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Wrap = styled.div`
  display: flex;

  ${(p) => (p.flex ? "justify-content:space-between;" : "")};
`;

const ImgInput = styled.input`
  margin-bottom: 16px;
`;

const ImgPreview = styled.div`
  width: 300px;
  height: 200px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${(p) => p.theme.verylightgreyColor};
  overflow: hidden;
  background-image: url('${(p) => p.imgurl}');
  background-size: cover;
  background-position: center;
`;
