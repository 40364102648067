import React from "react";
import styled from "styled-components";

const Container = styled.input`
  width: 100%;
  font-size: 15px;
  background-color: ${p => p.theme.verylightgreyColor};
  border: 1px solid ${p => p.theme.lightgreyColor};
  border-radius: 4px;
  padding: 5px;
`;

const Input = ({
  value,
  required = true,
  onChange,
  placeholder,
  type = "text",
  className
}) => (
  <Container
    className={className}
    placeholder={placeholder}
    required={required}
    value={value}
    onChange={onChange}
    type={type}
  />
);

export default Input;
