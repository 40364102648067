import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

// import PropTypes from "prop-types";

import Home from "Routes/Home";
import Admin from "Routes/Admin";

export default class extends React.Component {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/admin" component={Admin} />
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </>
    );
  }
}
